import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";

const Style = styled.div`
    .name {
        width: 150px;
    }
    .dot {
        width: max-content;
        margin-right: 10px;
    }
    .status {
        width: 150px;
    }
    .desc {
        margin: 0;
        font-size: 14px;
        line-height: 1.6;
    }
`;
const Detail = ({ toggle = () => {}, isShow = false }) => {
    return (
        <Modal toggle={toggle} isOpen={isShow}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <Style>
                    <h5 className="font-weight-bold mb-3">Detail Transaksi</h5>
                    <div className="d-flex mb-2">
                        <div className="name">Nama Produk</div>
                        <div className="dot">:</div>
                        <div className="status">Ban Depan Yamaha</div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="name">Jumlah Barang</div>
                        <div className="dot">:</div>
                        <div className="status">25</div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="name">Kategori Barang</div>
                        <div className="dot">:</div>
                        <div className="status">Otomotif</div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="name">Warna</div>
                        <div className="dot">:</div>
                        <div className="status">-</div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="name">Tanggal Pesan</div>
                        <div className="dot">:</div>
                        <div className="status">19 Juni 2020</div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="name">Tanggal Terima</div>
                        <div className="dot">:</div>
                        <div className="status">22 Juni 2020</div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="name">Status Bayar</div>
                        <div className="dot">:</div>
                        <div className="status">Dibayar</div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="name">Status Kirim</div>
                        <div className="dot">:</div>
                        <div className="status">Terkirim</div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="name">Kirim</div>
                        <div className="dot">:</div>
                        <div className="status">JNE Cargo</div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="name">Status</div>
                        <div className="dot">:</div>
                        <div className="status">Selesai</div>
                    </div>
                    {/* <hr /> */}
                    {/* <h5 className="font-weight-bold mb-3">Detail Retur</h5>
                    <div className="mb-3">
                        <Row>
                            <Col xl="3" md="3" sm="4" xs="6">
                                <img src={Dummy} alt="" className="img-fluid" />
                            </Col>
                            <Col xl="3" md="3" sm="4" xs="6">
                                <img src={Dummy} alt="" className="img-fluid" />
                            </Col>
                            <Col xl="3" md="3" sm="4" xs="6">
                                <img src={Dummy} alt="" className="img-fluid" />
                            </Col>
                            <Col xl="3" md="3" sm="4" xs="6">
                                <img src={Dummy} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </div>
                    <h6 className="font-weight-bold mb-2">Alaasan</h6>
                    <Card>
                        <CardBody>
                            <p className="desc">
                                Lorem ipsum dolor, sit amet consectetur
                                adipisicing elit. Earum libero error ex
                                praesentium provident eveniet dolorum quia
                                repellendus, necessitatibus beatae commodi
                                cumque minus deserunt quasi ratione voluptate
                                reiciendis nulla illum?
                            </p>
                        </CardBody>
                    </Card> */}
                </Style>
            </ModalBody>
        </Modal>
    );
};
export default Detail;
