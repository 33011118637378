/* eslint-disable */
import React, { useState } from "react";

import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";

import { useStore } from "../../../../Reducers";
import service from "../index.service";

const ModalAddBanner = ({
    isShow = false,
    toggle = () => {},
    setShow = () => {},
    setLoading = () => {},
    setRefresh = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [isMessage, setMessage] = React.useState(false);
    const [form, setForm] = useState([
        {
            type: "text",
            action: "text",
            label: "Judul",
            required: true,
            name: "title",
            col: 6,
            valid: "Judul tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "html",
            action: "html",
            name: "content",
            required: true,
            label: "Deskripsi",
            col: 12,
            valid: "Deskripsi tidak boleh kosong",
            value: "",
            rows: 3,
            status: false,
        },
        {
            type: "image",
            action: "uploadImage",
            required: true,
            label: "Gambar",
            name: "img_url",
            placeholder: "Klik Untuk Masukkan Gambar",
            col: 12,
            valid: "Harap masukkan gambar",
            value: "",
            status: false,
            normalData: true,
            heightStyle: "200px",
        },
    ]);
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            if (file !== undefined) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            }
        });
    const handlerSubmitData = async () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let data = {
                title: form[0].value,
                content: form[1].value,
                img_url: await toBase64(form[2].value),
            };
            service.newAboutUs({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (state?.aboutus?.newAboutUs?.isSuccess) {
            setShow(false);
            form[0].value = "";
            form[1].value = "";
            form[2].value = "";
        }
    }, [state]);
    return (
        <Modal size="lg" isOpen={isShow} toggle={toggle}>
            {isMessage && <Alert color="danger">{isMessage}</Alert>}
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Tambah Artikel</h5>
                <Form
                    isShow={isShow}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
                <div className="text-right">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => handlerSubmitData()}
                    >
                        Tambah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddBanner;
