/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../../../Components/Molekul/FormValidation";
import Button from "../../../../../../Components/Particle/Button";
import { useStore } from "../../../../../../Reducers";
import service from "../../../index.service";

const Edit = ({
    isEdit = false,
    dataArticle = false,
    setDataArticle = () => {},
    setLoading = () => {},
    setRefresh = () => {},
    setEdit = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [isMessage, setMessage] = useState(false);
    const [form, setForm] = useState([
        {
            type: "text",
            required: true,
            action: "text",
            name: "title",
            label: "Judul",
            col: 6,
            valid: "Judul tidak boleh kosong",
            value: dataArticle.title,
            status: true,
        },
        {
            type: "html",
            required: true,
            action: "html",
            name: "content",
            label: "Deskripsi",
            col: 12,
            valid: "Deskripsi tidak boleh kosong",
            value: dataArticle.content,
            rows: 3,
            status: true,
        },
        {
            type: "image",
            required: true,
            action: "uploadImage",
            label: "Gambar",
            name: "mg_url",
            placeholder: "Klik Untuk Masukkan Gambar",
            col: 12,
            valid: "Harap masukkan gambar",
            value: dataArticle.img_url,
            status: true,
            normalData: false,
            heightStyle: "200px",
        },
    ]);
    const updateValueArticle = () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let id = dataArticle.id;
            let data = {
                id: dataArticle.id,
                title: form[0].value,
                content: form[1].value,
                img_url: form[2].value,
            };
            service.updateArticle({ dispatch, id, data });
        }
        setRefresh(false);
    };
    const closePopUp = () => {
        setEdit(!isEdit);
        setDataArticle(false);
    };
    return (
        <Modal size="lg" isOpen={isEdit} toggle={closePopUp}>
            {isMessage && <Alert color="danger">{isMessage}</Alert>}
            <ModalHeader className="border-0 pb-0" toggle={closePopUp} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Edit Artikel</h5>

                <Form
                    isShow={isEdit}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />

                <div className="text-right">
                    <Button
                        color="success"
                        onClick={updateValueArticle}
                        size="sm"
                        type="button"
                    >
                        <i className="fas fa-edit mr-2"></i>Ubah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default Edit;
