import { servicesAction } from "../../../Reducers";

const service = {
    supplierList: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/list/supplier",
            method: "GET",
            token: true,
            qs: true,
            params: params,
            reducer: "service",
            group: "supplier",
            key: "supplierList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    createSupplier: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/oauth/register/new/supplier",
            method: "POST",
            token: true,
            data: data,
            reducer: "service",
            group: "supplier",
            key: "createSupplier",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap Mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap Mencoba beberapa saat lagi",
                },
            },
        });
    },
    transactionList: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/transaction/client/${id}`,
            method: "GET",
            token: true,
            reducer: "service",
            group: "transaction",
            key: "getTransaction",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap Mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap Mencoba beberapa saat lagi",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },

    verifyUser: ({ dispatch, id, verify, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/profile/${id}/${verify}`,
            method: "PUT",
            params: params,
            qs: true,
            token: true,
            reducer: "service",
            group: "user",
            key: "verifyUser",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: verify === "rejected" ? "Ditolak" : "Terverifikasi",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Telah terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Kode Referral tidak valid",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal service error",
                },
            },
        });
    },
    verifyUserClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "user",
            key: "verifyUser",
        });
    },
};
export default service;
