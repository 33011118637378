import React from "react";
import CommonTable from "../../../../Components/Molekul/CommonTable";
// import TableList from "./Table";
// import TableTransaction from "./TableTransaction";
import Button from "../../../../Components/Particle/Button";
import Detail from "../Modal/Detail";
import service from "../index.service";

const CardReseller = () => {
    const [dataReseller, setDataReseller] = React.useState(false);
    const [isShow, setShow] = React.useState(false);

    const [isRefresh, setRefresh] = React.useState(true);
    const handleDetailReseller = (item) => {
        setShow(true);
        setDataReseller(item);
    };
    return (
        <React.Fragment>
            <CommonTable
                isRefresh={isRefresh}
                configuration={{
                    searchInput: true,
                    searchValue: ["full_name", "email", "nik", "phone_number"],
                    header: false,
                    nav: {
                        hasSearch: true,
                        filter: [
                            {
                                icon: "fas fa-sort-amount-down",
                                type: "dropdown",
                                value: [
                                    {
                                        value: "email",
                                        label: "Email",
                                        key: "email",
                                    },
                                    {
                                        value: "full_name",
                                        label: "Name",
                                        key: "full_name",
                                    },
                                ],
                            },
                        ],
                    },
                    service: {
                        api: service.resellerList,
                        group: "reseller",
                        key: "resellerList",
                        filters: "",
                        filterData: `["verify_id","=","true"]`,
                    },
                    optionTable: [
                        {
                            headName: "No",
                            type: "number",
                        },
                        {
                            headName: "Nama",
                            type: "text",
                            fieldName: "full_name",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                        },

                        {
                            headName: "Email",
                            type: "email",
                            fieldName: "email",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                        },
                        {
                            headName: "Telepon",
                            type: "text",
                            fieldName: "phone_number",
                            iconClass: "fas fa-sort-numeric-down",
                            iconClass2: "fas fa-sort-numeric-up",
                        },
                        {
                            headName: "Tanggal Bergabung",
                            type: "isCostume",
                            fieldName: "created",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span>
                                            {item.created.substring(0, 10)}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "NIK",
                            type: "isCostume",
                            fieldName: "nik",
                            iconClass: "fas fa-sort-numeric-down",
                            iconClass2: "fas fa-sort-numeric-up",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span>
                                            {item.nik === null ? "-" : item.nik}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Foto KTP",
                            type: "isCostume",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        {item.identity_photo !== null ? (
                                            <img
                                                style={{
                                                    width: 100,
                                                    height: 50,
                                                    objectFit: "contain",
                                                }}
                                                src={item.identity_photo}
                                                alt={item.full_name}
                                                className="img-fluid"
                                            />
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Aksi",
                            type: "isCostume",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <Button
                                            color="primary"
                                            onClick={() =>
                                                handleDetailReseller(item)
                                            }
                                        >
                                            Lihat
                                        </Button>
                                    </React.Fragment>
                                );
                            },
                        },
                    ],
                }}
            ></CommonTable>
            <Detail
                setRefresh={setRefresh}
                item={dataReseller}
                isShow={isShow}
                toggle={() => setShow(!isShow)}
            />
        </React.Fragment>
    );
};
export default CardReseller;
