import React from "react";
import Header from "./Header";
import CardList from "./CardList";
import styled from "styled-components";

const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
        }
        transition: all 0.25s ease;
        width: 100%;
    }
`;
const Commission = () => {
    return (
        <Style id="commission">
            <Header />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <CardList />
                    </div>
                </section>
            </div>
        </Style>
    );
};
export default Commission;
