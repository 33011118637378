import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Button from "../../../../Components/Particle/Button";
import { formatMoney } from "../../../../Utils/PriceConverter";
import { color } from "../../../../Utils/Variable";

const Style = styled.div`
    .order {
        margin-bottom: 20px;
        .title {
            font-weight: bold;
            position: relative;
            padding: 0 0 10px;
            margin: 0 0 10px;
            font-size: 18px;
            color: ${color.primary};
            &:before {
                content: "";
                height: 2px;
                left: 0;
                margin: auto;
                position: absolute;
                bottom: 0;
                width: 100px;
                background: ${color.primary};
            }
        }
    }
    .data-order {
        display: flex;
        &__label {
            width: 175px;
        }
        &__dot {
            margin-right: 5px;
        }
        &__value {
            color: ${color.primary};
            font-weight: 600;
        }
    }
`;
const ModalDetail = ({
    // dataSupplier = false,
    isShow = false,
    toggle = () => {},
    data,
    setData = () => {},
    commissionFixed = [],
    commissionCustom = [],
}) => {
    const [dateTransaction, setDateTransaction] = React.useState("");
    const returnData = () => {
        setData(false);
        toggle();
    };
    React.useEffect(() => {
        if (data) {
            let getDateTransaction = data.transaction_code.split("/");
            const date = new Date(Number(getDateTransaction[1]));
            setDateTransaction(
                `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
                    2,
                    "0"
                )}-${String(date.getDate()).padStart(2, "0")}`
            );
        }
    }, [data]);
    return (
        <Modal size="lg" toggle={returnData} isOpen={isShow}>
            {data && (
                <ModalBody>
                    <Style id="transactionDetailSupplier">
                        <div className="order">
                            <h6 className="title">Informasi Transaksi</h6>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nama Pemesan
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.customer_detail.full_name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nama Produk
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.product.name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Tanggal Transaksi
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {dateTransaction}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nomor Transaksi
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.transaction_code}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Harga Jual
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {formatMoney(data.product_fee)}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Total Item
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.total_amount} item
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Total Harga
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {formatMoney(
                                        data.product_fee * data.total_amount
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="order">
                            <h6 className="title">Supplier</h6>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nama Toko
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.product.supplier.name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">Telepon</div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.product.supplier.phone_cs}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">Kota</div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.product.supplier.city}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">Alamat</div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.product.supplier.address}
                                </div>
                            </div>
                        </div>
                        <div className="order">
                            <h6 className="title">Reseller</h6>
                            <div className="data-order">
                                <div className="data-order__label">Nama</div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.reseller.full_name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">Telepon</div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.reseller.phone_number === null
                                        ? "-"
                                        : data.reseller.phone_number}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">Kota</div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.reseller.city === null
                                        ? "-"
                                        : data.reseller.city}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">Alamat</div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.product.supplier.address === null
                                        ? "-"
                                        : data.product.supplier.address}
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <Button className="mr-3" onClick={returnData}>
                                Tutup
                            </Button>
                        </div>
                    </Style>
                </ModalBody>
            )}
        </Modal>
    );
};
export default ModalDetail;
