import React from "react";
import HeaderRight from "../../../Components/Admin/Header";
import styled from "styled-components";
import { Col, Row } from "reactstrap";

const Style = styled.div`
    padding: 20px;
    box-shadow: 0 3px 6px #eee;
    background: #fff;
    h4 {
        font-weight: bold;
    }
    .header-section {
        @media (max-width: 767px) {
            margin-left: 30px;
        }
    }
`;
const Header = ({ titlePage }) => {
    return (
        <Style>
            <div className="header-section">
                <Row className="align-items-center">
                    <Col xl="6" md="6" sm="6">
                        <h4 className="mb-0">{titlePage}</h4>
                    </Col>
                    <Col xl="6" md="6" sm="6">
                        <HeaderRight />
                    </Col>
                </Row>
            </div>
        </Style>
    );
};
export default Header;
