import React from "react";
import { Card, CardBody } from "reactstrap";

import Header from "../Header";
import styled from "styled-components";
import Tab from "../Tab";

import CardList from "./CardList";
import ModalAddArticle from "./ModalAddArticle";

const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
        }
        transition: all 0.25s ease;
        width: 100%;
    }
`;

const AboutLakugan = () => {
    const [isRefresh, setRefresh] = React.useState(true);
    const [isShow, setShow] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [titlePage, setTitlePage] = React.useState("Tentang Lakugan");
    const [isShowAddArticle, setShowAddArticle] = React.useState(true);

    const handleShowModal = () => {
        setShow(!isShow);
    };
    const handleStateFilter = (isShow) => {
        setShowAddArticle(isShow);
    };
    return (
        <Style>
            <Header titlePage={titlePage} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <Card>
                            <CardBody>
                                <Tab />
                                <CardList
                                    isRefresh={isRefresh}
                                    setRefresh={setRefresh}
                                    setLoading={setLoading}
                                    isLoading={isLoading}
                                    handleShowModal={handleShowModal}
                                    isShowAddArticle={isShowAddArticle}
                                    handleStateFilter={handleStateFilter}
                                    setTitlePage={setTitlePage}
                                />
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
            <ModalAddArticle
                setRefresh={setRefresh}
                setLoading={setLoading}
                setShow={setShow}
                toggle={handleShowModal}
                isShow={isShow}
            />
        </Style>
    );
};
export default AboutLakugan;
