import React from "react";
import { Card, CardBody } from "reactstrap";
import { color } from "../../../../Utils/Variable";

import Header from "../Header";
import styled from "styled-components";
import Tab from "../Tab";

import Button from "../../../../Components/Particle/Button";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import Message from "../../../../Components/Modal/Message";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";

import ModalAddBlog from "./ModalAddBlog";
import ModalPreview from "./ModalPreview";
import ModalEditBlog from "./ModalEditBlog";
import ModalDeleteBlog from "./ModalDeleteBlog";

const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
        }
        transition: all 0.25s ease;
        width: 100%;
    }
    .card-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        transition: all 0.25s ease;
        background: ${(props) =>
            props.active === "true" ? "rgb(93 140 241 / 5%)" : "#fff"};
    }
    .fas {
        font-size: 18px;
    }
    .img-blog {
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
    .btn {
        &-dropdown {
            transition: all 0.25s ease;
            margin-right: 10px;
            &--user {
                color: ${(props) =>
                    props.collapse === "true" ? color.secondary : "#434348"};
            }
            &--mobile {
                color: #434348;
            }
            &:hover {
                color: ${color.secondary};
            }
        }
        &-detail {
            display: flex;
            width: 100%;
            border: none;
            background: transparent;
            padding: 10px;
            &:focus {
                outline: none;
            }
        }
        &-secondary {
            background: ${color.secondary};
            border: 1px solid ${color.secondary};
        }
    }
    .fa-user,
    .fa-store,
    h6 {
        margin: 0;
        color: #434348;
    }
    .btn-space {
        padding: 5px;
    }
    table {
        button {
            margin: 0 5px;
        }
    }
`;

const PostBlog = () => {
    const { dispatch, state } = useStore();
    const [isRefresh, setRefresh] = React.useState(true);
    const [isLoading, setLoading] = React.useState(false);
    const [isShow, setShow] = React.useState(false);
    const [isPreview, setPreview] = React.useState(false);
    const [isDisplay, setDisplay] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [dataBlog, setDataBlog] = React.useState(false);
    const [messageText, setMessageText] = React.useState("");
    const [isStatus, setStatus] = React.useState(true);
    const [isShowMessage, setShowMessage] = React.useState(false);
    const handleShowModal = () => {
        setShow(!isShow);
    };
    const handlerViewDetail = (item) => {
        setPreview(!isPreview);
        if (isPreview) {
            setDataBlog(false);
        }
        if (!isPreview) {
            setDataBlog(item);
        }
    };
    const handlerDeleteDetail = (item) => {
        setDisplay(!isDisplay);
        setDataBlog(item);
    };
    const handlerEditDetail = (item) => {
        setEdit(!isEdit);
        setDataBlog(item);
    };
    const handleEditPreview = (item) => {
        setPreview(false);
        setEdit(true);
        setDataBlog(item);
    };
    const closePreview = () => {
        setDataBlog(false);
        setPreview(!isPreview);
    };
    React.useEffect(() => {
        if (state?.blog?.blogList?.isSuccess) {
            setShow(false);
            setLoading(false);
            setRefresh(true);
            setStatus(state.blog.blogList.isSuccess);
            setMessageText(state.blog.blogList.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.blogClear({ dispatch });
        }
        if (state?.blog?.blogList?.isError) {
            setShow(false);
            setLoading(false);
            setRefresh(true);
            setStatus(state.blog.blogList.isSuccess);
            setMessageText(state.blog.blogList.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.blogClear({ dispatch });
        }
        if (state?.blog?.update?.isSuccess) {
            setRefresh(true);
            setLoading(false);
            setEdit(false);
            setDataBlog(false);
            setStatus(state.blog.update.isSuccess);
            setMessageText(state.blog.update.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.editClear({ dispatch });
        }
        if (state?.blog?.update?.isError) {
            setRefresh(true);
            setLoading(false);
            setEdit(false);
            setStatus(state.blog.update.isSuccess);
            setMessageText(state.blog.update.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.editClear({ dispatch });
        }
        if (state?.blog?.delete?.isSuccess) {
            setRefresh(true);
            setLoading(false);
            setDisplay(false);
            setStatus(state.blog.delete.isSuccess);
            setMessageText(state.blog.delete.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deleteClear({ dispatch });
        }
        if (state?.blog?.delete?.isError) {
            setRefresh(true);
            setLoading(false);
            setDisplay(false);
            setStatus(state.blog.delete.isSuccess);
            setMessageText(state.blog.delete.data.message);
            setLoading(false);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deleteClear({ dispatch });
        }
    }, [
        state,
        setLoading,
        setRefresh,
        setDisplay,
        dispatch,
        setMessageText,
        setShowMessage,
        setStatus,
    ]);
    return (
        <Style>
            <GrowingLoading active={isLoading} />
            <Header titlePage={"Blog List"} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <Card>
                            <CardBody>
                                <Tab />
                                <div
                                    className="d-flex"
                                    style={{ paddingBottom: 20 }}
                                >
                                    <Button
                                        onClick={handleShowModal}
                                        type="button"
                                        color="#5d8cf1"
                                        className="pl-5 pr-5"
                                    >
                                        Tambah
                                    </Button>
                                </div>
                                <CommonTable
                                    searchBy={["title"]}
                                    isRefresh={isRefresh}
                                    configuration={{
                                        searchInput: true,
                                        searchValue: ["title", "category.name"],
                                        header: false,
                                        nav: {
                                            hasSearch: true,
                                            filter: [
                                                {
                                                    icon: "fas fa-sort-amount-down",
                                                    type: "dropdown",
                                                    value: [
                                                        {
                                                            value: "title",
                                                            label: "Judul",
                                                            key: "title",
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        service: {
                                            api: service.getBlog,
                                            group: "blog",
                                            key: "getBlog",
                                            filters: "",
                                            filterData: "",
                                        },
                                        optionTable: [
                                            {
                                                headName: "No.",
                                                type: "number",
                                            },
                                            {
                                                headName: "Judul",
                                                type: "text",
                                                fieldName: "title",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                            },
                                            {
                                                headName: "Kategori",
                                                type: "text",
                                                fieldName: "category.name",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span>
                                                                {
                                                                    item
                                                                        .category
                                                                        .name
                                                                }
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                            },
                                            {
                                                headName: "Aksi",
                                                type: "actions",
                                                options: [
                                                    {
                                                        actionType:
                                                            "isViewModal",
                                                        function: (item) =>
                                                            handlerViewDetail(
                                                                item
                                                            ),
                                                        renderItem: () => {},
                                                        field: "id",
                                                    },
                                                    {
                                                        actionType:
                                                            "isEditModal",
                                                        function: (item) =>
                                                            handlerEditDetail(
                                                                item
                                                            ),
                                                        renderItem: () => {},
                                                        field: "id",
                                                    },
                                                    {
                                                        actionType:
                                                            "isDeleteModal",
                                                        function: (item) =>
                                                            handlerDeleteDetail(
                                                                item
                                                            ),
                                                        renderItem: () => {},
                                                        field: "id",
                                                    },
                                                ],
                                            },
                                        ],
                                    }}
                                ></CommonTable>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
            <ModalAddBlog
                setRefresh={setRefresh}
                setLoading={setLoading}
                setShow={setShow}
                toggle={handleShowModal}
                isShow={isShow}
            />
            <ModalPreview
                handleEditPreview={handleEditPreview}
                handlerViewDetail={handlerViewDetail}
                dataBlog={dataBlog}
                isShow={isPreview}
                toggle={closePreview}
            />
            {dataBlog && (
                <ModalEditBlog
                    setRefresh={setRefresh}
                    setEdit={setEdit}
                    handlerEditDetail={handlerEditDetail}
                    dataBlog={dataBlog}
                    isEdit={isEdit}
                    toggle={() => setDisplay(!isDisplay)}
                    setLoading={setLoading}
                    setDataBlog={setDataBlog}
                />
            )}
            <ModalDeleteBlog
                setRefresh={setRefresh}
                setDisplay={setDisplay}
                handlerDeleteDetail={handlerDeleteDetail}
                dataBlog={dataBlog}
                isDisplay={isDisplay}
                toggle={() => setDisplay(!isDisplay)}
                setLoading={setLoading}
            />
            <Message
                isShow={isShowMessage}
                status={isStatus}
                message={messageText}
            />
        </Style>
    );
};
export default PostBlog;
