import React from "react";
import Header from "./Header";
import Article from "./CardList/index.article";
import styled from "styled-components";

const Style = styled.div`
  section {
    .card-section {
      padding: 20px;
    }
    transition: all 0.25s ease;
    width: 100%;
  }
`;
const ArticlePage = () => {
  return (
    <Style id="category">
      <Header />
      <div className="d-flex">
        <section>
          <div className="card-section">
            <Article />
          </div>
        </section>
      </div>
    </Style>
  );
};
export default ArticlePage;
