import React from "react";
import { Card, CardBody } from "reactstrap";
import styled from "styled-components";
import Button from "../../../../Components/Particle/Button";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import Header from "../Header";

import service from "../index.service";
import Tab from "../Tab";
import { useHistory } from "react-router";
const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
        }
        transition: all 0.25s ease;
        width: 100%;
    }
`;
const TransactionReseller = () => {
    const history = useHistory();
    const handleDetailReseller = (item) => {
        history.push(`/admin/transaction/transaction-reseller/${item.id}`);
    };
    return (
        <Style>
            <Header titlePage={"Reseller"} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <Card>
                            <CardBody>
                                <Tab />
                                <CommonTable
                                    searchBy="full_name"
                                    configuration={{
                                        searchInput: true,
                                        searchValue: [
                                            "full_name",
                                            "email",
                                            "nik",
                                            "phone_number",
                                        ],
                                        header: false,
                                        nav: {
                                            hasSearch: true,
                                            filter: [
                                                {
                                                    icon: "fas fa-sort-amount-down",
                                                    type: "dropdown",
                                                    value: [
                                                        {
                                                            value: "email",
                                                            label: "Email",
                                                            key: "email",
                                                        },
                                                        {
                                                            value: "full_name",
                                                            label: "Name",
                                                            key: "full_name",
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        service: {
                                            api: service.resellerList,
                                            group: "reseller",
                                            key: "resellerList",
                                            filters: "",
                                            filterData: "",
                                        },
                                        optionTable: [
                                            {
                                                headName: "No",
                                                type: "number",
                                            },
                                            {
                                                headName: "Nama Pemilik",
                                                type: "text",
                                                fieldName: "full_name",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                            },

                                            {
                                                headName: "Email",
                                                type: "email",
                                                fieldName: "email",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                            },
                                            {
                                                headName: "NIK",
                                                type: "text",
                                                fieldName: "nik",
                                                iconClass:
                                                    "fas fa-sort-numeric-down",
                                            },
                                            {
                                                headName: "Tanggal Bergabung",
                                                type: "isCostume",
                                                fieldName: "created",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span>
                                                                {item.created.substring(
                                                                    0,
                                                                    10
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Aksi",
                                                type: "isCostume",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <Button
                                                                color="primary"
                                                                onClick={() =>
                                                                    handleDetailReseller(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                Lihat transaksi
                                                            </Button>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                        ],
                                    }}
                                ></CommonTable>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
        </Style>
    );
};
export default TransactionReseller;
