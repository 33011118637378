import React from "react";
import { Card, CardBody } from "reactstrap";
import Button from "../../Particle/Button";
import styled from "styled-components";
import { formatMoney } from "../../../Utils/PriceConverter";
import { color } from "../../../Utils/Variable";
import ProductDetail from "../Modal/ProductDetail";

const Style = styled(Card)`
    margin-bottom: 20px;
    .title {
        text-align: center;
        font-size: 16px;
        color: #aaa;
        margin: 0;
    }
    .price {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }
    .img-product {
        height: 150px;
        width: 150px;
        display: block;
        margin: auto;
    }
`;
const CardProduct = ({ data = {} }) => {
    const [isShow, setShow] = React.useState(false);

    const toggle = () => setShow(!isShow);
    return (
        <Style>
            <CardBody>
                <div className="mb-3">
                    <img
                        src={data?.product_image[0]?.img_url}
                        alt={data?.product_image[0]?.name}
                        className="img-fluid img-product"
                    />
                </div>
                <p className="title">{data?.name}</p>
                <p className="price">{formatMoney(data?.price)}</p>
                <div className="text-center">
                    <Button
                        color={color.secondary}
                        className="pl-4 pr-4"
                        onClick={toggle}
                    >
                        Lihat Produk
                    </Button>
                </div>
            </CardBody>
            <ProductDetail
                title="Detail Produk"
                isShow={isShow}
                item={data}
                toggle={toggle}
            />
        </Style>
    );
};
export default CardProduct;
