/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../../../Components/Molekul/FormValidation";
import Button from "../../../../../../Components/Particle/Button";
import { useStore } from "../../../../../../Reducers";
import service from "../../../index.service";
import moment from "moment";

const Edit = ({
    isEdit = false,
    dataBanner = false,
    setDataBanner = () => {},
    setLoading = () => {},
    setRefresh = () => {},
    setEdit = () => {},
}) => {
    const today = new Date();
    const tommorrow = new Date(today);
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [isMessage, setMessage] = useState(false);
    const [form, setForm] = useState([
        {
            type: "text",
            action: "text",
            required: true,
            label: "Judul",
            labelClass: "position-relative w-100",
            name: "Judul",
            placeholder: "Judul",
            character: {
                min: {
                    length: 4,
                    valid: "Minimal 4 karakter",
                },
                max: {
                    length: 25,
                    valid: "Maximal 25 karakter",
                },
            },
            col: 6,
            valid: "Nama tidak boleh kosong",
            value: dataBanner.title,
            status: true,
        },
        {
            type: "textarea",
            action: "textarea",
            required: true,
            name: "content",
            label: "Deskripsi",
            placeholder: "Deskripsi",
            col: 12,
            character: {
                max: {
                    length: 700,
                    valid: "Maximal 10 karakter",
                },
                min: {
                    length: 10,
                    valid: "Minimal 10 karakter",
                },
            },
            labelClass: "position-relative w-100",
            valid: "Deskripsi tidak boleh kosong",
            value: dataBanner.description,
            rows: 3,
            status: true,
        },
        {
            type: "datepicker",
            action: "datepicker",
            name: "publish_until",
            label: "Publish Until",
            col: 6,
            valid: "Publish until tidak boleh kosong",
            value: moment(new Date(dataBanner.publish_until)).format(
                "YYYY-MM-DD"
            ),
            minDate: tommorrow.setDate(tommorrow.getDate()),
            status: true,
        },
        {
            type: "checkbox",
            action: "checkbox",
            name: "published",
            label: "Published?",
            col: 6,
            valid: "Published tidak boleh kosong",
            value: dataBanner.published,
            status: true,
        },
        {
            type: "image",
            action: "uploadImage",
            required: true,
            label: "Gambar (Uk: 1440x500)",
            name: "article_image.img_url",
            placeholder: "Klik Untuk Masukan Gambar",
            col: 12,
            setting: {
                width: 1440,
                height: 500,
                valid: "Ukuran gambar wajib 1440x500",
            },
            valid: "Harap masukan gambar",
            value: dataBanner?.image?.img_url,
            status: true,
            normalData: true,
            heightStyle: "200px",
        },
    ]);
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            if (file !== undefined) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            }
        });

    const updateValueBanner = async () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let id = dataBanner.id;
            let data = {
                title: form[0].value,
                description: form[1].value,
                image: {
                    name:
                        form[4].value.name === undefined
                            ? "default.jpg"
                            : form[4].value.name,
                    img_url:
                        form[4].value.name === undefined
                            ? form[4].value
                            : await toBase64(form[4].value),
                },
                banner_url: "", //Trial for content
                published: form[3].value,
                publish_until: form[2].value,
            };
            service.updateBanner({ dispatch, id, data });
        }
        setRefresh(false);
    };
    const closePopUp = () => {
        setEdit(!isEdit);
        setDataBanner(false);
    };

    return (
        <Modal size="lg" isOpen={isEdit} toggle={closePopUp}>
            {isMessage && <Alert color="danger">{isMessage}</Alert>}
            <ModalHeader className="border-0 pb-0" toggle={closePopUp} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Edit Banner</h5>

                <Form
                    isShow={isEdit}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />

                <div className="text-right">
                    <Button
                        color="primary"
                        type="button"
                        onClick={updateValueBanner}
                    >
                        Edit
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default Edit;
