import React from "react";
import styled from "styled-components";
import { color } from "../../../../../Utils/Variable";
import Button from "../../../../../Components/Particle/Button";

import CardArticle from "./CardArticle";
import Preview from "./Modal/Preview";
import Delete from "./Modal/Delete";
import Edit from "./Modal/Edit";
import { useStore } from "../../../../../Reducers";
import MessageState from "../../../../../Components/Modal/Message";
import service from "../../index.service";

const Style = styled.div`
    .card-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        transition: all 0.25s ease;
        background: ${(props) =>
            props.active === "true" ? "rgb(93 140 241 / 5%)" : "#fff"};
    }
    .fas {
        font-size: 18px;
    }
    .img-blog {
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
    .btn {
        &-dropdown {
            transition: all 0.25s ease;
            margin-right: 10px;
            &--user {
                color: ${(props) =>
                    props.collapse === "true" ? color.secondary : "#434348"};
            }
            &--mobile {
                color: #434348;
            }
            &:hover {
                color: ${color.secondary};
            }
        }
        &-detail {
            display: flex;
            width: 100%;
            border: none;
            background: transparent;
            padding: 10px;
            &:focus {
                outline: none;
            }
        }
        &-secondary {
            background: ${color.secondary};
            border: 1px solid ${color.secondary};
        }
    }
    .fa-user,
    .fa-store,
    h6 {
        margin: 0;
        color: #434348;
    }
    .btn-space {
        padding: 5px;
    }
`;
const CardList = ({
    isAside = false,
    isShowAddArticle = true,
    handleShowModal = () => {},
    setLoading = () => {},
    isLoading = false,
    isRefresh = true,
    setRefresh = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [message, setMessage] = React.useState("");
    const [status, setStatus] = React.useState(false);
    const [isShowStatus, setShowStatus] = React.useState(false);
    const [isShow, setShow] = React.useState(false);
    const [isDisplay, setDisplay] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [dataArticle, setDataArticle] = React.useState(false);
    const handlerViewDetail = (item) => {
        setShow(!isShow);
        if (isShow) {
            setDataArticle(false);
        }
        if (!isShow) {
            setDataArticle(item);
        }
    };
    const handlerDeleteDetail = (item) => {
        setDisplay(!isDisplay);
        setDataArticle(item);
    };
    const handlerEditDetail = (item) => {
        setEdit(!isEdit);
        setDataArticle(item);
    };
    const handleEditPreview = (item) => {
        setShow(false);
        setEdit(true);
        setDataArticle(item);
    };
    const closePreview = () => {
        setDataArticle(false);
        setShow(!isShow);
    };
    React.useEffect(() => {
        if (state?.aboutus?.newAboutUs?.isSuccess) {
            setShowStatus(true);
            setStatus(state.aboutus.newAboutUs.isSuccess);
            setMessage(state?.aboutus?.newAboutUs?.message.text);
            setTimeout(() => {
                setShowStatus(false);
            }, 2500);
            setShow(false);
            setLoading(false);
            setRefresh(true);
            service.aboutUsClear({ dispatch });
        }
        if (state?.aboutus?.newAboutUs?.isError) {
            setShowStatus(true);
            setStatus(state.aboutus.newAboutUs.isSuccess);
            setMessage(state?.aboutus?.newAboutUs?.data.message);
            setTimeout(() => {
                setShowStatus(false);
            }, 2500);
            setShow(false);
            setLoading(false);
            setRefresh(true);
            setMessage(state?.aboutus?.newAboutUs.message.text);
        }
        if (state?.aboutus?.delete?.isSuccess) {
            setShowStatus(true);
            setStatus(state.aboutus.delete.isSuccess);
            setMessage(state?.aboutus?.delete?.message.text);
            setTimeout(() => {
                setShowStatus(false);
            }, 2500);
            setRefresh(true);
            setLoading(false);
            setDisplay(false);
            service.articleDeleteClear({ dispatch });
            // window.location.reload();
        }
        if (state?.aboutus?.delete?.isError) {
            setShowStatus(true);
            setStatus(state.aboutus.delete.isSuccess);
            setMessage(state?.aboutus?.delete?.data.message);
            setTimeout(() => {
                setShowStatus(false);
            }, 2500);
            setRefresh(true);
            setLoading(false);
            setDisplay(false);
            setMessage(state?.aboutus?.delete?.message.text);
            service.articleDeleteClear({ dispatch });
            // window.location.reload();
        }
        if (state?.aboutus?.update?.isSuccess) {
            setShowStatus(true);
            setStatus(state.aboutus.update.isSuccess);
            setMessage(state?.aboutus?.update?.message.text);
            setTimeout(() => {
                setShowStatus(false);
            }, 2500);
            setRefresh(true);
            setLoading(false);
            setEdit(false);
            setDataArticle(false);
            // window.location.reload();
            service.articleEditClear({ dispatch });
        }
        if (state?.aboutus?.update?.isError) {
            setShowStatus(true);
            setStatus(state.aboutus.update.isSuccess);
            setMessage(state?.aboutus?.update?.data.message);
            setTimeout(() => {
                setShowStatus(false);
            }, 2500);
            setRefresh(true);
            setLoading(false);
            setEdit(false);
            setMessage(state?.aboutus?.update?.message.text);
            service.articleEditClear({ dispatch });
        }
    }, [state, dispatch, setLoading, setRefresh]);
    return (
        <Style active={isAside.toString()}>
            {isShowAddArticle && (
                <div className="d-flex" style={{ paddingBottom: 20 }}>
                    <Button
                        onClick={handleShowModal}
                        type="button"
                        color="#5d8cf1"
                        className="pl-5 pr-5 mr-3"
                    >
                        Tambah
                    </Button>
                </div>
            )}
            <CardArticle
                isRefresh={isRefresh}
                dataArticle={dataArticle}
                handlerViewDetail={handlerViewDetail}
                handlerEditDetail={handlerEditDetail}
                handlerDeleteDetail={handlerDeleteDetail}
                isLoading={isLoading}
            />
            <Preview
                handleEditPreview={handleEditPreview}
                handlerViewDetail={handlerViewDetail}
                dataArticle={dataArticle}
                isShow={isShow}
                toggle={closePreview}
            />
            {dataArticle && (
                <Edit
                    setRefresh={setRefresh}
                    setEdit={setEdit}
                    handlerEditDetail={handlerEditDetail}
                    dataArticle={dataArticle}
                    isEdit={isEdit}
                    toggle={() => setDisplay(!isDisplay)}
                    setLoading={setLoading}
                    setDataArticle={setDataArticle}
                />
            )}
            <Delete
                setRefresh={setRefresh}
                setDisplay={setDisplay}
                handlerDeleteDetail={handlerDeleteDetail}
                dataArticle={dataArticle}
                isDisplay={isDisplay}
                toggle={() => setDisplay(!isDisplay)}
                setLoading={setLoading}
            />
            <MessageState
                isShow={isShowStatus}
                status={status}
                message={message}
            />
        </Style>
    );
};
export default CardList;
