import React from "react";
import { Modal, ModalBody, Row, Col, Input, FormGroup } from "reactstrap";
// import TabMenu from "../../../../Components/Molekul/TabMenu";
import styled from "styled-components";
// import { color } from "../../../../Utils/Variable";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import MessageState from "../../../../Components/Modal/Message";
// import DataReseller from "./DataReseller";
// import CardCatalog from "../CardList/Card";
import { formatMoney } from "../../../../Utils/PriceConverter";
import service from "../index.service";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import FormText from "../../../../Components/Particle/Form/FormText";
import DefaultImage from "../../../../Components/Assets/Image/reseller_default.png";
const Style = styled(Modal)`
    .information-tab {
        text-align: center;
    }
    .right-half {
        display: table-cell;
    }
    .btn-identity {
        background: transparent;
        border: unset;
        padding: 0;
        margin: 0;
        display: block;
    }
    .left-half {
        display: table-cell;
    }
    .button-modal {
        margin: 0 4px;
        width: 100%;
    }
    .less-more p {
        float: right;
        font-size: 12px;
        text-decoration: none;
        cursor: pointer;
        color: #898b8a;
    }
    .image-head {
        float: left;
    }
    .avatar {
        margin: 0 auto 20px;
        display: block;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        background: #aaa;
        object-fit: contain;
    }
    .tab-top-title {
        width: 100%;
        text-align: center;
        margin: auto;
        display: block;
    }
    .header {
        text-align: left;
        margin: 5px;
        padding: 5px;
        font-weight: bold;
    }
    .title {
        color: #898b8a;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
    }
    .desc {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        &-value {
            color: #898b8a;
            font-size: 14px;
            &.text {
                text-align: right;
                width: 100%;
            }
        }
    }
`;

const Detail = ({
    item = false,
    isShow = false,
    setShow = () => {},
    toggle = () => {},
    setRefresh = () => {},
    verify = false,
}) => {
    const [isOpen, setOpen] = React.useState(false);
    const [referralText, setReferralText] = React.useState("");
    const { dispatch, state } = useStore();
    const [message, setMessage] = React.useState("");
    const [modalReason, setModalReason] = React.useState(false);
    const [modalConfirmation, setModalConfirmation] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [isModalStatusMessage, setModalStatusMessage] = React.useState(false);
    // const [productList, setProductList] = React.useState([]);
    const [isReferral, setReferral] = React.useState(false);
    const [isMore, setMore] = React.useState(false);
    const [reason, setReason] = React.useState("");
    const [reasonObj, setReasonObj] = React.useState({
        label: "Alasan Penolakan",
        value: reason,
        required: true,
        type: "textarea",
        rows: 5,
        valid: "Alasan wajib di isi",
        character: {
            min: {
                length: 3,
                valid: "Minimal 3 character",
            },
            max: {
                length: 100,
                valid: "Maximal 100 character",
            },
        },
        status: reason === "" ? false : true,
    });
    const toggleIdentity = () => {
        setOpen(!isOpen);
    };
    const closeModal = () => {
        setMore(false);
        toggle();
    };
    // const resellerDetail = (value) => {
    //     setOpen(!isOpen);
    // };
    const handleReferralText = (e) => {
        let value = e.target.value;
        setReferralText(value);
    };
    const openReferral = (value) => {
        setReferralText(value);
        setReferral(!isReferral);
    };
    const handleGiveReferral = (item) => {
        setRefresh(false);
        let data = {
            code: referralText,
            reseller_id: item.id,
        };
        // if (item?.share_referral_code?.length < 1) {
        service.referralCode({ dispatch, data });
        // }
        // if (item?.share_referral_code?.length > 0) {
        //     let id = item?.share_referral[0].id;
        //     service.referralUpdate({ dispatch, data, id });
        // }
    };
    const onGetReason = (value) => {
        reasonObj.value = value.value;
        reasonObj.status = value.value === "" ? false : true;
        setReasonObj(reasonObj);
        setReason(value.value);
    };
    const handleVerifyUser = (id, verify) => {
        if (verify === "rejected") {
            setModalReason(true);
        } else if (verify === "confirmation") {
            setModalConfirmation(true);
        } else {
            setLoading(true);
            setRefresh(false);
            service.verifyUser({ dispatch, id, verify });
        }
    };
    const handleAcceptRejected = (id, verify) => {
        let params = {
            notifVerifikasi: reasonObj.value,
        };
        setLoading(true);
        setRefresh(false);
        service.verifyUser({ dispatch, id, verify, params });
    };
    const linkName = isMore ? "Lebih Sedikit << " : "Lebih Banyak >> ";
    React.useEffect(() => {
        if (state?.reseller?.referralCode?.isSuccess) {
            setReferral(false);
            setMessage(state.reseller.referralCode.message.text);
            setModalStatusMessage(true);
            setRefresh(true);
            toggle();
            service.referralCodeClear({ dispatch });
            setStatus(state.reseller.referralCode.isSuccess);
            setTimeout(() => {
                setModalStatusMessage(false);
            }, 3000);
        }
        if (state?.reseller?.referralCode?.isError) {
            setReferral(false);
            setMessage(state.reseller.referralCode.message.text);
            setModalStatusMessage(true);
            service.referralCodeClear({ dispatch });
            setStatus(state.reseller.referralCode.isSuccess);
            setTimeout(() => {
                setModalStatusMessage(false);
            }, 3000);
        }
        if (state?.user?.verifyUser?.isSuccess) {
            setLoading(false);
            setRefresh(true);
            setMessage(state.user.verifyUser.message.text);
            setModalStatusMessage(true);
            service.verifyUserClear({ dispatch });
            setStatus(state.user.verifyUser.isSuccess);
            setModalConfirmation(false);
            setTimeout(() => {
                setModalStatusMessage(false);
                setModalReason(false);
                setShow(false);
            }, 3000);
        }
        if (state?.user?.verifyUser?.isError) {
            setLoading(false);
            setRefresh(true);
            setMessage(state.user.verifyUser.data.message);
            setModalStatusMessage(true);
            service.verifyUserClear({ dispatch });
            setStatus(state.user.verifyUser.isSuccess);
            setModalConfirmation(false);
            setTimeout(() => {
                setModalStatusMessage(false);
            }, 3000);
        }
    }, [state, dispatch, setRefresh, toggle, setShow]);
    return (
        <React.Fragment>
            <GrowingLoading active={isLoading} />
            {item && (
                <>
                    <Style size="lg" isOpen={isShow} toggle={closeModal}>
                        <ModalBody>
                            <Row>
                                <Col xl="12">
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="">
                                                Foto Profile
                                            </label>
                                            <div>
                                                <img
                                                    src={
                                                        item?.photo_profile ===
                                                        null
                                                            ? DefaultImage
                                                            : item?.photo_profile
                                                    }
                                                    alt=""
                                                    className="avatar"
                                                />
                                                <div className="d-flex justify-content-center">
                                                    {item?.share_referral_code ===
                                                        null && (
                                                        <div>
                                                            <Button
                                                                onClick={() =>
                                                                    openReferral(
                                                                        ""
                                                                    )
                                                                }
                                                                color="primary"
                                                            >
                                                                Beri Referral
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="h-100">
                                                <label htmlFor="">
                                                    Foto KTP
                                                </label>
                                                {item?.identity_photo ===
                                                null ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                            color: "#aaa",
                                                            height: "100%",
                                                        }}
                                                    >
                                                        Tidak tersedia
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="btn-identity"
                                                        onClick={() =>
                                                            toggleIdentity(
                                                                item?.identity_photo
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                item?.identity_photo
                                                            }
                                                            alt=""
                                                            className="img-fluid img-identity"
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl="12">
                                    <h4>{item?.full_name}</h4>
                                    <div className="mb-6">
                                        <h6 className="information-tab">
                                            Informasi Umum
                                        </h6>
                                        <div className="desc">
                                            <div className="title">NIK</div>
                                            <div className="desc-value text">
                                                {item?.nik !== null
                                                    ? item.nik
                                                    : "-"}
                                            </div>
                                        </div>
                                        <div className="desc">
                                            <div className="title">
                                                Nama Pemilik
                                            </div>
                                            <div className="desc-value text">
                                                {item?.full_name}
                                            </div>
                                        </div>
                                        <div className="desc">
                                            <div className="title">
                                                Telepon Pemilik
                                            </div>
                                            <div className="desc-value text">
                                                {item?.phone_number !== null
                                                    ? item?.phone_number
                                                    : "-"}
                                            </div>
                                        </div>
                                        {item?.share_referral_code !== null && (
                                            <div className="desc">
                                                <div className="title">
                                                    Kode Referral
                                                </div>
                                                <div className="desc-value text">
                                                    {item?.share_referral_code}
                                                </div>
                                            </div>
                                        )}

                                        <div className="desc">
                                            <div className="title">Email</div>
                                            <div className="desc-value text">
                                                {item?.email !== null
                                                    ? item.email
                                                    : "-"}
                                            </div>
                                        </div>
                                        <div className="desc">
                                            <div className="title">Saldo</div>
                                            <div className="desc-value text">
                                                Saldo :{" "}
                                                {formatMoney(
                                                    item?.wallet?.nominal
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {isMore && (
                                        <React.Fragment>
                                            <div className="desc">
                                                <div className="title">
                                                    Provinsi
                                                </div>
                                                <div className="desc-value text">
                                                    {item?.province !== null
                                                        ? item?.province
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="desc">
                                                <div className="title">
                                                    Kota
                                                </div>
                                                <div className="desc-value text">
                                                    {item?.city !== null
                                                        ? item?.city
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="desc">
                                                <div className="title">
                                                    Kecamatan
                                                </div>
                                                <div className="desc-value text">
                                                    {item?.district !== null
                                                        ? item?.district
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="desc">
                                                <div className="title">
                                                    Kode pos
                                                </div>
                                                <div className="desc-value text">
                                                    {item?.postal_code !== null
                                                        ? item?.postal_code
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="desc">
                                                <div className="title">
                                                    Alamat
                                                </div>
                                                <div className="desc-value text">
                                                    {item?.address !== null
                                                        ? item?.address
                                                        : "-"}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <div
                                        className="less-more"
                                        onClick={() => {
                                            setMore(!isMore);
                                        }}
                                    >
                                        <p>{linkName}</p>
                                    </div>
                                </Col>
                            </Row>
                            {verify && item?.identity_photo !== null && (
                                <Row>
                                    <Col xl={12}>
                                        {item?.update_data ? (
                                            <div className="text-right">
                                                <Button
                                                    onClick={() =>
                                                        handleVerifyUser(
                                                            item?.id,
                                                            "rejected"
                                                        )
                                                    }
                                                    color="danger"
                                                    style={{
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    Tolak Akses
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    onClick={() =>
                                                        handleVerifyUser(
                                                            item?.id,
                                                            "confirmation"
                                                        )
                                                    }
                                                >
                                                    Beri Akses
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="mb-3 text-center text-danger">
                                                <i>
                                                    User ini telah ditolak untuk
                                                    diberikan akses
                                                </i>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </ModalBody>
                    </Style>
                    <Modal isOpen={isReferral} toggle={openReferral}>
                        <ModalBody>
                            <FormGroup>
                                <label htmlFor="">Kode Referral</label>
                                <Input
                                    type="text"
                                    value={referralText}
                                    onChange={(e) => handleReferralText(e)}
                                />
                            </FormGroup>
                            <div>
                                <Button
                                    color="outline-primary"
                                    className="mr-2"
                                    onClick={openReferral}
                                >
                                    Tutup
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => handleGiveReferral(item)}
                                >
                                    Simpan
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>
                    <MessageState
                        isShow={isModalStatusMessage}
                        status={status}
                        message={message}
                    />
                </>
            )}
            <Modal size="xl" toggle={toggleIdentity} isOpen={isOpen}>
                <img src={item?.identity_photo} alt="" className="img-fluid" />
            </Modal>
            <Modal isOpen={modalReason}>
                <ModalBody>
                    <Row>
                        <Col xl={12}>
                            <FormText
                                onGetValue={onGetReason}
                                item={reasonObj}
                            />
                        </Col>
                        <Col xl={12}>
                            <div className="text-right">
                                <Button
                                    onClick={() => setModalReason(false)}
                                    style={{ marginRight: "5px" }}
                                >
                                    Batal
                                </Button>
                                <Button
                                    disabled={!reasonObj.status}
                                    color="danger"
                                    onClick={() =>
                                        handleAcceptRejected(
                                            item?.id,
                                            "rejected"
                                        )
                                    }
                                >
                                    Tolak
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal centered isOpen={modalConfirmation}>
                <ModalBody>
                    <Col xl={12}>
                        <p>
                            Apakah anda yakin ingin memberi akses pada
                            <span className="font-weight-bold">
                                {" "}
                                "{item?.full_name}"{" "}
                            </span>
                            ?
                        </p>
                    </Col>
                    <Col xl={12}>
                        <div className="d-flex justify-content-between">
                            <Button
                                onClick={() => setModalConfirmation(false)}
                                style={{ width: "49%" }}
                            >
                                Tidak
                            </Button>
                            <Button
                                onClick={() =>
                                    handleAcceptRejected(item?.id, "verified")
                                }
                                color="danger"
                                style={{ width: "49%" }}
                            >
                                Ya
                            </Button>
                        </div>
                    </Col>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};
export default Detail;
