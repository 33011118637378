/* eslint-disable */
import React from "react";
import {
    Col,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Label,
} from "reactstrap";
import styled from "styled-components";
import CommonTable from "../../Molekul/CommonTable";
import Form from "../../Molekul/FormValidation";
import { color } from "../../../Utils/Variable";
import Button from "../../Particle/Button";
import FormPrice from "../../Particle/Form/FormPrice";
import FormSelect from "../../Particle/Form/FormSelect";
import FormText from "../../Particle/Form/FormText";
import { useCallback } from "react";

const Style = styled(Modal)`
    .sub-title {
        color: #848484;
        font-size: 14px;
    }
`;
const CreateProduct = ({ isShow = false, toggle = () => {} }) => {
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [formPiece, setFormPiece] = React.useState(false);
    const [formDuration, setFormDuration] = React.useState(false);
    const [form, setForm] = React.useState([
        {
            name: "nameProduct",
            placeholder: "Nama Product",
            type: "text",
            action: "text",
            col: 12,
            valid: "Nama Product tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            name: "price",
            type: "price",
            action: "price",
            currencyLogo: "Rp. ",
            placeholder: "Harga",
            col: 6,
            value: "",
            status: false,
        },
        {
            name: "category",
            type: "select",
            action: "select",
            col: 6,
            option: [
                {
                    label: "Fashion Pria",
                    value: "1",
                },
            ],
            valid: "Pilih kategori",
            value: "",
            status: false,
        },
        {
            name: "image-multiple",
            type: "image",
            action: "uploadImageMultiple",
            col: 12,
            valid: "Harap masukan gambar",
            value: [],
            status: false,
            max: 3,
        },
        {
            name: "desc",
            type: "textarea",
            action: "textarea",
            rows: 10,
            col: 12,
            valid: "Harap masukan gambar",
            value: [],
            status: false,
            max: 3,
        },
    ]);
    const [dummyData, setDataDummy] = React.useState([
        {
            id: 0,
            name: "",
            size: {
                value: "1",
                options: [
                    { label: "XL", value: "1" },
                    { label: "L", value: "2" },
                    { label: "M", value: "3" },
                ],
            },
            stock: 0,
            weight: 0,
        },
    ]);
    const handleAddSpecification = () => {
        let obj = {
            id: dummyData.length,
            name: "",
            size: {
                value: "1",
                options: [
                    { label: "XL", value: "1" },
                    { label: "L", value: "2" },
                    { label: "M", value: "3" },
                ],
            },
            stock: "0",
            weight: 0,
        };
        setDataDummy((prevState) => {
            return [...prevState, obj];
        });
        // setDataDummy(obj);
    };
    const handleDeleteItem = (e, item) => {
        e.preventDefault();
        const items = dummyData;
        const valueToRemove = item;
        const filteredItems = items.filter(function (item) {
            return item !== valueToRemove;
        });
        // setLoadingRemove(true);
        onRemoveData(filteredItems);
    };
    const onRemoveData = useCallback((value) => {
        return setDataDummy(value);
    });
    const handleCheckPiece = (e) => {
        let checked = e.target.checked;
        setFormPiece(checked);
    };
    const handleCheckDuration = (e) => {
        let checked = e.target.checked;
        setFormDuration(checked);
    };
    const onGetValue = (item) => {
        dummyData[item.id][item.name] = item.value;
        setDataDummy(dummyData);
    };
    const handlerSubmitData = (e) => {
        let isError = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            alert("sukses");
        }
    };
    return (
        <Style size="xl" isOpen={isShow} toggle={toggle}>
            <ModalHeader toggle={toggle}>Tambah Product</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xl="6" md="6" sm="6" xs="12">
                        <span className="sub-title mb-4 d-block">
                            Deskripsi Produk
                        </span>
                        <Form
                            setForm={setForm}
                            validateForm={validateForm}
                            validationClick={validationClick}
                            form={form}
                        />
                    </Col>
                    <Col xl="6" md="6" sm="6" xs="12">
                        <span className="sub-title mb-4 d-block">Status</span>
                        <div className="mb-5">
                            <Button
                                onClick={handleAddSpecification}
                                color={color.secondary}
                                type="button"
                            >
                                + Tambah Spesifikasi
                            </Button>
                            <CommonTable
                                edit={true}
                                dummyData={dummyData}
                                setDataDummy={setDataDummy}
                                configuration={{
                                    searchInput: false,
                                    nav: {
                                        hasSearch: false,
                                    },
                                    // service: {
                                    //     api: service.missionList,
                                    //     group: "mission",
                                    //     key: "missionList"
                                    // },
                                    optionTable: [
                                        {
                                            headName: "Warna",
                                            fieldName: "name",
                                            type: "isCostume",
                                            renderItem: (item) => {
                                                return (
                                                    <React.Fragment>
                                                        <FormText
                                                            id={item.id}
                                                            onGetValue={
                                                                onGetValue
                                                            }
                                                            item={{
                                                                value:
                                                                    item.name,
                                                                name: "name",
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                );
                                            },
                                        },
                                        {
                                            headName: "Ukuran",
                                            fieldName: "size",
                                            type: "isCostume",
                                            renderItem: (item) => {
                                                return (
                                                    <React.Fragment>
                                                        <FormSelect
                                                            id={item.id}
                                                            onGetValue={
                                                                onGetValue
                                                            }
                                                            item={{
                                                                value:
                                                                    item.size
                                                                        .value,
                                                                option:
                                                                    item.size
                                                                        .options,
                                                                width: "100px",
                                                                name: "size",
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                );
                                            },
                                        },
                                        {
                                            headName: "Stok",
                                            fieldName: "stock",
                                            type: "isCostume",
                                            renderItem: (item, key) => {
                                                return (
                                                    <React.Fragment>
                                                        <FormText
                                                            id={item.id}
                                                            onGetValue={
                                                                onGetValue
                                                            }
                                                            item={{
                                                                value:
                                                                    item.stock,
                                                                type: "number",
                                                                name: "stock",
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                );
                                            },
                                        },
                                        {
                                            headName: "Berat",
                                            fieldName: "weight",
                                            type: "isCostume",
                                            renderItem: (item) => {
                                                return (
                                                    <React.Fragment>
                                                        <FormPrice
                                                            id={item.id}
                                                            onGetValue={
                                                                onGetValue
                                                            }
                                                            item={{
                                                                defaultValue:
                                                                    item.weight,
                                                                suffix: " kg",
                                                                precision: 1,
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                );
                                            },
                                        },
                                        {
                                            headName: "",
                                            type: "isCostume",
                                            renderItem: (item, key) => {
                                                return (
                                                    <React.Fragment>
                                                        {item.id !== 0 && (
                                                            <FormGroup>
                                                                <Button
                                                                    color="transparent"
                                                                    size="sm"
                                                                    type="button"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        handleDeleteItem(
                                                                            e,
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fas fa-trash-alt text-secondary"></i>
                                                                </Button>
                                                            </FormGroup>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            },
                                        },
                                    ],
                                }}
                            ></CommonTable>
                        </div>
                        <div>
                            <span className="sub-title mb-2 d-block">
                                Spesifikasi Khusus
                            </span>
                            <Row>
                                <Col xl="6" md="6" sm="6">
                                    <span className="sub-title mb-2 d-block">
                                        Pilih Jenis Potongan
                                    </span>
                                    <div className="mb-2">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    id="checkbox2"
                                                    onChange={handleCheckPiece}
                                                />{" "}
                                                Potongan Khusus
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    {formPiece && (
                                        <FormGroup>
                                            <FormPrice
                                                item={{
                                                    defaultValue: 0,
                                                    currencyLogo: "Rp ",
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                </Col>
                                <Col xl="6" md="6" sm="6">
                                    <span className="sub-title mb-2 d-block">
                                        Pre Order
                                    </span>
                                    <div className="mb-2">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    id="checkbox2"
                                                    onChange={
                                                        handleCheckDuration
                                                    }
                                                />{" "}
                                                Durasi (Hari)
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    {formDuration && (
                                        <FormGroup>
                                            <FormText
                                                item={{
                                                    defaultValue: "",
                                                    type: "number",
                                                    name: "day",
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className="text-right">
                    <Button
                        type="button"
                        color={color.secondary}
                        onClick={handlerSubmitData}
                    >
                        Simpan
                    </Button>
                </div>
            </ModalBody>
        </Style>
    );
};
export default CreateProduct;
