import React from "react";
const TableHead = ({
    sortFilter = "",
    dataHead = [],
    dataNav,
    onGetValueFilter = () => {},
}) => {
    // eslint-disable-next-line
    const [isActive, setActive] = React.useState(true);
    const handleActiveSort = (value) => {
        // setActive(!isActive);
        onGetValueFilter(value);
    };
    return (
        <thead className="thead-light">
            {dataNav.hasSearch ? (
                <tr>
                    {dataHead.map((item, key) => {
                        return (
                            <React.Fragment key={key}>
                                {item.fieldName !== undefined ? (
                                    <th
                                        className={
                                            isActive
                                                ? sortFilter ===
                                                      item.fieldName ||
                                                  sortFilter ===
                                                      `-${item.fieldName}`
                                                    ? "text-primary"
                                                    : ""
                                                : ""
                                        }
                                        style={{ cursor: "pointer" }}
                                        scope="col"
                                        onClick={() =>
                                            handleActiveSort(item.fieldName)
                                        }
                                    >
                                        {item.headName}{" "}
                                        <i
                                            style={{ fontSize: 16 }}
                                            className={
                                                sortFilter === item.fieldName
                                                    ? item.iconClass
                                                    : sortFilter ===
                                                      `-${item.fieldName}`
                                                    ? item.iconClass2
                                                    : item.iconClass
                                            }
                                        ></i>
                                    </th>
                                ) : (
                                    <th scope="col" key={key}>
                                        {item.headName}
                                    </th>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tr>
            ) : (
                <tr>
                    {dataHead.map((item, key) => {
                        return (
                            <th scope="col" key={key}>
                                {item.headName}
                            </th>
                        );
                    })}
                </tr>
            )}
        </thead>
    );
};

export default TableHead;
