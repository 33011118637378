/*eslint-disable*/
import React from "react";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import { useStore } from "../../../../Reducers";
import service from "../index.service";
import Button from "../../../../Components/Particle/Button";
import styled from "styled-components";
import Header from "../Header";
import { Card, CardBody } from "reactstrap";
import { formatMoney } from "../../../../Utils/PriceConverter";
import { Link } from "react-router-dom";
import ModalDetail from "./ModalDetail";
const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
            .img-supplier {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
            .checkbox {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 24px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .checkbox input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #eee;
            }
            .checkbox:hover input ~ .checkmark {
                background-color: #ccc;
            }

            .checkbox input:checked ~ .checkmark {
                background-color: #2196f3;
            }

            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
            .checkbox input:checked ~ .checkmark:after {
                display: block;
            }
            .checkbox .checkmark:after {
                left: 9px;
                top: 5px;
                width: 7px;
                height: 12px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        transition: all 0.25s ease;
        width: 100%;
    }
`;
const TransactionSupplierList = (
    {
        match: {
            params: { slug },
        },
    },
    props
) => {
    const { dispatch, state } = useStore();
    const [dataSupplier, setDataSupplier] = React.useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [isShow, setShow] = React.useState(false);
    const [data, setData] = React.useState(false);
    const [commissionCustom, setCommissionCustom] = React.useState([]);
    const [commissionFixed, setCommissionFixed] = React.useState([]);
    const [getIdMultiple, setIdMultiple] = React.useState([]);

    const handleShowDetail = (item) => {
        setShow(!isShow);
        setData(item);
    };
    const handleCheckProduct = async (e, item) => {
        const getId = [item];
        let value = e.target.checked;
        if (value) {
            setIdMultiple((prevState) => {
                return [...prevState, ...getId];
            });
        }
        if (!value) {
            onRemoveData(item);
        }
    };
    const onRemoveData = async (value) => {
        setIdMultiple((prevState) => {
            return prevState.filter((item) => item.id !== value.id);
        });
    };
    const statusPayment = (data) => {
        if (data) {
            switch (data.status) {
                case 0:
                    return "Menunggu pembayaran";
                case 1:
                    return "Menunggu konfirmasi dari Supplier";
                case 3:
                    return "Pesanan dalam proses";
                case 4:
                    return "Pesanan dalam perjalanan";
                case 5:
                    return "Pesanan telah tiba";
                case 10:
                    return "Pesanan selesai";
                case 16:
                    return "Pesanan selesai";
                case 13:
                    return "Pembatalan pembayaran";
                default:
                    return "-";
            }
        }
    };
    React.useEffect(() => {
        if (isFirstGet) {
            service.supplierList({
                dispatch,
                params: {
                    filters: `[["id","=","${slug}"]]`,
                },
            });
            service.commission({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch, slug]);
    React.useEffect(() => {
        if (state?.supplier?.supplierList?.isSuccess) {
            setDataSupplier(
                state.supplier.supplierList.data.content.filter(
                    (item) => item.id === Number(slug)
                )
            );
        }
        if (state?.commission?.commission?.isSuccess) {
            setCommissionCustom(
                state.commission.commission.data.content.filter(
                    (item) => item.id === 1
                )
            );
            setCommissionFixed(
                state.commission.commission.data.content.filter(
                    (item) => item.id === 2
                )
            );
        }
    }, [state, dispatch, slug]);
    return (
        <Style>
            {dataSupplier && (
                <Header titlePage={`${dataSupplier[0].store.name}`} />
            )}

            <React.Fragment>
                <div className="d-flex">
                    <section>
                        <div className="card-section">
                            <Card>
                                <CardBody>
                                    <Link to="/admin/transaction/supplier">
                                        <i className="fas fa-arrow-left"></i>{" "}
                                        Kembali
                                    </Link>

                                    <React.Fragment>
                                        <CommonTable
                                            zeroData="Tidak ada transaksi"
                                            searchBy="full_name"
                                            configuration={{
                                                searchInput: true,
                                                searchValue: [
                                                    "product.name",
                                                    "customer_detail.full_name",
                                                ],
                                                nav: {
                                                    hasSearch: true,
                                                    filter: [
                                                        {
                                                            icon: "fas fa-sort-amount-down",
                                                            type: "dropdown",
                                                        },
                                                    ],
                                                },
                                                service: {
                                                    api: service.transactionList,
                                                    id: slug,
                                                    group: "transaction",
                                                    key: "transactionList",
                                                    filters: "",
                                                    filterData: "",
                                                },
                                                optionTable: [
                                                    {
                                                        headName: "No",
                                                        type: "number",
                                                    },
                                                    {
                                                        headName:
                                                            "Nama Pemesan",
                                                        type: "isCostume",
                                                        renderItem: (item) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                "capitalize",
                                                                        }}
                                                                    >
                                                                        {
                                                                            item
                                                                                .customer_detail
                                                                                .full_name
                                                                        }
                                                                    </span>
                                                                </React.Fragment>
                                                            );
                                                        },
                                                    },
                                                    {
                                                        headName:
                                                            "Tanggal Transaksi",
                                                        type: "isCostume",
                                                        renderItem: (item) => {
                                                            let getDateTransaction =
                                                                item.transaction_code.split(
                                                                    "/"
                                                                );
                                                            const date =
                                                                new Date(
                                                                    Number(
                                                                        getDateTransaction[1]
                                                                    )
                                                                );
                                                            return (
                                                                <React.Fragment>
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                "capitalize",
                                                                        }}
                                                                    >
                                                                        {`${date.getFullYear()}-${String(
                                                                            date.getMonth() +
                                                                                1
                                                                        ).padStart(
                                                                            2,
                                                                            "0"
                                                                        )}-${String(
                                                                            date.getDate()
                                                                        ).padStart(
                                                                            2,
                                                                            "0"
                                                                        )}
                                                                        `}
                                                                    </span>
                                                                </React.Fragment>
                                                            );
                                                        },
                                                    },
                                                    {
                                                        headName: "Nama Produk",
                                                        type: "isCostume",
                                                        renderItem: (item) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                "capitalize",
                                                                        }}
                                                                    >
                                                                        {
                                                                            item
                                                                                .product
                                                                                .name
                                                                        }
                                                                    </span>
                                                                </React.Fragment>
                                                            );
                                                        },
                                                    },
                                                    {
                                                        headName: "Harga Jual",
                                                        type: "isCostume",
                                                        renderItem: (item) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                "capitalize",
                                                                        }}
                                                                    >
                                                                        {formatMoney(
                                                                            item
                                                                                .product
                                                                                .price
                                                                        )}
                                                                    </span>
                                                                </React.Fragment>
                                                            );
                                                        },
                                                    },
                                                    {
                                                        headName: "Total Item",
                                                        type: "text",
                                                        fieldName:
                                                            "total_amount",
                                                    },
                                                    {
                                                        headName: "Total Harga",
                                                        type: "isCostume",
                                                        renderItem: (item) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                "capitalize",
                                                                        }}
                                                                    >
                                                                        {formatMoney(
                                                                            item
                                                                                .product
                                                                                .price *
                                                                                item.total_amount
                                                                        )}
                                                                    </span>
                                                                </React.Fragment>
                                                            );
                                                        },
                                                    },

                                                    {
                                                        headName: "Status",
                                                        type: "isCostume",
                                                        renderItem: (item) => {
                                                            return (
                                                                <React.Fragment>
                                                                    {statusPayment(
                                                                        item
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        },
                                                    },
                                                    {
                                                        headName: "Aksi",
                                                        type: "isCostume",
                                                        renderItem: (item) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            handleShowDetail(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        Lihat
                                                                    </Button>
                                                                </React.Fragment>
                                                            );
                                                        },
                                                    },
                                                ],
                                            }}
                                        ></CommonTable>
                                    </React.Fragment>
                                </CardBody>
                            </Card>
                        </div>
                    </section>
                </div>
                <ModalDetail
                    dataSupplier={dataSupplier}
                    commissionFixed={commissionFixed}
                    commissionCustom={commissionCustom}
                    setData={setData}
                    toggle={() => setShow(!isShow)}
                    isShow={isShow}
                    data={data}
                />
            </React.Fragment>
        </Style>
    );
};
export default TransactionSupplierList;
