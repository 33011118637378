import React from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import LocalStorage from "./Utils/LocalStorage";

import RoutesList from "./Router/RoutesList";
import CodeSplit from "./Components/CodeSplit";
const App = (props) => {
    const expired = LocalStorage().get("EX");
    const token = LocalStorage().get("authAdminLakugan");
    React.useEffect(() => {
        var countDownDate = new Date(expired).getTime();

        // Update the count down every 1 second
        var x = setInterval(function () {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            if (distance < 0) {
                clearInterval(x);
                LocalStorage().remove("EX");
                LocalStorage().remove("authAdminLakugan");
            }
        }, 1000);
    }, [expired, token]);
    return (
        <Router>
            <Switch>
                {RoutesList.map((item) => {
                    return (
                        <Route
                            exact
                            key={item.path}
                            path={`/${item.role}` + item.path}
                            component={(props) => (
                                <CodeSplit load={() => item.layout}>
                                    {(Component) =>
                                        Component === null ? (
                                            <div>Loading...</div>
                                        ) : (
                                            <Component
                                                routesList={RoutesList}
                                                item={item}
                                                {...props}
                                            />
                                        )
                                    }
                                </CodeSplit>
                            )}
                        />
                    );
                })}

                <Redirect to="/404" />
            </Switch>
        </Router>
    );
};

export default App;
