import React from "react";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import { useStore } from "../../../../Reducers";
import service from "../index.service";
import Button from "../../../../Components/Particle/Button";
import styled from "styled-components";
import Header from "../Header";
import { Card, CardBody } from "reactstrap";
import { formatMoney } from "../../../../Utils/PriceConverter";
import { Link } from "react-router-dom";
import ModalDetail from "./ModalDetail";
const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
            .img-supplier {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
        }
        transition: all 0.25s ease;
        width: 100%;
    }
`;
const TransactionListReseller = (
    {
        match: {
            params: { slug },
        },
    },
    props
) => {
    const { dispatch, state } = useStore();
    const [dataReseller, setDataReseller] = React.useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [isShow, setShow] = React.useState(false);
    const [data, setData] = React.useState(false);
    const [commissionCustom, setCommissionCustom] = React.useState([]);
    const [commissionFixed, setCommissionFixed] = React.useState([]);

    const handleShowDetail = (item) => {
        setShow(!isShow);
        setData(item);
    };

    React.useEffect(() => {
        if (isFirstGet) {
            service.resellerList({
                dispatch,
                params: {
                    filters: `[["id","=","${slug}"]]`,
                },
            });
            service.commission({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch, slug]);
    React.useEffect(() => {
        if (state?.transaction?.transactionList?.isSuccess) {
            service.transactionListClear({ dispatch });
        }
        if (state?.reseller?.resellerList?.isSuccess) {
            setDataReseller(
                state.reseller.resellerList.data.content.filter(
                    (item) => item.id === Number(slug)
                )
            );
        }
        if (state?.commission?.commission?.isSuccess) {
            setCommissionCustom(
                state.commission.commission.data.content.filter(
                    (item) => item.id === 1
                )
            );
            setCommissionFixed(
                state.commission.commission.data.content.filter(
                    (item) => item.id === 2
                )
            );
        }
    }, [state, dispatch, slug]);
    return (
        <Style>
            <Header titlePage={"Reseller"} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <Card>
                            <CardBody>
                                <Link to="/admin/finance/reseller">
                                    <i className="fas fa-arrow-left"></i>{" "}
                                    Kembali
                                </Link>
                                <CommonTable
                                    searchBy="full_name"
                                    configuration={{
                                        searchInput: true,
                                        searchValue: [
                                            "product.name",
                                            // "customer_detail.full_name",
                                            // "customer_detail.phone_number",
                                        ],
                                        nav: {
                                            hasSearch: true,
                                            filter: [
                                                {
                                                    icon: "fas fa-sort-amount-down",
                                                    type: "dropdown",
                                                },
                                            ],
                                        },
                                        service: {
                                            api: service.transactionList,
                                            id: slug,
                                            group: "transaction",
                                            key: "transactionList",
                                            filters: "",
                                        },
                                        optionTable: [
                                            {
                                                headName: "No",
                                                type: "number",
                                            },
                                            {
                                                headName: "Nama Pemesan",
                                                type: "isCostume",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span
                                                                style={{
                                                                    textTransform:
                                                                        "capitalize",
                                                                }}
                                                            >
                                                                {
                                                                    item
                                                                        .customer_detail
                                                                        .full_name
                                                                }
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Tanggal Transaksi",
                                                type: "isCostume",
                                                renderItem: (item) => {
                                                    let getDateTransaction =
                                                        item.transaction_code.split(
                                                            "/"
                                                        );
                                                    const date = new Date(
                                                        Number(
                                                            getDateTransaction[1]
                                                        )
                                                    );
                                                    return (
                                                        <React.Fragment>
                                                            <span
                                                                style={{
                                                                    textTransform:
                                                                        "capitalize",
                                                                }}
                                                            >
                                                                {`${date.getFullYear()}-${String(
                                                                    date.getMonth() +
                                                                        1
                                                                ).padStart(
                                                                    2,
                                                                    "0"
                                                                )}-${String(
                                                                    date.getDate()
                                                                ).padStart(
                                                                    2,
                                                                    "0"
                                                                )}
                                                                        `}
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Nama Produk",
                                                type: "isCostume",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span
                                                                style={{
                                                                    textTransform:
                                                                        "capitalize",
                                                                }}
                                                            >
                                                                {
                                                                    item.product
                                                                        .name
                                                                }
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Harga Jual",
                                                type: "isCostume",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span
                                                                style={{
                                                                    textTransform:
                                                                        "capitalize",
                                                                }}
                                                            >
                                                                {formatMoney(
                                                                    item.product
                                                                        .price
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Total Item",
                                                type: "text",
                                                fieldName: "total_amount",
                                            },
                                            {
                                                headName: "Total Harga",
                                                type: "isCostume",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span
                                                                style={{
                                                                    textTransform:
                                                                        "capitalize",
                                                                }}
                                                            >
                                                                {formatMoney(
                                                                    item.product
                                                                        .price *
                                                                        item.total_amount
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Aksi",
                                                type: "isCostume",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <Button
                                                                color="primary"
                                                                onClick={() =>
                                                                    handleShowDetail(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                Lihat
                                                            </Button>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                        ],
                                    }}
                                ></CommonTable>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
            <ModalDetail
                dataReseller={dataReseller}
                commissionFixed={commissionFixed}
                commissionCustom={commissionCustom}
                setData={setData}
                toggle={() => setShow(!isShow)}
                isShow={isShow}
                data={data}
            />
        </Style>
    );
};
export default TransactionListReseller;
