/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import styled from "styled-components";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import service from "../index.service";
const Style = styled.div`
    .lower-text {
        text-transform: capitalize;
    }
`;
const ModalAddPaymentMethod = ({
    isShow = false,
    toggle = () => {},
    setShow = () => {},
    setLoading = () => {},
    setRefresh = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [isMessage, setMessage] = React.useState(false);
    const [form, setForm] = useState([
        {
            type: "text",
            action: "text",
            label: "Nama",
            name: "name",
            required: true,
            placeholder: "Nama",
            col: 6,
            valid: "Nama tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "image",
            action: "uploadImage",
            required: true,
            name: "image",
            placeholder: "Klik Untuk Masukan Gambar",
            col: 12,
            valid: "Harap masukan gambar",
            value: "",
            status: false,
            normalData: false,
            heightStyle: "200px",
        },
        {
            type: "text",
            action: "text",
            label: "Type",
            name: "type",
            required: true,
            className: "lower-text",
            placeholder: "Type",
            col: 6,
            valid: "Type tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "checkbox",
            action: "checkbox",
            label: "Publish",
            name: "publish",
            placeholder: "*Publish",
            col: 6,
            valid: "Publish tidak boleh kosong",
            value: false,
            status: true,
        },
        {
            required: false,
            type: "price",
            action: "price",
            label: "Fee",
            name: "fee",
            placeholder: "Fee",
            currencyLogo: "Rp. ",
            col: 6,
            valid: "Fee tidak boleh kosong",
            value: "",
            status: true,
        },
        {
            required: false,
            type: "price",
            action: "price",
            label: "Percentage",
            name: "percentage",
            placeholder: "Percentage",
            col: 6,
            precision: 1,
            suffix: " %",
            valid: "Percentage tidak boleh kosong",
            value: 0,
            status: true,
        },
        {
            type: "text",
            action: "text",
            label: "Vendor",
            required: true,
            name: "vendor",
            placeholder: "*Vendor",
            col: 6,
            valid: "Vendor tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "text",
            action: "text",
            label: "Channel",
            required: true,
            name: "channel",
            placeholder: "*Channel",
            col: 6,
            valid: "Channel tidak boleh kosong",
            value: "",
            status: false,
        },
    ]);
    const handlerSubmitData = () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let data = {
                name: form[0].value,
                image: form[1].value,
                type: form[2].value,
                publish: form[3].value,
                fee: form[4].value,
                percentages: form[5].value,
                vendor: form[6].value,
                channel: form[7].value,
            };
            service.newPaymentMethod({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (state?.payment?.newPaymentMethod?.isSuccess) {
            setShow(false);
            setLoading(false);
            setRefresh(true);
            form[0].value = "";
            form[1].value = "";
            form[2].value = "";
            form[3].value = "";
            form[4].value = "";
            form[5].value = "";
            form[6].value = "";
            form[7].value = "";
            service.newPMClear({ dispatch });
        }
        if (state?.payment?.newPaymentMethod?.isError) {
            setShow(false);
            setLoading(false);
            setRefresh(true);
            setMessage(state?.payment?.newPaymentMethod.message.text);
            service.newPMClear({ dispatch });
        }
    }, [state]);
    React.useEffect(() => {
        const timer = setTimeout(() => setMessage(false), 2000);
        return () => clearTimeout(timer);
    });
    return (
        <Modal size="lg" isOpen={isShow} toggle={toggle}>
            {isMessage && <Alert color="danger">{isMessage}</Alert>}
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Tambah Payment Method</h5>
                <Style>
                    <Form
                        isShow={isShow}
                        setForm={setForm}
                        validateForm={validateForm}
                        validationClick={validationClick}
                        form={form}
                    />
                </Style>
                <div className="text-right">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => handlerSubmitData()}
                    >
                        Tambah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default ModalAddPaymentMethod;
