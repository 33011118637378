import { servicesAction } from "../../../Reducers";

const service = {
    //Category Fetch
    category: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/category/?filters=[["type","=","1"]]`,
            method: "GET",
            token: true,
            qs: true,
            params: params,
            reducer: "service",
            group: "category",
            key: "category",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },

    //Blog Service
    getBlog: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/article/",
            params: params,
            method: "GET",
            token: true,
            qs: true,
            reducer: "service",
            group: "blog",
            key: "getBlog",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    blogList: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/article/new",
            method: "POST",
            token: true,
            reducer: "service",
            qs: true,
            data: data,
            group: "blog",
            key: "blogList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Blog berhasil di tambahkan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    updateBlog: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/article/update/${id}`,
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "blog",
            key: "update",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Blog berhasil di ubah!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, Blog already accessed",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, Blog already accessed",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    deleteBlog: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/article/delete/${id}`,
            method: "DELETE",
            token: true,
            data: data,
            reducer: "service",
            group: "blog",
            key: "delete",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Blog berhasil di hapuskan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    blogClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "blog",
            key: "blogList",
        });
    },
    editClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "blog",
            key: "update",
        });
    },
    deleteClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "blog",
            key: "delete",
        });
    },

    //FAQ Service
    categoryFaq: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/faq/`,
            method: "GET",
            token: true,
            qs: true,
            params: params,
            reducer: "service",
            group: "faq",
            key: "faqList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    AddFAQ: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/faq/new`,
            method: "POST",
            token: true,
            qs: true,
            data: data,
            reducer: "service",
            group: "faq",
            key: "addFAQ",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "FAQ berhasil di tambahkan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    deleteFAQ: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/faq/delete/${id}`,
            method: "DELETE",
            token: true,
            data: data,
            reducer: "service",
            group: "faq",
            key: "delete",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "FAQ berhasil di hapuskan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    updateFAQ: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/faq/update/${id}`,
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "faq",
            key: "update",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "FAQ berhasil di ubah!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    clearFAQ: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "faq",
            key: "addFAQ",
        });
    },
    editClearFAQ: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "faq",
            key: "update",
        });
    },
    deleteClearFAQ: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "faq",
            key: "delete",
        });
    },

    //QA Service
    AddQA: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/qa/new`,
            method: "POST",
            token: true,
            qs: true,
            data: data,
            reducer: "service",
            group: "qa",
            key: "addQA",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "QA berhasil di tambahkan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    updateQA: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/qa/update/question/${id}`,
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "qa",
            key: "update",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "QA berhasil di ubah!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    deleteQA: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/qa/delete/question/${id}`,
            method: "DELETE",
            token: true,
            data: data,
            reducer: "service",
            group: "qa",
            key: "delete",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "QA berhasil di hapuskan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    clearQA: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "qa",
            key: "addQA",
        });
    },
    editClearQA: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "qa",
            key: "update",
        });
    },
    deleteClearQA: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "qa",
            key: "delete",
        });
    },

    //Banner Service
    getBanner: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/banner/`,
            method: "GET",
            token: true,
            qs: true,
            params: params,
            reducer: "service",
            group: "banner",
            key: "getBanner",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    newBanner: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/banner/new",
            method: "POST",
            token: true,
            reducer: "service",
            qs: true,
            data: data,
            group: "banner",
            key: "newBanner",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Banner berhasil di tambahkan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal server error",
                },
            },
        });
    },
    updateBanner: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/banner/update/${id}`,
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "banner",
            key: "update",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Banner berhasil di ubah!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal server error",
                },
            },
        });
    },
    deleteBanner: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/banner/delete/${id}`,
            method: "DELETE",
            token: true,
            data: data,
            reducer: "service",
            group: "banner",
            key: "delete",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Banner berhasil di hapuskan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    bannerClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "banner",
            key: "newBanner",
        });
    },
    bannerEditClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "banner",
            key: "update",
        });
    },
    bannerDeleteClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "banner",
            key: "delete",
        });
    },

    //About us Service
    getArticle: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/about_us/`,
            method: "GET",
            token: true,
            qs: true,
            params: params,
            reducer: "service",
            group: "aboutus",
            key: "getArticle",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    newAboutUs: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/about_us/new",
            method: "POST",
            token: true,
            reducer: "service",
            qs: true,
            data: data,
            group: "aboutus",
            key: "newAboutUs",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Sukses menambahkan artikel Lakugan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    updateArticle: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/about_us/update/${id}`,
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "aboutus",
            key: "update",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Sukses Update artikel lakugan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    deleteArticle: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/about_us/delete/${id}`,
            method: "DELETE",
            token: true,
            data: data,
            reducer: "service",
            group: "aboutus",
            key: "delete",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Sukses Hapus artikel lakugan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    aboutUsClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "aboutus",
            key: "newAboutUs",
        });
    },
    articleEditClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "aboutus",
            key: "update",
        });
    },
    articleDeleteClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "aboutus",
            key: "delete",
        });
    },

    // Payment method Service
    getPaymentMethod: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/payment/method/`,
            method: "GET",
            params: params,
            qs: true,
            token: true,
            reducer: "service",
            group: "payment",
            key: "payment",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    deletePaymentMethod: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/payment/method/delete/${id}`,
            method: "DELETE",
            token: true,
            reducer: "service",
            group: "payment",
            key: "delete",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Berhasil dihapus",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },

    newPaymentMethod: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/payment/method",
            method: "POST",
            token: true,
            reducer: "service",
            qs: true,
            data: data,
            group: "payment",
            key: "newPaymentMethod",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Payment Method berhasil di tambahkan!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    updatePaymentMethod: ({ dispatch, data, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/payment/method/update/${id}`,
            method: "PUT",
            token: true,
            reducer: "service",
            qs: true,
            data: data,
            group: "payment",
            key: "newPaymentMethod",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Payment Method berhasil di ubah!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 401, please check data",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Error 400, please check data",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Server Error",
                },
            },
        });
    },
    deletePaymentClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "payment",
            key: "delete",
        });
    },
    newPMClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "payment",
            key: "newPaymentMethod",
        });
    },
};

export default service;
