/*eslint-disable*/
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";
import Form from "../../../Components/Molekul/FormValidation";
import Button from "../../../Components/Particle/Button";
import { useStore } from "../../../Reducers";
import service from "./index.servis";

const ModalVoucher = ({
    isShow = true,
    toggle = () => {},
    setRefresh = () => {},
    setMessageText = () => {},
    setShowMessage = () => {},
    setStatus = () => {},
}) => {
    const today = new Date();
    const tomorrow = new Date(today);
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [validateForm, setValidateForm] = React.useState(true);
    const [form, setForm] = React.useState([
        {
            name: "voucher_name",
            type: "text",
            action: "text",
            currencyLogo: "",
            label: "Nama Voucher",
            required: true,
            valid: "Nama voucher harus di isi",
            col: 12,
            value: "",
            status: false,
        },
        {
            name: "price",
            type: "price",
            action: "price",
            suffix: "%",
            label: "Diskon",
            valid: "Jumlah diskon harus di isi",
            required: true,
            col: 6,
            value: "",
            status: false,
        },
        {
            name: "price",
            type: "price",
            action: "price",
            currencyLogo: "Rp. ",
            valid: "Maksimal diskon harus di isi",
            label: "Maksimal Diskon",
            required: true,
            col: 6,
            value: "",
            status: false,
        },
        {
            name: "voucher_total",
            type: "number",
            action: "text",
            currencyLogo: "",
            label: "Jumlah Voucher",
            required: true,
            valid: "Jumlah voucher harus di isi",
            col: 6,
            value: "",
            status: false,
        },
        {
            type: "datepicker",
            action: "datepicker",
            label: "Tanggal Berlaku",
            name: "publish_until",
            col: 6,
            valid: "Tanggal berlaku tidak boleh kosong",
            value: tomorrow.setDate(tomorrow.getDate() + 1),
            minDate: tomorrow.setDate(tomorrow.getDate()),
            status: true,
        },
        {
            type: "checkbox",
            action: "checkbox",
            label: "Published?",
            name: "published",
            col: 6,
            valid: "Published tidak boleh kosong",
            value: false,
            status: true,
        },
    ]);
    const handlerSubmitData = (e) => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            let data = {
                max_discount: form[2].value,
                discount_percentages: form[1].value,
                name: form[0].value,
                publish_until: form[4].value,
                published: form[5].value,
                limit_voucher: form[3].value,
            };
            setLoading(true);
            setRefresh(false);
            service.voucherPost({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (state?.voucher?.voucherPost?.isSuccess) {
            setStatus(state.voucher.voucherPost.isSuccess);
            setRefresh(true);
            toggle();
            setLoading(false);
            setMessageText(state.voucher.voucherPost.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.voucherPostClear({ dispatch });
        }
        if (state?.voucher?.voucherPost?.isError) {
            setStatus(state.voucher.voucherPost.isSuccess);
            setMessageText(state.voucher.voucherPost.data.message);
            setShowMessage(true);
            setLoading(false);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.voucherPostClear({ dispatch });
        }
    }, [state, dispatch]);
    return (
        <Modal isOpen={isShow} toggle={toggle}>
            <GrowingLoading active={loading} />
            <ModalHeader toggle={toggle}>Create Voucher</ModalHeader>
            <ModalBody>
                <Form
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
                <div className="text-right">
                    <Button onClick={handlerSubmitData} color="primary">
                        Simpan
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default ModalVoucher;
