import React from "react";
import { Card, CardBody } from "reactstrap";
import Button from "../../../../Components/Particle/Button";
import styled from "styled-components";
import service from "../index.service";
import DefaultImage from "../../../../Components/Assets/Image/store_default.png";
import Header from "../Header";
import Tab from "../Tab";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import { useHistory } from "react-router";
const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
            .img-supplier {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
        }
        transition: all 0.25s ease;
        width: 100%;
    }
`;
const TransactionSupplier = () => {
    const history = useHistory();
    const handleDetailSupplier = (item) => {
        history.push(`/admin/transaction/transaction-supplier/${item.id}`);
    };
    return (
        <Style>
            <Header titlePage={"Supplier"} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <Card>
                            <CardBody>
                                <Tab />
                                <CommonTable
                                    searchBy="full_name"
                                    configuration={{
                                        searchInput: true,
                                        searchValue: [
                                            "store.name",
                                            "full_name",
                                            "email",
                                        ],

                                        // searchValue: "store.name",
                                        header: false,
                                        nav: {
                                            hasSearch: true,
                                            filter: [
                                                {
                                                    icon: "fas fa-sort-amount-down",
                                                    type: "dropdown",
                                                    value: [
                                                        {
                                                            value: "email",
                                                            label: "Email",
                                                            key: "email",
                                                        },
                                                        {
                                                            value: "full_name",
                                                            label: "Name",
                                                            key: "full_name",
                                                        },
                                                        {
                                                            value: "store.name",
                                                            label: "Nama Toko",
                                                            key: "store.name",
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        service: {
                                            api: service.supplierList,
                                            group: "supplier",
                                            key: "supplierList",
                                            filters: "",
                                            filterData: "",
                                        },
                                        optionTable: [
                                            {
                                                headName: "No",
                                                type: "number",
                                            },
                                            {
                                                headName: "Nama Toko",
                                                type: "text",
                                                fieldName: "store.name",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <img
                                                                src={
                                                                    item.store
                                                                        .image_store !==
                                                                    undefined
                                                                        ? item
                                                                              .store
                                                                              .image_store
                                                                        : DefaultImage
                                                                }
                                                                alt=""
                                                                className="img-fluid mr-3 img-supplier"
                                                            />
                                                            <span>
                                                                {
                                                                    item.store
                                                                        .name
                                                                }
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Nama Pemilik",
                                                type: "text",
                                                fieldName: "full_name",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                            },
                                            {
                                                headName: "Telepon",
                                                type: "text",
                                                fieldName: "phone_number",
                                                iconClass:
                                                    "fas fa-sort-numeric-down",
                                            },
                                            {
                                                headName: "Tanggal Bergabung",
                                                type: "isCostume",
                                                fieldName: "created",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span>
                                                                {item.created.substring(
                                                                    0,
                                                                    10
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Saldo",
                                                type: "price",
                                                fieldName: "wallet.nominal",
                                                iconClass:
                                                    "fas fa-sort-numeric-down",
                                            },
                                            {
                                                headName: "Aksi",
                                                type: "isCostume",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <Button
                                                                color="primary"
                                                                onClick={() =>
                                                                    handleDetailSupplier(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                Lihat transaksi
                                                            </Button>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                        ],
                                    }}
                                ></CommonTable>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
        </Style>
    );
};
export default TransactionSupplier;
