import React from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import TabMenu from "../../../../Components/Molekul/TabMenu";
import styled from "styled-components";
import DefaultImage from "../../../../Components/Assets/Image/store_default.png";
import { color } from "../../../../Utils/Variable";
import { formatMoney } from "../../../../Utils/PriceConverter";
import ListReseller from "./ListReseller";
import Button from "../../../../Components/Particle/Button";
import ListProduct from "./ListProduct";
import { useStore } from "../../../../Reducers";
import service from "../index.service";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import MessageState from "../../../../Components/Modal/Message";
import FormText from "../../../../Components/Particle/Form/FormText";

const Style = styled(Modal)`
    .information-tab {
        text-align: center;
    }
    .right-half {
        display: table-cell;
        width: 60%;
    }
    .left-half {
        display: table-cell;
        width: 60%;
    }
    .button-modal {
        margin: 0 4px;
        width: 100%;
    }
    .less-more p {
        float: right;
        font-size: 12px;
        text-decoration: none;
        cursor: pointer;
        color: #898b8a;
    }
    .avatar {
        margin: 0 auto 20px;
        display: block;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        background: #aaa;
        object-fit: contain;
    }
    .img-identity {
        width: 250px;
        height: 150px;
        margin: auto;
        object-fit: contain;
        display: block;
    }
    .tab-content {
        border: unset;
    }
    .btn-identity {
        background: transparent;
        border: unset;
        padding: 0;
        margin: 0;
        display: block;
    }
    .tab-top-title {
        width: 100%;
        text-align: center;
        margin: auto;
        display: block;
    }
    .header {
        text-align: left;
        margin: 5px;
        padding: 5px;
        font-weight: bold;
        @media (max-width: 1199px) {
            text-align: center;
        }
    }
    .title {
        color: #898b8a;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
    }
    .desc {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        &-value {
            color: #898b8a;
            font-size: 14px;
            &.text {
                text-align: right;
                width: 100%;
            }
        }
    }
`;

const Detail = ({
    verify = false,
    item = false,
    isShow = false,
    toggle = () => {},
    setRefresh = () => {},
    setShow = () => {},
}) => {
    const [isOpen, setOpen] = React.useState(false);
    const { dispatch, state } = useStore();
    const [message, setMessage] = React.useState("");
    const [modalReason, setModalReason] = React.useState(false);
    const [modalConfirmation, setModalConfirmation] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [isModalStatusMessage, setModalStatusMessage] = React.useState(false);
    const [isMore, setMore] = React.useState(false);
    const [reason, setReason] = React.useState("");
    const [reasonObj, setReasonObj] = React.useState({
        label: "Alasan Penolakan",
        value: reason,
        required: true,
        type: "textarea",
        rows: 5,
        valid: "Alasan wajib di isi",
        character: {
            min: {
                length: 3,
                valid: "Minimal 3 character",
            },
            max: {
                length: 100,
                valid: "Maximal 100 character",
            },
        },
        status: reason === "" ? false : true,
    });
    const toggleIdentity = () => {
        setOpen(!isOpen);
    };
    const handleVerifyUser = (id, verify) => {
        if (verify === "rejected") {
            setModalReason(true);
        } else if (verify === "confirmation") {
            setModalConfirmation(true);
        } else {
            setLoading(true);
            setRefresh(false);
            service.verifyUser({ dispatch, id, verify });
        }
    };
    const handleAcceptRejected = (id, verify) => {
        let params = {
            notifVerifikasi: reasonObj.value,
        };
        setLoading(true);
        setRefresh(false);
        service.verifyUser({ dispatch, id, verify, params });
    };
    const closeModal = () => {
        setMore(false);
        toggle();
    };
    const onGetReason = (value) => {
        reasonObj.value = value.value;
        reasonObj.status = value.value === "" ? false : true;
        setReasonObj(reasonObj);
        setReason(value.value);
    };
    React.useEffect(() => {
        if (state?.user?.verifyUser?.isSuccess) {
            setLoading(false);
            setRefresh(true);
            setMessage(state.user.verifyUser.message.text);
            setModalStatusMessage(true);
            service.verifyUserClear({ dispatch });
            setStatus(state.user.verifyUser.isSuccess);
            setTimeout(() => {
                setModalStatusMessage(false);
                setModalReason(false);
                setShow(false);
            }, 3000);
        }
        if (state?.user?.verifyUser?.isError) {
            setLoading(false);
            setRefresh(true);
            setMessage(state.user.verifyUser.data.message);
            setModalStatusMessage(true);
            service.verifyUserClear({ dispatch });
            setStatus(state.user.verifyUser.isSuccess);
            setTimeout(() => {
                setModalStatusMessage(false);
            }, 3000);
        }
    }, [state, dispatch, setRefresh, setShow]);
    const extraContent = (
        <div>
            <h6 className="information-tab">Informasi Rekening</h6>
            <div className="desc">
                <div className="title">Nama bank</div>
                <div className="desc-value text">
                    {item?.bank?.bank_name || "-"}
                </div>
            </div>
            <div className="desc">
                <div className="title">Cabang bank</div>
                <div className="desc-value text">
                    {item?.bank?.bank_branch || "-"}
                </div>
            </div>
            <div className="desc">
                <div className="title">Nama Rekening</div>
                <div className="desc-value text">
                    {item?.bank?.account_name || "-"}
                </div>
            </div>
            <div className="desc">
                <div className="title">Nomor Rekening</div>
                <div className="desc-value text">
                    {item?.bank?.account_number || "-"}
                </div>
            </div>
            <h6 className="information-tab">Informasi Toko</h6>
            <div className="desc">
                <div className="title">Provinsi</div>
                <div className="desc-value text">
                    {item?.province !== null ? item?.province : "-"}
                </div>
            </div>
            <div className="desc">
                <div className="title">Kecamatan</div>
                <div className="desc-value text">
                    {item?.district !== null ? item?.district : "-"}
                </div>
            </div>
            <div className="desc">
                <div className="title">Kota</div>
                <div className="desc-value text">
                    {item?.city !== null ? item?.city : "-"}
                </div>
            </div>
            <div className="desc">
                <div className="title">Kode pos</div>
                <div className="desc-value text">
                    {item?.postal_code !== null ? item?.postal_code : "-"}
                </div>
            </div>
            <div className="desc">
                <div className="title">Alamat</div>
                <div className="desc-value text">
                    {item?.address !== null ? item?.address : "-"}
                </div>
            </div>
            <div className="desc">
                <div className="title">Nomor Telepon Toko</div>
                <div className="desc-value text">
                    {item?.store?.phone_cs !== null
                        ? item?.store?.phone_cs
                        : "-"}
                </div>
            </div>
            <div className="desc">
                <div className="title">Deskripsi</div>
                <div
                    className="desc-value text"
                    dangerouslySetInnerHTML={{
                        __html:
                            item?.description !== null
                                ? item?.description
                                : "-",
                    }}
                ></div>
            </div>
        </div>
    );

    const linkName = isMore ? "Lebih Sedikit << " : "Lebih Banyak >> ";
    return (
        <Style size="lg" isOpen={isShow} toggle={closeModal}>
            <GrowingLoading active={isLoading} />
            <ModalBody>
                <Row>
                    <Col xl="12">
                        <Row>
                            <Col md={6}>
                                <div>
                                    <label htmlFor="">Foto Profile</label>
                                    <img
                                        src={
                                            item?.store?.image_store !==
                                            undefined
                                                ? item?.store?.image_store
                                                : DefaultImage
                                        }
                                        alt=""
                                        className="avatar"
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="h-100">
                                    <label htmlFor="">Foto KTP</label>
                                    {item?.identity_photo === null ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                color: "#aaa",
                                                height: "100%",
                                            }}
                                        >
                                            Tidak tersedia
                                        </div>
                                    ) : (
                                        <button
                                            className="btn-identity"
                                            onClick={() =>
                                                toggleIdentity(
                                                    item?.identity_photo
                                                )
                                            }
                                        >
                                            <img
                                                src={item?.identity_photo}
                                                alt=""
                                                className="img-fluid img-identity"
                                            />
                                        </button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl="12">
                        <h4 className="header">{item?.store?.name}</h4>
                        <div className="mb-6">
                            <h6 className="information-tab">Informasi Umum</h6>
                            <div className="desc">
                                <div className="title">NIK</div>
                                <div className="desc-value text">
                                    {item?.nik !== null ? item.nik : "-"}
                                </div>
                            </div>
                            <div className="desc">
                                <div className="title">Nama Pemilik</div>
                                <div className="desc-value text">
                                    {item?.full_name}
                                </div>
                            </div>
                            <div className="desc">
                                <div className="title">Telepon Pemilik</div>
                                <div className="desc-value text">
                                    {item?.phone_number !== null
                                        ? item?.phone_number
                                        : "-"}
                                </div>
                            </div>
                            <div className="desc">
                                <div className="title">Email</div>
                                <div className="desc-value text">
                                    {item?.email !== null ? item.email : "-"}
                                </div>
                            </div>
                            <div className="desc">
                                <div className="title">Saldo</div>
                                <div className="desc-value text">
                                    Saldo : {formatMoney(item?.wallet?.nominal)}
                                </div>
                            </div>
                            <div className="desc">
                                <div className="title">
                                    Total Saldo yang sudah di cairkan
                                </div>
                                <div className="desc-value text">
                                    {formatMoney(item?.wallet?.nominal)}
                                </div>
                            </div>
                            {isMore && extraContent}
                            <div
                                className="less-more"
                                onClick={() => {
                                    setMore(!isMore);
                                }}
                            >
                                <p>{linkName}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                {verify && item?.identity_photo !== null && (
                    <React.Fragment>
                        <Row>
                            <Col xl={12}>
                                {item?.update_data ? (
                                    <div className="text-right">
                                        <Button
                                            onClick={() =>
                                                handleVerifyUser(
                                                    item?.id,
                                                    "rejected"
                                                )
                                            }
                                            color="danger"
                                            style={{ marginRight: 10 }}
                                        >
                                            Tolak Akses
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() =>
                                                handleVerifyUser(
                                                    item?.id,
                                                    "confirmation"
                                                )
                                            }
                                        >
                                            Beri Akses
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="mb-3 text-center text-danger">
                                        <i>
                                            User ini telah ditolak untuk
                                            diberikan akses
                                        </i>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
                <TabMenu
                    styleTab={true}
                    mergesTab={false}
                    color={{
                        text: color.primary,
                        background: "#fff",
                        activeText: "#fff",
                        activeColor: color.primary,
                        activeBackground: "#fff",
                    }}
                    data={[
                        {
                            title: "List Reseller",
                            key: 1,
                            render: () => {
                                return (
                                    <ListReseller
                                        item={item?.store?.resellers}
                                    />
                                );
                            },
                        },
                        {
                            title: "List Produk",
                            key: 1,
                            render: () => {
                                return (
                                    <ListProduct
                                        data={item?.store.product_list}
                                    />
                                );
                            },
                        },
                    ]}
                />
                <MessageState
                    isShow={isModalStatusMessage}
                    status={status}
                    message={message}
                />
            </ModalBody>
            <Modal size="xl" toggle={toggleIdentity} isOpen={isOpen}>
                <img src={item?.identity_photo} alt="" className="img-fluid" />
            </Modal>
            <Modal isOpen={modalReason}>
                <ModalBody>
                    <Row>
                        <Col xl={12}>
                            <FormText
                                onGetValue={onGetReason}
                                item={reasonObj}
                            />
                        </Col>
                        <Col xl={12}>
                            <div className="text-right">
                                <Button
                                    onClick={() => setModalReason(false)}
                                    style={{ marginRight: "5px" }}
                                >
                                    Batal
                                </Button>
                                <Button
                                    disabled={!reasonObj.status}
                                    color="danger"
                                    onClick={() =>
                                        handleAcceptRejected(
                                            item?.id,
                                            "rejected"
                                        )
                                    }
                                >
                                    Tolak
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal centered isOpen={modalConfirmation}>
                <ModalBody>
                    <Col xl={12}>
                        <p>
                            Apakah anda yakin ingin memberi akses pada
                            <span className="font-weight-bold">
                                {" "}
                                "{item?.full_name}"{" "}
                            </span>
                            ?
                        </p>
                    </Col>
                    <Col xl={12}>
                        <div className="d-flex justify-content-between">
                            <Button
                                onClick={() => setModalConfirmation(false)}
                                style={{ width: "49%" }}
                            >
                                Tidak
                            </Button>
                            <Button
                                onClick={() =>
                                    handleAcceptRejected(item?.id, "verified")
                                }
                                color="danger"
                                style={{ width: "49%" }}
                            >
                                Ya
                            </Button>
                        </div>
                    </Col>
                </ModalBody>
            </Modal>
        </Style>
    );
};
export default Detail;
