/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const ModalEditQA = ({
    isEdit = false,
    dataFAQ = false,
    setDataFAQ = () => {},
    setLoading = () => {},
    setRefresh = () => {},
    setEdit = () => {},
    isID = false,
    setID = () => {},
}) => {
    const { dispatch } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [form, setForm] = useState([
        {
            type: "text",
            action: "text",
            label: "Question",
            name: "question",
            placeholder: "*Question",
            col: 6,
            valid: "Question tidak boleh kosong",
            value: isID?.question,
            status: true,
        },
        {
            type: "html",
            action: "html",
            name: "answer",
            placeholder: "*Answer",
            col: 8,
            valid: "Answer tidak boleh kosong",
            value: isID?.answer,
            rows: 3,
            status: false,
        },
    ]);
    const updateValueQA = () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let id = isID.id;
            let data = {
                id: isID.id,
                question: form[0].value,
                answer: form[1].value,
                faq: {
                    id: dataFAQ.id,
                    category: dataFAQ.category,
                    title: dataFAQ.title,
                },
            };
            service.updateQA({ dispatch, id, data });
        }
        setRefresh(false);
        setLoading(true);
    };
    const closePopUp = () => {
        setID(false);
        setEdit(!isEdit);
        setDataFAQ(false);
    };
    return (
        <Modal size="lg" isOpen={isEdit} toggle={closePopUp}>
            <ModalHeader className="border-0 pb-0" toggle={closePopUp} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Ubah QA</h5>
                <Form
                    isShow={isEdit}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />

                <div className="text-right">
                    <Button
                        color="success"
                        onClick={updateValueQA}
                        size="sm"
                        type="button"
                    >
                        <i className="fas fa-edit mr-2"></i>Ubah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default ModalEditQA;
