/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import styled from "styled-components";
import service from "../index.service";
const Style = styled.div`
    .lower-text {
        text-transform: capitalize;
    }
`;
const ModalEditPayment = ({
    setStatus = () => {},
    setMessageText = () => {},
    isShow = false,
    setShowMessage = () => {},
    setData = () => {},
    toggle = () => {},
    setShow = () => {},
    setLoading = () => {},
    setRefresh = () => {},
    item = false,
}) => {
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [isMessage, setMessage] = React.useState(false);
    const [form, setForm] = useState([
        {
            type: "text",
            action: "text",
            label: "Nama",
            required: true,
            name: "name",
            placeholder: "*Nama",
            col: 6,
            valid: "Nama tidak boleh kosong",
            value: item.name,
            status: true,
        },
        {
            type: "image",
            action: "uploadImage",
            required: true,
            name: "image",
            placeholder: "Klik Untuk Masukan Gambar",
            col: 12,
            valid: "Harap masukan gambar",
            value: item.image,
            status: true,
            normalData: false,
            heightStyle: "200px",
        },
        {
            type: "text",
            action: "text",
            label: "Type",
            className: "lower-text",
            required: true,
            name: "type",
            placeholder: "*Type",
            col: 6,
            valid: "Type tidak boleh kosong",
            value: item.type,
            status: true,
        },
        {
            type: "checkbox",
            action: "checkbox",
            label: "Publish",
            name: "publish",
            placeholder: "*Publish",
            col: 6,
            valid: "Publish tidak boleh kosong",
            value: item.publish,
            status: true,
        },
        {
            required: false,
            type: "price",
            action: "price",
            label: "Fee",
            name: "fee",
            placeholder: "Fee",
            currencyLogo: "Rp. ",
            col: 6,
            valid: "Fee tidak boleh kosong",
            value: item.fee,
            defaultValue: item.fee,
            status: true,
        },
        {
            required: false,
            type: "price",
            action: "price",
            label: "Percentage",
            name: "percentage",
            placeholder: "Percentage",
            col: 6,
            precision: 1,
            suffix: " %",
            valid: "Percentage tidak boleh kosong",
            value: item.percentages,
            defaultValue: item.percentages,
            status: true,
        },
        {
            type: "text",
            action: "text",
            label: "Vendor",
            required: true,
            name: "vendor",
            placeholder: "*Vendor",
            col: 6,
            valid: "Vendor tidak boleh kosong",
            value: item.vendor,
            status: true,
        },
        {
            type: "text",
            action: "text",
            label: "Channel",
            required: true,
            name: "channel",
            placeholder: "*Channel",
            col: 6,
            valid: "Channel tidak boleh kosong",
            value: item.channel,
            status: true,
        },
    ]);
    const handlerSubmitData = () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let id = item.id;
            let data = {
                name: form[0].value,
                image: form[1].value,
                type: form[2].value,
                publish: form[3].value,
                fee: form[4].value,
                percentages: form[5].value,
                vendor: form[6].value,
                channel: form[7].value,
            };
            service.updatePaymentMethod({ dispatch, data, id });
        }
    };
    React.useEffect(() => {
        if (state?.payment?.newPaymentMethod?.isSuccess) {
            setStatus(state.payment.newPaymentMethod.isSuccess);
            setShow(false);
            setLoading(false);
            setMessageText(state.payment.newPaymentMethod.message.text);
            setRefresh(true);
            setData(false);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
                service.newPMClear({ dispatch });
            }, 2500);
        }
        if (state?.payment?.newPaymentMethod?.isError) {
            setStatus(state.payment.newPaymentMethod.isSuccess);
            setShowMessage(true);
            setMessageText(state.payment.newPaymentMethod.data.message);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            setShow(false);
            setLoading(false);
            setRefresh(true);
            setMessage(state?.payment?.newPaymentMethod.message.text);
        }
    }, [state]);
    return (
        <Modal size="lg" isOpen={isShow} toggle={toggle}>
            {isMessage && <Alert color="danger">{isMessage}</Alert>}
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Edit Payment Method</h5>
                <Style>
                    <Form
                        isShow={isShow}
                        setForm={setForm}
                        validateForm={validateForm}
                        validationClick={validationClick}
                        form={form}
                    />
                </Style>
                <div className="text-right">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => handlerSubmitData()}
                    >
                        Simpan
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default ModalEditPayment;
