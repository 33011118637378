import React from "react";
import styled from "styled-components";
import { FormGroup } from "reactstrap";

const Style = styled.div`
  .label-publish {
    margin: 5px;
  }
`;

const FormCheckbox = ({ item = false, onGetValue = () => {}, id = false }) => {
  const [isChecked, setChecked] = React.useState(item.value || false);
  const handleChange = (e) => {
    setChecked(!isChecked);
    onGetValue({
      id: id,
      name: e.target.name,
      value: e.target.checked,
      status: true,
    });
  };
  return (
    <FormGroup>
      <Style>
        <div>
          {item.label && (
            <label>
              <input
                checked={isChecked}
                type="checkbox"
                onChange={handleChange}
                className="label-publish"
              />
              {item.label}
              {item.required && <span className="text-danger">*</span>}
            </label>
          )}
        </div>
      </Style>
    </FormGroup>
  );
};

export default FormCheckbox;
