import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
import Button from "../../Particle/Button";
import Form from "../../Molekul/FormValidation";
import { color } from "../../../Utils/Variable";

const EditProfile = ({ isShow = false, toggle = () => {} }) => {
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);

    const [formInformation, setFormInformation] = React.useState([
        {
            type: "number",
            action: "text",
            name: "rekening",
            placeholder: "Nomor Rekening",
            col: 6,
            valid: "Nomor Rekening Tidak boleh Kosong",
            value: "",
            status: false,
        },
        {
            type: "text",
            action: "text",
            name: "penerima",
            placeholder: "Nama Penerima",
            col: 6,
            valid: "Nama Penerima Tidak boleh Kosong",
            value: "",
            status: false,
        },
        {
            placeholder: "Pilih Bank",
            type: "select",
            action: "select",
            name: "bank",
            col: 6,
            option: [
                {
                    label: "BCA",
                    value: "1",
                },
                {
                    label: "CIMB Niaga",
                    value: "2",
                },
            ],
            valid: "Harap pilih Bank",
            value: "",
            status: false,
        },
        {
            placeholder: "Pilih Cabang Bank",
            type: "select",
            action: "select",
            name: "cabang",
            col: 6,
            option: [
                {
                    label: "Kebun Jeruk",
                    value: "1",
                },
                {
                    label: "Tomang",
                    value: "2",
                },
            ],
            valid: "Harap pilih Bank",
            value: "",
            status: false,
        },
    ]);
    const [form, setForm] = React.useState([
        {
            type: "number",
            action: "text",
            name: "ktp",
            placeholder: "Nomor Ktp",
            col: 6,
            valid: "Nomor Ktp Tidak boleh Kosong",
            value: "",
            status: false,
        },
        {
            type: "date",
            action: "datepicker",
            name: "birthday",
            placeholder: "Tanggal Lahir",
            col: 6,
            valid: "Tanggal Lahir Tidak boleh Kosong",
            value: moment(new Date()).format("YYYY-MM-DD"),
            status: false,
        },
        {
            placeholder: "Jenis Kelamin",
            type: "select",
            action: "select",
            name: "gender",
            col: 6,
            option: [
                {
                    label: "Laki-Laki",
                    value: "1",
                },
                {
                    label: "Perempuan",
                    value: "2",
                },
            ],
            valid: "Jenis Kelamin Tidak Boleh Kosong",
            value: "",
            status: false,
        },
        {
            placeholder: "Jenis Pekerjaan",
            type: "select",
            action: "select",
            name: "jobdesc",
            col: 6,
            option: [
                {
                    label: "IT Support",
                    value: "1",
                },
                {
                    label: "Developer",
                    value: "2",
                },
            ],
            valid: "Jenis Pekerjaan Tidak Boleh Kosong",
            value: "",
            status: false,
        },
        {
            placeholder: "Kecamatan",
            type: "select",
            action: "select",
            name: "sub-district",
            col: 3,
            option: [
                {
                    label: "Rawalumbu",
                    value: "Rawalumbu",
                },
                {
                    label: "kebon jeruk",
                    value: "kebomJeruk",
                },
            ],
            valid: "Kecamatan Tidak Boleh Kosong",
            value: "",
            status: false,
        },
        {
            placeholder: "Kabupaten",
            type: "select",
            action: "select",
            name: "district",
            col: 3,
            option: [
                {
                    label: "Rawalumbu",
                    value: "Rawalumbu",
                },
                {
                    label: "kebon jeruk",
                    value: "kebomJeruk",
                },
            ],
            valid: "Kabupaten Tidak Boleh Kosong",
            value: "",
            status: false,
        },
        {
            placeholder: "Provinsi",
            type: "select",
            action: "select",
            name: "province",
            col: 3,
            option: [
                {
                    label: "Jawa Barat",
                    value: "1",
                },
                {
                    label: "Jawa Timur",
                    value: "2",
                },
            ],
            valid: "Provinsi Tidak Boleh Kosong",
            value: "",
            status: false,
        },
        {
            placeholder: "Kode Pos",
            type: "number",
            action: "text",
            name: "province",
            col: 3,
            valid: "Kode Pos Tidak Boleh Kosong",
            value: "",
            status: false,
        },

        {
            type: "textarea",
            action: "textarea",
            name: "address",
            placeholder: "Alamat Lengkap",
            col: 12,
            valid: "Alamat Tidak boleh Kosong",
            value: "",
            status: false,
        },
        {
            placeholder: "Hari Operasional",
            type: "select",
            isMulti: true,
            action: "select",
            name: "operational",
            col: 6,
            option: [
                {
                    label: "Senin",
                    value: "1",
                },
                {
                    label: "Selasa",
                    value: "2",
                },
            ],
            valid: "Hari Operasional Tidak Boleh Kosong",
            value: "",
            status: false,
        },
        {
            type: "number",
            action: "time",
            name: "open",
            placeholder: "Jam Buka",
            col: 3,
            valid: "Nomor telepon tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "number",
            action: "time",
            name: "closed",
            placeholder: "Jam Tutup",
            col: 3,
            valid: "Nomor telepon tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "image",
            action: "uploadImage",
            name: "image_1",
            placeholder: "Klik Untuk Masukan Gambar",
            col: 3,
            valid: "Harap masukan gambar",
            value: "",
            status: false,
            normalData: false,
            heightStyle: "150px",
        },
        {
            type: "image",
            action: "uploadImage",
            name: "image_2",
            placeholder: "Klik Untuk Masukan Gambar",
            col: 3,
            valid: "Harap masukan gambar",
            value: "",
            status: false,
            normalData: false,
            heightStyle: "150px",
        },
    ]);
    const handlerSubmitData = (e) => {
        let isError = false;
        let isErrorInformation = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        for (let key in formInformation) {
            if (!formInformation[key].status) {
                isErrorInformation = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError && !isErrorInformation) {
            alert("sukses");
        }
    };
    return (
        <Modal size="lg" isOpen={isShow} toggle={toggle}>
            <ModalHeader toggle={toggle}>Edit Profile</ModalHeader>
            <ModalBody>
                <h6 className="font-weight-bold mb-3">Data diri</h6>
                <Form
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
                <h6 className="font-weight-bold mb-3">Informasi Rekening</h6>
                <Form
                    setForm={setFormInformation}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={formInformation}
                />
                <div>
                    <Button
                        onClick={handlerSubmitData}
                        type="button"
                        color={`${color.secondary}`}
                    >
                        Simpan
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default EditProfile;
