import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import CardProduct from "../Card/CardProduct";
const ListProduct = ({
    isShow = false,
    data = [],
    toggle = () => {},
    handleCreateProduct = () => {},
}) => {
    return (
        <Modal size="xl" isOpen={isShow} toggle={toggle}>
            <ModalHeader toggle={toggle}>Daftar Produk</ModalHeader>
            <ModalBody>
                <Row>
                    {data.length > 0
                        ? data.map((item, key) => {
                              return (
                                  <Col xl="3" md="3" sm="4" xs="6" key={key}>
                                      <CardProduct data={item} />
                                  </Col>
                              );
                          })
                        : false}
                </Row>
            </ModalBody>
        </Modal>
    );
};
export default ListProduct;
