import { servicesAction } from "../../../Reducers";

const service = {
    resellerList: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/list/reseller",
            method: "GET",
            token: true,
            reducer: "service",
            qs: true,
            params: params,
            group: "reseller",
            key: "resellerList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    referralCode: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/share_referral/new",
            method: "POST",
            token: true,
            reducer: "service",
            data: data,
            group: "reseller",
            key: "referralCode",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Kode Referral berhasil ditambahkan",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Telah terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Kode Referral tidak valid",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal service error",
                },
            },
        });
    },
    verifyUser: ({ dispatch, id, verify, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/profile/${id}/${verify}`,
            method: "PUT",
            params: params,
            qs: true,
            token: true,
            reducer: "service",
            group: "user",
            key: "verifyUser",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: verify === "rejected" ? "Ditolak" : "Terverifikasi",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Telah terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Kode Referral tidak valid",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal service error",
                },
            },
        });
    },
    referralUpdate: ({ dispatch, data, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/share_referral/update/${id}`,
            method: "PUT",
            token: true,
            reducer: "service",
            data: data,
            group: "reseller",
            key: "referralCode",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Kode Referral berhasil ditambahkan",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Telah terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Kode Referral tidak valid",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal service error",
                },
            },
        });
    },
    referralCodeClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "reseller",
            key: "referralCode",
        });
    },
    verifyUserClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "user",
            key: "verifyUser",
        });
    },
};
export default service;
