import React from "react";
import { Card, CardBody } from "reactstrap";
import TabMenu from "../../../../Components/Molekul/TabMenu";
import styled from "styled-components";
import { color } from "../../../../Utils/Variable";
import FixCommission from "./FixCommission";
import CustomCommission from "./CustomCommission";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const Style = styled(Card)`
    .card-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const CardList = () => {
    const [data, setData] = React.useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const { dispatch, state } = useStore();

    React.useEffect(() => {
        if (isFirstGet) {
            service.commission({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);
    const handleCommissionClear = () => {
        if (state?.commission?.commission?.isSuccess) {
            setData(state.commission.commission.data.content);
            service.commissionClear({ dispatch });
        }
    };
    const handleCommissionClearCallback = React.useCallback(
        handleCommissionClear
    );
    React.useEffect(() => {
        handleCommissionClearCallback();
    }, [handleCommissionClearCallback]);
    return (
        <Style>
            <CardBody>
                <div className="card-list">
                    <TabMenu
                        color={{
                            text: "#aaa",
                            background: "#fff",
                            activeText: color.primary,
                            activeColor: color.primary,
                            activeBackground: "#fff",
                        }}
                        data={[
                            {
                                title: "Komisi Tetap",
                                key: 1,
                                render: () => {
                                    return (
                                        data && (
                                            <FixCommission
                                                data={data.filter(
                                                    (item) => item.id === 1
                                                )}
                                            />
                                        )
                                    );
                                },
                            },
                            {
                                title: "Komisi Custom",
                                key: 2,
                                render: () => {
                                    // return <div></div>;
                                    return (
                                        data && (
                                            <CustomCommission
                                                data={data.filter(
                                                    (item) => item.id === 2
                                                )}
                                            />
                                        )
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </CardBody>
        </Style>
    );
};
export default CardList;
