import React, { useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Header from "./Header";
import styled from "styled-components";
import Tab from "./Tab";
import { color } from "../../../Utils/Variable";

import Button from "../../../Components/Particle/Button";
import CommonTable from "../../../Components/Molekul/CommonTable";
import service from "./index.service";
import { useStore } from "../../../Reducers";
import Message from "../../../Components/Modal/Message";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";

import ModalAddFAQ from "./FAQ/ModalAddFAQ";
import ModalAddQA from "./FAQ/ModalAddQA";
import ModalPreview from "./FAQ/ModalPreview";
import ModalEditFAQ from "./FAQ/ModalEditFAQ";
import ModalEditQA from "./FAQ/ModalEditQA";
import ModalDeleteFAQ from "./FAQ/ModalDeleteFAQ";
import ModalDeleteQA from "./FAQ/ModalDeleteQA";

const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
        }
        transition: all 0.25s ease;
        width: 100%;
    }
    .card-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        transition: all 0.25s ease;
        background: ${(props) =>
            props.active === "true" ? "rgb(93 140 241 / 5%)" : "#fff"};
    }
    .fas {
        font-size: 18px;
    }
    .img-blog {
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
    .btn {
        &-dropdown {
            transition: all 0.25s ease;
            margin-right: 10px;
            &--user {
                color: ${(props) =>
                    props.collapse === "true" ? color.secondary : "#434348"};
            }
            &--mobile {
                color: #434348;
            }
            &:hover {
                color: ${color.secondary};
            }
        }
        &-detail {
            display: flex;
            width: 100%;
            border: none;
            background: transparent;
            padding: 10px;
            &:focus {
                outline: none;
            }
        }
        &-secondary {
            background: ${color.secondary};
            border: 1px solid ${color.secondary};
        }
    }
    .fa-user,
    .fa-store,
    h6 {
        margin: 0;
        color: #434348;
    }
    .btn-space {
        padding: 5px;
    }
    button {
        margin: 5px;
    }
`;

const FAQ = () => {
    const { dispatch, state } = useStore();
    const [isRefresh, setRefresh] = React.useState(true);
    const [isShow, setShow] = React.useState(false);
    const [isShowQA, setShowQA] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [isDisplay, setDisplay] = React.useState(false);
    const [isDisplayQA, setDisplayQA] = React.useState(false);
    const [isDisplayFAQ, setDisplayFAQ] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [isEditFAQ, setEditFAQ] = React.useState(false);
    const [isView, setView] = React.useState(false);

    const [dataFAQ, setDataFAQ] = React.useState(false);
    const [isID, setID] = useState(false);

    const [messageText, setMessageText] = React.useState("");
    const [isStatus, setStatus] = React.useState(true);
    const [isShowMessage, setShowMessage] = React.useState(false);

    const handleShowModal = () => {
        setShow(!isShow);
    };
    const handleShowModalQA = () => {
        setShowQA(!isShowQA);
    };
    const handlerViewDetail = (item) => {
        setView(!isView);
        setDataFAQ(item);
    };
    const handleEditPreview = (item, ques) => {
        setView(false);
        setEdit(true);
        setID(ques);
        setDataFAQ(item);
    };
    const handleEditFAQ = (item) => {
        setView(false);
        setEditFAQ(true);
        setDataFAQ(item);
    };
    const handleRemove = (item) => {
        setView(false);
        setDisplayFAQ(true);
        setDataFAQ(item);
    };
    const handleRemoveQA = (item, ques) => {
        setView(false);
        setDisplayQA(true);
        setDataFAQ(item);
        setID(ques);
    };
    React.useEffect(() => {
        if (state?.faq?.addFAQ?.isSuccess) {
            setShow(false);
            setLoading(false);
            setRefresh(true);
            setStatus(state.faq.addFAQ.isSuccess);
            setMessageText(state.faq.addFAQ.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.clearFAQ({ dispatch });
        }
        if (state?.faq?.addFAQ?.isError) {
            setShow(false);
            setLoading(false);
            setRefresh(true);
            setStatus(state.faq.addFAQ.isSuccess);
            setMessageText(state.faq.addFAQ.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.clearFAQ({ dispatch });
        }
        if (state?.qa?.addQA?.isSuccess) {
            setShowQA(false);
            setLoading(false);
            setRefresh(true);
            setStatus(state.qa.addQA.isSuccess);
            setMessageText(state.qa.addQA.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.clearQA({ dispatch });
        }
        if (state?.qa?.addQA?.isError) {
            setShowQA(false);
            setLoading(false);
            setRefresh(true);
            setStatus(state.qa.addQA.isSuccess);
            setMessageText(state.qa.addQA.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.clearQA({ dispatch });
        }
        if (state?.faq?.update?.isSuccess) {
            setRefresh(true);
            setLoading(false);
            setEditFAQ(false);
            setDataFAQ(false);
            setStatus(state.faq.update.isSuccess);
            setMessageText(state.faq.update.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.editClearFAQ({ dispatch });
        }
        if (state?.faq?.update?.isError) {
            setRefresh(true);
            setLoading(false);
            setEditFAQ(false);
            setStatus(state.faq.update.isSuccess);
            setMessageText(state.faq.update.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.editClearFAQ({ dispatch });
        }
        if (state?.qa?.update?.isSuccess) {
            setRefresh(true);
            setLoading(false);
            setEdit(false);
            setDataFAQ(false);
            setID(false);
            setStatus(state.qa.update.isSuccess);
            setMessageText(state.qa.update.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.editClearQA({ dispatch });
        }
        if (state?.qa?.update?.isError) {
            setRefresh(true);
            setLoading(false);
            setEdit(false);
            setStatus(state.qa.update.isSuccess);
            setMessageText(state.qa.update.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.editClearQA({ dispatch });
        }
        if (state?.faq?.delete?.isSuccess) {
            setRefresh(true);
            setLoading(false);
            setDisplayFAQ(false);
            setDataFAQ(false);
            setStatus(state.faq.delete.isSuccess);
            setMessageText(state.faq.delete.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deleteClearFAQ({ dispatch });
        }
        if (state?.faq?.delete?.isError) {
            setRefresh(true);
            setLoading(false);
            setDisplayFAQ(false);
            setStatus(state.faq.delete.isSuccess);
            setMessageText(state.faq.delete.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deleteClearFAQ({ dispatch });
        }
        if (state?.qa?.delete?.isSuccess) {
            setRefresh(true);
            setLoading(false);
            setDisplayQA(false);
            setDataFAQ(false);
            setID(false);
            setStatus(state.qa.delete.isSuccess);
            setMessageText(state.qa.delete.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deleteClearQA({ dispatch });
        }
        if (state?.qa?.delete?.isError) {
            setRefresh(true);
            setLoading(false);
            setDisplayQA(false);
            setStatus(state.qa.delete.isSuccess);
            setMessageText(state.qa.delete.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deleteClearQA({ dispatch });
        }
    }, [
        state,
        setLoading,
        setRefresh,
        setDisplayQA,
        setDataFAQ,
        setID,
        dispatch,
    ]);
    return (
        <Style>
            <GrowingLoading active={isLoading} />
            <Header titlePage={"FAQ"} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <Card>
                            <CardBody>
                                <Tab />
                                <Row>
                                    <Col xl="12">
                                        <div className="text-right mb-4">
                                            <Button
                                                color="#5d8cf1"
                                                className="mr-2"
                                                onClick={handleShowModal}
                                            >
                                                Tambah Kategori FAQ
                                            </Button>
                                            <Button
                                                color="#5d8cf1"
                                                onClick={handleShowModalQA}
                                            >
                                                Buat Pertanyaan
                                            </Button>
                                        </div>
                                        <div id="table_FAQ">
                                            <CommonTable
                                                searchBy={["title"]}
                                                isRefresh={isRefresh}
                                                configuration={{
                                                    searchInput: true,
                                                    searchValue: ["title"],
                                                    header: false,
                                                    nav: {
                                                        hasSearch: true,
                                                        filter: [
                                                            {
                                                                icon: "fas fa-sort-amount-down",
                                                                type: "dropdown",
                                                                value: [
                                                                    {
                                                                        value: "title",
                                                                        label: "Title",
                                                                        key: "title",
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    },
                                                    // service: {
                                                    //     api: service.getBlog,
                                                    //     group: "blog",
                                                    //     key: "getBlog",
                                                    // },
                                                    service: {
                                                        api: service.categoryFaq,
                                                        group: "faq",
                                                        key: "faqList",
                                                        filters: "",
                                                    },
                                                    optionTable: [
                                                        {
                                                            headName: "No.",
                                                            type: "number",
                                                        },
                                                        {
                                                            headName: "User",
                                                            type: "text",
                                                            fieldName:
                                                                "category",
                                                            iconClass:
                                                                "fas fa-sort-alpha-down",
                                                            iconClass2:
                                                                "fas fa-sort-alpha-up-alt",
                                                        },
                                                        {
                                                            headName:
                                                                "Kategori",
                                                            type: "text",
                                                            fieldName: "title",
                                                            iconClass:
                                                                "fas fa-sort-alpha-down",
                                                            iconClass2:
                                                                "fas fa-sort-alpha-up-alt",
                                                        },
                                                        {
                                                            headName: "Aksi",
                                                            type: "actions",
                                                            options: [
                                                                {
                                                                    actionType:
                                                                        "isViewModal",
                                                                    function: (
                                                                        item
                                                                    ) =>
                                                                        handlerViewDetail(
                                                                            item
                                                                        ),
                                                                    renderItem:
                                                                        () => {},
                                                                    field: "id",
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                }}
                                            ></CommonTable>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
            <ModalAddFAQ
                setRefresh={setRefresh}
                setLoading={setLoading}
                setShow={setShow}
                toggle={handleShowModal}
                isShow={isShow}
            ></ModalAddFAQ>
            <ModalAddQA
                setRefresh={setRefresh}
                setLoading={setLoading}
                setShowQA={setShowQA}
                toggle={handleShowModalQA}
                isShowQA={isShowQA}
            ></ModalAddQA>
            <ModalPreview
                handlerViewDetail={handlerViewDetail}
                dataFAQ={dataFAQ}
                isView={isView}
                toggle={() => setView(!isView)}
                handleEditPreview={handleEditPreview}
                handleEditFAQ={handleEditFAQ}
                handleRemove={handleRemove}
                handleRemoveQA={handleRemoveQA}
            />
            {isID && (
                <ModalEditQA
                    setRefresh={setRefresh}
                    setEdit={setEdit}
                    setID={setID}
                    isID={isID}
                    dataFAQ={dataFAQ}
                    isEdit={isEdit}
                    toggle={() => setDisplay(!isDisplay)}
                    setLoading={setLoading}
                    setDataFAQ={setDataFAQ}
                />
            )}
            {dataFAQ && (
                <ModalEditFAQ
                    setRefresh={setRefresh}
                    setEditFAQ={setEditFAQ}
                    dataFAQ={dataFAQ}
                    isEditFAQ={isEditFAQ}
                    toggle={() => setDisplay(!isDisplay)}
                    setLoading={setLoading}
                    setDataFAQ={setDataFAQ}
                />
            )}
            <ModalDeleteFAQ
                setRefresh={setRefresh}
                setDisplayFAQ={setDisplayFAQ}
                dataFAQ={dataFAQ}
                isDisplayFAQ={isDisplayFAQ}
                toggle={() => setDisplayFAQ(!isDisplayFAQ)}
                setDataFAQ={setDataFAQ}
                setLoading={setLoading}
            />
            <ModalDeleteQA
                setRefresh={setRefresh}
                setDisplayQA={setDisplayQA}
                setID={setID}
                isID={isID}
                dataFAQ={dataFAQ}
                isDisplayQA={isDisplayQA}
                toggle={() => setDisplayQA(!isDisplayQA)}
                setDataFAQ={setDataFAQ}
                setLoading={setLoading}
            />
            <Message
                isShow={isShowMessage}
                status={isStatus}
                message={messageText}
            />
        </Style>
    );
};
export default FAQ;
