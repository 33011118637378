import { servicesAction } from "../../../Reducers";

const service = {
    //Category Fetch
    voucherList: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/voucher`,
            method: "GET",
            token: true,
            qs: true,
            params: params,
            reducer: "service",
            group: "voucher",
            key: "voucherList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    voucherPost: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/voucher`,
            method: "POST",
            token: true,
            data: data,
            reducer: "service",
            group: "voucher",
            key: "voucherPost",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Voucher berhasil di tambahkan",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    voucherUpdate: ({ dispatch, data, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/voucher/update/${id}`,
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "voucher",
            key: "voucherUpdate",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Voucher berhasil di Update",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    voucherDelete: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/voucher/delete/${id}`,
            method: "DELETE",
            token: true,
            reducer: "service",
            group: "voucher",
            key: "voucherDelete",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Voucher berhasil di hapus",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    voucherListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "voucher",
            key: "voucherList",
        });
    },
    voucherPostClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "voucher",
            key: "voucherPost",
        });
    },
    voucherUpdateClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "voucher",
            key: "voucherUpdate",
        });
    },
    voucherDeleteClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "voucher",
            key: "voucherDelete",
        });
    },
};

export default service;
