/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const ModalEditBlog = ({
    isEdit = false,
    dataBlog = false,
    setDataBlog = () => {},
    setLoading = () => {},
    setRefresh = () => {},
    setEdit = () => {},
}) => {
    const { dispatch } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [form, setForm] = useState([
        {
            type: "image",
            action: "uploadImage",
            name: "article_image.img_url",
            placeholder: "Klik Untuk Masukan Gambar",
            col: 12,
            valid: "Harap masukan gambar",
            value: dataBlog.article_image[0].img_url,
            status: true,
            normalData: false,
            heightStyle: "200px",
        },
        {
            type: "text",
            action: "text",
            name: "title",
            required: true,
            label: "Judul",
            placeholder: "Judul",
            col: 6,
            valid: "Title tidak boleh kosong",
            value: dataBlog.title,
            status: true,
        },
        {
            name: "category",
            type: "select",
            action: "select",
            required: true,
            label: "Kategori",
            col: 6,
            service: {
                api: service.category,
                group: "category",
                key: "category",
            },
            isMulti: false,
            valid: "Pilih kategori",
            value: dataBlog.category.id,
            status: true,
        },

        {
            type: "html",
            action: "html",
            name: "content",
            required: true,
            placeholder: "Deskripsi",
            label: "Deskripsi",
            col: 12,
            valid: "Deskripsi tidak boleh kosong",
            value: dataBlog.content,
            rows: 3,
            status: true,
        },
    ]);
    const updateValueBlog = async () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let id = dataBlog.id;
            let data = {
                category: { id: form[2].value },
                author: "Anon",
                title: form[1].value,
                content: form[3].value,
                article_image: [
                    {
                        id: dataBlog.article_image[0].id,
                        img_url: form[0].value,
                    },
                ],
            };
            service.updateBlog({ dispatch, id, data });
        }
        setRefresh(false);
    };
    const closePopUp = () => {
        setEdit(!isEdit);
        setDataBlog(false);
    };
    return (
        <Modal size="lg" isOpen={isEdit} toggle={closePopUp}>
            <ModalHeader className="border-0 pb-0" toggle={closePopUp} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Ubah Blog</h5>

                <Form
                    isShow={isEdit}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />

                <div className="text-right">
                    <Button
                        color="success"
                        type="button"
                        size="sm"
                        type="button"
                        onClick={updateValueBlog}
                    >
                        <i className="fas fa-edit mr-2"></i>Ubah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default ModalEditBlog;
