import { servicesAction } from "../../../Reducers";

const service = {
    token: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/oauth/token",
            method: "POST",
            data: data,
            formData: true,

            type: "application/x-www-form-urlencoded",
            reducer: "service",
            group: "user",
            key: "token",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    tokenClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "token",
            type: "CLEAR",
        });
    },
};
export default service;
