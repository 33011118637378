import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Button from "../../../../Components/Particle/Button";
import { formatMoney } from "../../../../Utils/PriceConverter";

const Style = styled.div`
    .order {
        margin-bottom: 20px;
        .title {
            font-weight: bold;
            position: relative;
            padding: 0 0 10px;
            margin: 0 0 10px;
            font-size: 14px;
            color: #000000;
            &:before {
                content: "";
                height: 2px;
                left: 0;
                margin: auto;
                position: absolute;
                bottom: 0;
                width: 100px;
                background: #000000;
            }
        }
    }
    .data-order {
        margin-bottom: 8px;
        display: flex;
        font-size: 14px;
        &__label {
            width: 175px;
        }
        &__dot {
            margin-right: 5px;
        }
        &__value {
            color: #000000;
            font-weight: 600;
        }
    }
`;
const ModalDetail = ({
    dataReseller = false,
    isShow = false,
    toggle = () => {},
    data,
    setData = () => {},
    commissionFixed = [],
    commissionCustom = [],
}) => {
    const [dateTransaction, setDateTransaction] = React.useState("");
    const returnData = () => {
        setData(false);
        toggle();
    };
    React.useEffect(() => {
        if (data) {
            let getDateTransaction = data.transaction_code.split("/");
            const date = new Date(Number(getDateTransaction[1]));
            setDateTransaction(
                `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
                    2,
                    "0"
                )}-${String(date.getDate()).padStart(2, "0")}`
            );
        }
    }, [data]);
    return (
        <Modal size="lg" toggle={returnData} isOpen={isShow}>
            {data && (
                <ModalBody>
                    <Style id="transactionDetailSupplier">
                        <div className="order">
                            <h6 className="title">Informasi Transaksi</h6>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nama Pemesan
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.customer_detail.full_name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nama Produk
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.product.name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Tanggal Transaksi
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {dateTransaction}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nomor Transaksi
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.transaction_code}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Jasa Pengiriman
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.payment_channel.name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Harga Produk
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {formatMoney(data.product.price)}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Total Item
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.total_amount} item
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Biaya Admin
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {formatMoney(data.admin_fee)}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Biaya Pengiriman
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {formatMoney(data.shipping_fee)}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Biaya Asuransi
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {data.insurance_fee === 0
                                        ? "-"
                                        : formatMoney(data.insurance_fee)}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Total Transaksi
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {formatMoney(data.total_cost)}
                                </div>
                            </div>
                        </div>

                        <div className="order">
                            <h6 className="title">Reseller</h6>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nama Reseller
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {dataReseller[0].full_name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">Bank</div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {dataReseller[0].bank.bank_name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nomor Rekening
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {dataReseller[0].bank.account_number}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Nama Rekening
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {dataReseller[0].bank.account_name}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Pembuatan Rekening
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {dataReseller[0].bank.bank_branch}
                                </div>
                            </div>
                            <div className="data-order">
                                <div className="data-order__label">
                                    Total komisi
                                </div>
                                <div className="data-order__dot">:</div>
                                <div className="data-order__value">
                                    {formatMoney(
                                        data.product.is_special_cut
                                            ? (data.product
                                                  .nominal_special_cut /
                                                  100) *
                                                  commissionFixed[0]
                                                      .reseller_commission
                                            : (Number(
                                                  data.product.price *
                                                      data.total_amount
                                              ) /
                                                  100) *
                                                  commissionCustom[0]
                                                      .reseller_commission
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <Button className="mr-3" onClick={returnData}>
                                Tutup
                            </Button>
                        </div>
                    </Style>
                </ModalBody>
            )}
        </Modal>
    );
};
export default ModalDetail;
