import { servicesAction } from "../../../Reducers";

const service = {
    category: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/category/`,
            method: "GET",
            token: true,
            qs: true,
            params: params,
            reducer: "service",
            group: "category",
            key: "category",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    categoryArt: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/category/`,
            method: "GET",
            token: true,
            qs: true,
            params: params,
            reducer: "service",
            group: "category",
            key: "categoryart",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },

    newCategory: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/category/new",
            method: "POST",
            token: true,
            data: data,
            reducer: "service",
            group: "category",
            key: "newCategory",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Kategori berhasil di tambahkan",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Illegal",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Category already accessed",
                },
            },
        });
    },
    updateCategory: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/category/update/${id}`,
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "category",
            key: "update",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Kategori berhasil di update",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Category already accessed",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Category already accessed",
                },
            },
        });
    },
    deleteCategory: ({ dispatch, id, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/category/delete/${id}`,
            method: "DELETE",
            token: true,
            data: data,
            reducer: "service",
            group: "category",
            key: "delete",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Kategori berhasil dihapus",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    deleteCategoryClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "category",
            key: "delete",
        });
    },
    newCategoryClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "category",
            key: "newCategory",
        });
    },
    updateCategoryClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "category",
            key: "update",
        });
    },
};
export default service;
