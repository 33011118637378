import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Form from "../../Molekul/FormValidation";

import moment from "moment";
import Button from "../../Particle/Button";

const Filter = ({ toggle = () => {}, isShow = false }) => {
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [form, setForm] = React.useState([
        {
            label: "Nama Reseller",
            type: "text",
            action: "text",
            name: "nameReseller",
            placeholder: "Nama Supplier",
            col: 6,
            valid: "Nama Reseller tidak boleh kosong",
            value: "",
            status: true,
        },
        {
            labelStartDate: "Dari Tanggal",
            labelEndDate: "Sampai Tanggal",
            type: "text",
            action: "datepicker",
            name: "dateChange",
            range: true,
            col: 12,
            value: {
                start: moment(new Date()).format("YYYY-MM-DD"),
                end: moment(new Date()).format("YYYY-MM-DD"),
            },
            status: false,
        },
        {
            labelMin: "Min Harga",
            labelMax: "Max Harga",
            type: "range",
            action: "inputRange",
            name: "input-range",
            prefix: "Rp. ",
            formatLabel: false,
            col: 12,
            isMulti: true,
            valid: "",
            value: 100,
            status: true,
            maxValue: false,
            minValue: 0,
            haveInput: true,
            haveRange: false,
        },
        {
            label: "Status",
            type: "select",
            action: "select",
            name: "category",
            col: 6,
            option: [
                {
                    label: "Sukses",
                    value: "success",
                },
                {
                    label: "Retur",
                    value: "return",
                },
                {
                    label: "Batal",
                    value: "cancel",
                },
            ],
            valid: "Pilih kategori",
            value: "",
            status: true,
        },
    ]);
    const handlerSubmitData = (e) => {
        let isError = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            alert("check!");
        }
    };
    return (
        <Modal toggle={toggle} isOpen={isShow}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Filter</h5>
                <Form
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
                <Button
                    type="button"
                    color="#5d8cf1"
                    onClick={handlerSubmitData}
                >
                    Filter
                </Button>
            </ModalBody>
        </Modal>
    );
};
export default Filter;
