import React from "react";
import {
    Card,
    CardBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import styled from "styled-components";
import { color } from "../../../../Utils/Variable";
import Button from "../../../../Components/Particle/Button";
import CardSupplier from "./CardSupplier";
import Tab from "../Tab";

const Style = styled(Card)`
    .card-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        transition: all 0.25s ease;
        background: ${(props) =>
            props.active === "true" ? "rgb(93 140 241 / 5%)" : "#fff"};
    }
    .fas {
        font-size: 18px;
    }
    .img-supplier {
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
    .btn {
        &-dropdown {
            transition: all 0.25s ease;
            margin-right: 10px;
            &--user {
                color: ${(props) =>
                    props.collapse === "true" ? color.secondary : "#434348"};
            }
            &--mobile {
                color: #434348;
            }
            &:hover {
                color: ${color.secondary};
            }
        }
        &-detail {
            display: flex;
            width: 100%;
            border: none;
            background: transparent;
            padding: 10px;
            &:focus {
                outline: none;
            }
        }
        &-secondary {
            background: ${color.secondary};
            border: 1px solid ${color.secondary};
        }
    }
    .fa-user,
    .fa-store,
    h6 {
        margin: 0;
        color: #434348;
    }
`;
const CardList = ({
    isAside = false,
    isShowAddSupplier = true,
    filterReseller = false,
    handleShowFilterTransaction = () => {},
    handleShowModal = () => {},
}) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

    const eventOnClick = (e) => {
        e.preventDefault();
    };
    return (
        <Style active={isAside.toString()}>
            <CardBody>
                <Tab />
                {isShowAddSupplier && !filterReseller && (
                    <div className="d-flex" style={{ paddingBottom: 20 }}>
                        {/* <Button
                            onClick={handleShowModal}
                            type="button"
                            color="#5d8cf1"
                            className="pl-5 pr-5 mr-3"
                        >
                            Tambah
                        </Button> */}
                    </div>
                )}
                {!isShowAddSupplier && filterReseller && (
                    <div className="d-flex" style={{ paddingBottom: 20 }}>
                        <Button
                            onClick={handleShowFilterTransaction}
                            type="button"
                            color="#5d8cf1"
                            className="pl-5 pr-5 mr-3"
                        >
                            Filter
                        </Button>
                        <Dropdown
                            className="mr-3"
                            isOpen={dropdownOpen}
                            toggle={toggleDropdown}
                        >
                            <DropdownToggle>
                                <i className="fas fa-sort-amount-down text-white"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={(e) => eventOnClick(e)}>
                                    Kategory
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Button color="#5d8cf1">
                            <i className="fas fa-download text-white"></i>
                        </Button>
                    </div>
                )}
                <CardSupplier />
            </CardBody>
        </Style>
    );
};
export default CardList;
