/* eslint-disable*/
import React from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import Button from "../../../../../../Components/Particle/Button";
import service from "../../../index.service";
import { useStore } from "../../../../../../Reducers";

const Delete = ({
    isDisplay = false,
    toggle = () => {},
    dataArticle = false,
    setLoading = () => {},
    setRefresh = () => {},
}) => {
    const { dispatch, state } = useStore();

    const handleDeleteItem = () => {
        setRefresh(false);
        setLoading(true);
        let id = dataArticle.id;
        let data = {
            id: dataArticle.id,
            title: dataArticle.title,
            content: dataArticle.content,
            img_url: dataArticle.img_url,
        };
        service.deleteArticle({ dispatch, id, data });
        setLoading(true);
    };
    return (
        <Modal isOpen={isDisplay} toggle={toggle}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h6 className="my-4 text-center">
                    Yakin ingin menghapus artikel ini ?
                </h6>
                <Row>
                    <Col xl="6" md="6" lg="6" sm="6" xs="6">
                        <Button
                            type="button"
                            color="primary"
                            onClick={toggle}
                            block
                        >
                            Tidak
                        </Button>
                    </Col>
                    <Col xl="6" md="6" lg="6" sm="6" xs="6">
                        <Button
                            type="button"
                            color="outline-primary"
                            onClick={handleDeleteItem}
                            block
                        >
                            Ya
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default Delete;
