import React from "react";
import TabLink from "../../../Components/Molekul/TabLink";

const Tab = () => {
    const tabLink = [
        {
            name: "Terverifikasi",
            link: "/admin/reseller/verification",
        },
        {
            name: "Belum Terverifikasi",
            link: "/admin/reseller/not-verification",
        },
    ];
    return <TabLink data={tabLink} />;
};
export default Tab;
