import React from "react";
import TabLink from "../../../Components/Molekul/TabLink";

const Tab = () => {
  const tabLink = [
    {
      name: "Supplier",
      link: "/admin/finance/supplier",
    },
    {
      name: "Reseller",
      link: "/admin/finance/reseller",
    },
    {
      name: "Customer",
      link: "/admin/finance/customer",
    },
    {
      name: "Semua Transaksi",
      link: "/admin/finance/all-transaction",
    },
  ];
  return <TabLink data={tabLink} />;
};
export default Tab;
