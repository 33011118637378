import React from "react";
import Button from "../../../../Components/Particle/Button";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import { FormGroup, Modal, ModalBody, Row, Col } from "reactstrap";
import styled from "styled-components";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import MessageState from "../../../../Components/Modal/Message";

const Style = styled.div`
    .card-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        transition: all 0.25s ease;
        padding: 20px;
    }
    .notice {
        position: fixed;
        z-index: 3;
        top: 50px;
        left: 0;
        right: 0;
    }
`;

const Article = ({ isRefresh = true, setRefresh = () => {} }) => {
    const { dispatch, state } = useStore();
    const [isStatus, setStatus] = React.useState(true);
    const [isShowMessage, setShowMessage] = React.useState(false);
    const [messageText, setMessageText] = React.useState("");
    const [updateItemCategory, setUpdateItemCategory] = React.useState(false);
    const [updateCategory, setUpdateCategory] = React.useState("");
    // eslint-disable-next-line
    const [isLoading, setLoading] = React.useState(false);
    // eslint-disable-next-line
    const [isMessage, setMessage] = React.useState(false);
    const [isUpdate, setUpdate] = React.useState(false);
    // const [isRefresh, setRefresh] = React.useState(true);
    const [category, setCategory] = React.useState({});
    const [isModalDelete, setModalDelete] = React.useState(false);

    const handleUpdateCategory = (item) => {
        setUpdate(true);
        setUpdateItemCategory(item);
        setUpdateCategory(item.name);
    };
    const updateValueCategory = () => {
        setRefresh(false);
        setLoading(true);
        let id = updateItemCategory.id;
        let data = {
            name: updateCategory,
        };

        // if (updateCategory.length > 2) {
        service.updateCategory({ dispatch, id, data });
        // }
    };
    const handleDeleteModal = (item) => {
        setCategory(item);
        setModalDelete(true);
    };
    const handleDeleteItem = (item) => {
        setRefresh(false);
        setLoading(true);
        let id = category.id;
        let data = {
            name: category.name,
        };
        service.deleteCategory({ dispatch, id, data });
    };
    React.useEffect(() => {
        if (state?.category?.delete?.isSuccess) {
            setRefresh(true);
            setModalDelete(false);
            setLoading(false);
            setStatus(state.category.delete.isSuccess);
            setMessageText(state.category.delete.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deleteCategoryClear({ dispatch });
            // window.location.reload();
        }
        if (state?.category?.delete?.isError) {
            setRefresh(true);
            setModalDelete(false);
            setLoading(false);
            setStatus(state.category.delete.isSuccess);
            setMessageText(state.category.delete.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deleteCategoryClear({ dispatch });
            // window.location.reload();
        }
        if (state?.category?.update?.isSuccess) {
            setMessage(false);
            setRefresh(true);
            setLoading(false);
            setUpdate(false);
            setMessageText(state.category.update.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.updateCategoryClear({ dispatch });
            // window.location.reload();
        }
        if (state?.category?.update?.isError) {
            setRefresh(true);
            setMessage(false);
            setLoading(false);
            setUpdate(false);
            setMessageText(state.category.update.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.updateCategoryClear({ dispatch });
            // window.location.reload();
        }
    }, [state, setMessage, setLoading, setRefresh, setUpdate, dispatch]);
    return (
        <Style>
            <Modal isOpen={isModalDelete}>
                <ModalBody>
                    <h6 className="my-4 text-center">
                        Apakah Anda yakin ingin menghapus "
                        <b>{category.name}</b>" dari list kategori ?
                    </h6>
                    <Row>
                        <Col xl="6" md="6" lg="6" sm="6" xs="6">
                            <Button
                                type="button"
                                color="primary"
                                onClick={() => setModalDelete(false)}
                                block
                            >
                                Tidak
                            </Button>
                        </Col>
                        <Col xl="6" md="6" lg="6" sm="6" xs="6">
                            <Button
                                type="button"
                                color="outline-primary"
                                onClick={handleDeleteItem}
                                block
                            >
                                Ya
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <CommonTable
                isRefresh={isRefresh}
                configuration={{
                    searchInput: true,
                    searchValue: ["name"],
                    header: false,
                    nav: {
                        hasSearch: true,
                        filter: [
                            {
                                icon: "fas fa-sort-amount-down",
                                type: "dropdown",
                                value: [
                                    {
                                        value: "name",
                                        label: "Name",
                                        key: "name",
                                    },
                                ],
                            },
                        ],
                    },
                    service: {
                        api: service.categoryArt,
                        group: "category",
                        key: "categoryart",
                        filters: `["type","like","1"]`,
                        filterData: "",
                    },
                    optionTable: [
                        {
                            headName: "Kategori",
                            type: "text",
                            fieldName: "name",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                        },
                        {
                            headName: "Aksi",
                            type: "isCostume",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <Button
                                            color="transparent"
                                            size="sm"
                                            type="button"
                                            onClick={() =>
                                                handleDeleteModal(item)
                                            }
                                        >
                                            <i className="fas fa-trash-alt text-danger"></i>
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                handleUpdateCategory(item)
                                            }
                                            color="transparent"
                                            size="sm"
                                            type="button"
                                        >
                                            <i className="fas fa-edit text-success"></i>
                                        </Button>
                                    </React.Fragment>
                                );
                            },
                        },
                    ],
                }}
            ></CommonTable>
            <Modal isOpen={isUpdate} toggle={() => setUpdate(!isUpdate)}>
                <ModalBody>
                    <FormGroup>
                        <label>Update Kategori</label>
                        <input
                            defaultValue={updateCategory}
                            onChange={(e) => setUpdateCategory(e.target.value)}
                            className="form-control"
                            type="text"
                        />
                    </FormGroup>
                    <div className="d-flex">
                        <Button
                            color="outline-primary"
                            className="mr-3"
                            onClick={() => setUpdate(false)}
                        >
                            Batal
                        </Button>
                        <Button color="primary" onClick={updateValueCategory}>
                            Update
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
            <MessageState
                isShow={isShowMessage}
                status={isStatus}
                message={messageText}
            />
        </Style>
    );
};

export default Article;
