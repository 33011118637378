import Login from "../Pages/Auth/Login";
import Supplier from "../Pages/Admin/Supplier";
import Reseller from "../Pages/Admin/Reseller";
import Commission from "../Pages/Admin/Commission";
import CategoryProduct from "../Pages/Admin/Category";
import CategoryArticle from "../Pages/Admin/Category/Article";
import FAQ from "../Pages/Admin/Setting/FAQ";
import FinanceSupplier from "../Pages/Admin/Finance/Supplier";
import FinanceReseller from "../Pages/Admin/Finance/Reseller";
import Blog from "../Pages/Admin/Setting/Blog";
import Banner from "../Pages/Admin/Setting/Banner";
import About from "../Pages/Admin/Setting/AboutLakugan";
import PaymentMethod from "../Pages/Admin/Setting/PaymentMethod";
import Page404 from "../Pages/Admin/Error/404";
import TransactionListReseller from "../Pages/Admin/Finance/Reseller/TransactionList";
import TransactionSupplier from "../Pages/Admin/Transaksi/Supplier";
import TransactionReseller from "../Pages/Admin/Transaksi/Reseller";
import TransactionResellerList from "../Pages/Admin/Transaksi/Reseller/TransactionList";
import TransactionSupplierList from "../Pages/Admin/Transaksi/Supplier/TransactionList";
import AllTransaction from "../Pages/Admin/Finance/AllTransaction";
import Voucher from "../Pages/Admin/Voucher";
import CancelTransaction from "../Pages/Admin/Finance/Customer";
import NotVerification from "../Pages/Admin/Reseller/NotVerification";
import NotVerificationSupplier from "../Pages/Admin/Supplier/NotVerificationSupplier";
export default [
  {
    component: Login,
    path: "/",
    isHeader: true,
    layout: import("../Router/RouteGuestLayout"),
    isFooter: true,
    title: "Login",
    role: "",
  },
  {
    component: Supplier,
    path: "/supplier/verification",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    sidebar: {
      icon: "fas fa-box mr-2",
      name: "Supplier",
      activeName: "supplier",
    },
    title: "Supplier",
    role: "admin",
  },
  {
    component: NotVerificationSupplier,
    path: "/supplier/not-verification",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Supplier",
    role: "admin",
  },
  {
    component: Reseller,
    path: "/reseller/verification",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    sidebar: {
      icon: "fas fa-sync-alt mr-2",
      name: "Reseller",
      activeName: "reseller",
    },
    title: "Reseller",
    role: "admin",
  },
  {
    component: NotVerification,
    path: "/reseller/not-verification",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Reseller",
    role: "admin",
  },
  {
    component: Commission,
    path: "/commission",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    sidebar: {
      icon: "fas fa-hand-holding-usd mr-2",
      name: "Komisi",
      activeName: "commission",
    },
    title: "Commission",
    role: "admin",
  },

  {
    component: CategoryProduct,
    path: "/category/product",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    sidebar: {
      icon: "fas fa-list mr-2",
      name: "Kategori",
      activeName: "category",
    },
    title: "Category",
    role: "admin",
  },
  {
    component: CategoryArticle,
    path: "/category/article",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Category",
    role: "admin",
  },
  // {
  //     component: CategoryArticle,
  //     path: "/category/article",
  //     isHeader: true,
  //     layout: import("../Router/RouteAdminLayout"),
  //     isFooter: true,
  //     title: "Category",
  //     role: "admin",
  // },
  {
    component: FAQ,
    path: "/setting/faq",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "FAQ",
    role: "admin",
  },
  {
    component: Voucher,
    path: "/voucher",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    sidebar: {
      icon: "fas fa-ticket-alt mr-2",
      name: "Voucher",
      activeName: "voucher",
    },
    title: "Voucher",
    role: "admin",
  },
  {
    component: FinanceSupplier,
    path: "/finance/supplier",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    sidebar: {
      icon: "fas fa-user mr-2",
      name: "Finance",
      activeName: "finance",
    },
    title: "Supplier",
    role: "admin",
  },
  {
    component: FinanceReseller,
    path: "/finance/reseller",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Reseller",
    role: "admin",
  },
  {
    component: AllTransaction,
    path: "/finance/all-transaction",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Semua Transaksi",
    role: "admin",
  },
  {
    component: CancelTransaction,
    path: "/finance/customer",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Customer",
    role: "admin",
  },

  {
    component: TransactionListReseller,
    path: "/finance/transaction-reseller/:slug",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Reseller",
    role: "admin",
  },
  {
    component: TransactionSupplier,
    path: "/transaction/supplier",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    sidebar: {
      icon: "fas fa-money-bill-wave mr-2",
      name: "Transaksi",
      activeName: "transaction",
    },
    title: "Supplier",
    role: "admin",
  },
  {
    component: TransactionReseller,
    path: "/transaction/reseller",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Reseller",
    role: "admin",
  },
  {
    component: TransactionResellerList,
    path: "/transaction/transaction-reseller/:slug",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Reseller",
    role: "admin",
  },
  {
    component: TransactionSupplierList,
    path: "/transaction/transaction-supplier/:slug",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Supplier",
    role: "admin",
  },
  {
    component: Blog,
    path: "/setting/blog",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    sidebar: {
      icon: "fas fa-cogs mr-2",
      name: "Setting",
      activeName: "setting",
    },
    title: "Setting",
    role: "admin",
  },
  {
    component: Banner,
    path: "/setting/banner",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Banner",
    role: "admin",
  },
  {
    component: About,
    path: "/setting/tentang",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "About Lakugan",
    role: "admin",
  },
  {
    component: PaymentMethod,
    path: "/setting/payment-method",
    isHeader: true,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Payment Method",
    role: "admin",
  },
  {
    component: Page404,
    path: "404",
    isHeader: false,
    layout: import("../Router/RouteAdminLayout"),
    isFooter: true,
    title: "Error",
    role: "",
  },
];
