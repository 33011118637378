/* eslint-disable */
import React from "react";
import CardList from "./CardList";
import Header from "./Header";
// import ModalAddSupplier from "./ModalAddSupplier";
import ModalFilter from "./ModalFilter";
import styled from "styled-components";
import { aside } from "../../../Utils/Variable";
import Filter from "../../../Components/Admin/Modal/Filter";
import Detail from "../../../Components/Admin/Modal/Detail";

const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
        }
        transition: all 0.25s ease;
        width: ${(props) =>
            props.isAside ? `calc(100% - ${aside.width})` : "100%"};
    }
`;

const Supplier = () => {
    const [isShow, setShow] = React.useState(false);
    const [isFilter, setFilter] = React.useState(false);
    const [isAside, setAside] = React.useState(false);
    const [isFilterTransaction, setFilterTransaction] = React.useState(false);
    const [titlePage, setTitlePage] = React.useState("List Supplier");
    const [isShowAddSupplier, setShowAddSupplier] = React.useState(true);
    const [filterReseller, setFilterReseller] = React.useState(false);
    const handleShowModal = () => {
        setShow(!isShow);
    };
    const handleStateFilter = (isShow) => {
        setShowAddSupplier(isShow);
    };
    const handleFilterReseller = (isShow) => {
        setFilterReseller(isShow);
    };
    const handleModalFilter = () => {
        setFilter(!isFilter);
    };
    const handleShowAside = (item) => {
        setAside(true);
    };
    const handleShowFilterTransaction = () => {
        setFilterTransaction(!isFilterTransaction);
    };
    return (
        <Style id="supplier" isAside={isAside}>
            <Header titlePage={titlePage} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <CardList
                            handleShowModal={handleShowModal}
                            handleModalFilter={handleModalFilter}
                            handleShowFilterTransaction={
                                handleShowFilterTransaction
                            }
                            isShowAddSupplier={isShowAddSupplier}
                            filterReseller={filterReseller}
                            isAside={isAside}
                            handleShowAside={handleShowAside}
                            handleFilterReseller={handleFilterReseller}
                            handleStateFilter={handleStateFilter}
                            setTitlePage={setTitlePage}
                        />
                    </div>
                </section>
            </div>
            <Filter
                isShow={isFilterTransaction}
                toggle={handleShowFilterTransaction}
            />
            <Detail />
            <ModalFilter toggle={handleModalFilter} isShow={isFilter} />
            {/* <ModalAddSupplier
                setShow={setShow}
                toggle={handleShowModal}
                isShow={isShow}
            /> */}
        </Style>
    );
};
export default Supplier;
