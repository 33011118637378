/*eslint-disable*/
import React from "react";
import CommonTable from "../../../Components/Molekul/CommonTable";
import service from "./index.servis";
import styled from "styled-components";
import Header from "./Header";
import { Card, CardBody, Col, ModalBody, Row, Modal } from "reactstrap";
import Button from "../../../Components/Particle/Button";
import ModalVoucher from "./ModalVoucher";
import EditVoucher from "./EditVoucher";
import MessageState from "../../../Components/Modal/Message";
import { useStore } from "../../../Reducers";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";
const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
            .card-list {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #ddd;
                transition: all 0.25s ease;
            }
            .notice {
                position: fixed;
                z-index: 3;
                top: 50px;
                left: 0;
                right: 0;
            }
        }
        transition: all 0.25s ease;
        width: 100%;
    }
`;
const ModalDelete = ({
    voucher = false,
    isOpen = false,
    toggle = () => {},
    setRefresh = () => {},
    setMessageText = () => {},
    setShowMessage = () => {},
    setStatus = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [loading, setLoading] = React.useState(false);
    const handleDeleteVoucher = () => {
        setLoading(true);
        setRefresh(false);
        service.voucherDelete({ dispatch, id: voucher.id });
    };
    React.useEffect(() => {
        if (state?.voucher?.voucherDelete?.isSuccess) {
            setStatus(state.voucher.voucherDelete.isSuccess);
            setRefresh(true);
            toggle();
            setLoading(false);
            setMessageText(state.voucher.voucherDelete.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.voucherDeleteClear({ dispatch });
        }
        if (state?.voucher?.voucherDelete?.isError) {
            setStatus(state.voucher.voucherDelete.isSuccess);
            setMessageText(state.voucher.voucherDelete.data.message);
            setShowMessage(true);
            setLoading(false);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.voucherDeleteClear({ dispatch });
        }
    }, [state, dispatch]);
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <GrowingLoading active={loading} />
            {voucher && (
                <ModalBody>
                    <div className="text-center">
                        <div style={{ margin: "30px 0" }}>
                            Apakah anda yakin ingin menghapus voucher{" "}
                            <b>{voucher.name}</b> ?
                        </div>
                        <Row>
                            <Col xs={6}>
                                <Button block onClick={toggle}>
                                    Tidak
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    onClick={handleDeleteVoucher}
                                    block
                                    color="danger"
                                >
                                    Ya
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            )}
        </Modal>
    );
};
const Voucher = () => {
    const [isRefresh, setRefresh] = React.useState(true);
    const [isShow, setShow] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [isDelete, setDelete] = React.useState(false);
    const [data, setData] = React.useState(false);
    const [isStatus, setStatus] = React.useState(true);
    const [isShowMessage, setShowMessage] = React.useState(false);
    const [messageText, setMessageText] = React.useState("");
    const handleShowModal = () => {
        setShow(!isShow);
    };
    const handleEditVoucher = (item) => {
        setEdit(!isEdit);
        setData(item);
    };
    const handleDeleteVoucher = (item) => {
        setDelete(!isDelete);
        setData(item);
    };
    return (
        <Style>
            <Header titlePage={"Voucher"} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <Card>
                            <CardBody>
                                <Button
                                    color="#5d8cf1"
                                    className="pl-5 pr-5"
                                    type="button"
                                    onClick={() => handleShowModal()}
                                >
                                    Tambah
                                </Button>
                                <Row>
                                    <Col xl="12">
                                        <CommonTable
                                            searchBy={["name"]}
                                            isRefresh={isRefresh}
                                            configuration={{
                                                searchInput: true,
                                                searchValue: ["name"],
                                                header: false,
                                                nav: {
                                                    hasSearch: true,
                                                    filter: [
                                                        {
                                                            icon: "fas fa-sort-amount-down",
                                                            type: "dropdown",
                                                            value: [
                                                                {
                                                                    value: "title",
                                                                    label: "Judul",
                                                                    key: "title",
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                                service: {
                                                    api: service.voucherList,
                                                    group: "voucher",
                                                    key: "voucherList",
                                                    filters: "",
                                                    filterData: "",
                                                },
                                                optionTable: [
                                                    {
                                                        headName: "No.",
                                                        type: "number",
                                                    },
                                                    {
                                                        headName:
                                                            "Nama Voucher",
                                                        type: "text",
                                                        fieldName: "name",
                                                        iconClass:
                                                            "fas fa-sort-alpha-down",
                                                        iconClass2:
                                                            "fas fa-sort-alpha-up-alt",
                                                    },
                                                    {
                                                        headName: "Diskon",
                                                        type: "percent",
                                                        fieldName:
                                                            "discount_percentages",
                                                        iconClass:
                                                            "fas fa-sort-numeric-down",

                                                        iconClass2:
                                                            "fas fa-sort-numeric-up",
                                                    },
                                                    {
                                                        headName:
                                                            "Maxsimal Diskon",
                                                        type: "price",
                                                        fieldName:
                                                            "max_discount",
                                                        iconClass:
                                                            "fas fa-sort-numeric-down",

                                                        iconClass2:
                                                            "fas fa-sort-numeric-up",
                                                    },
                                                    {
                                                        headName:
                                                            "Limit Voucher",
                                                        type: "text",
                                                        fieldName:
                                                            "limit_voucher",
                                                        iconClass:
                                                            "fas fa-sort-alpha-down",
                                                        iconClass:
                                                            "fas fa-sort-numeric-down",

                                                        iconClass2:
                                                            "fas fa-sort-numeric-up",
                                                    },
                                                    {
                                                        headName: "Aksi",
                                                        type: "actions",
                                                        options: [
                                                            {
                                                                actionType:
                                                                    "isEditModal",
                                                                function: (
                                                                    item
                                                                ) =>
                                                                    handleEditVoucher(
                                                                        item
                                                                    ),
                                                                renderItem:
                                                                    () => {},
                                                                field: "id",
                                                                className:
                                                                    "mr-2",
                                                            },
                                                            {
                                                                actionType:
                                                                    "isDeleteModal",
                                                                function: (
                                                                    item
                                                                ) =>
                                                                    handleDeleteVoucher(
                                                                        item
                                                                    ),
                                                                renderItem:
                                                                    () => {},
                                                                field: "id",
                                                            },
                                                        ],
                                                    },
                                                ],
                                            }}
                                        ></CommonTable>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
            <ModalVoucher
                setMessageText={setMessageText}
                setShowMessage={setShowMessage}
                setRefresh={setRefresh}
                setStatus={setStatus}
                isShow={isShow}
                toggle={() => setShow(false)}
            />
            {data && (
                <EditVoucher
                    data={data}
                    setMessageText={setMessageText}
                    setShowMessage={setShowMessage}
                    setRefresh={setRefresh}
                    setStatus={setStatus}
                    isShow={isEdit}
                    toggle={() => {
                        setData(false);
                        setEdit(false);
                    }}
                />
            )}
            <ModalDelete
                voucher={data}
                isOpen={isDelete}
                toggle={() => {
                    setDelete(false);
                    setData(false);
                }}
                setMessageText={setMessageText}
                setShowMessage={setShowMessage}
                setRefresh={setRefresh}
                setStatus={setStatus}
            />
            <MessageState
                isShow={isShowMessage}
                status={isStatus}
                message={messageText}
            />
        </Style>
    );
};
export default Voucher;
