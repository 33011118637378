import React from "react";
import { Card, CardBody, Modal, ModalBody } from "reactstrap";
import Button from "../../../../Components/Particle/Button";
import styled from "styled-components";
import service from "../index.service";
import Header from "../Header";
import Tab from "../Tab";
import DefaultImage from "../../../../Components/Assets/Image/store_default.png";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import Form from "../../../../Components/Molekul/FormValidation";
import { formatMoney } from "../../../../Utils/PriceConverter";
import { color } from "../../../../Utils/Variable";
import { useStore } from "../../../../Reducers";
import MessageState from "../../../../Components/Modal/Message";
const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
            .img-supplier {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
        }
        transition: all 0.25s ease;
        width: 100%;
    }
`;
const CommissionStyle = styled.div`
    margin-bottom: 30px;
    .user-name {
        font-size: 20px;
        font-weight: bold;
        position: relative;
        color: ${color.primary};
        margin-bottom: 30px;
        padding-bottom: 4px;
        width: max-content;
        &:before {
            content: "";
            position: absolute;
            height: 4px;
            background: ${color.primary};
            border-radius: 20px;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }
    .label-info {
        display: flex;
        margin-bottom: 10px;
        &__text {
            width: 200px;
        }
        &__value {
            margin-left: 10px;
            font-weight: 600;
        }
    }
`;
const ModalProcessCommission = ({
    setForm = () => {},
    validationClick = true,
    validateForm = true,
    form = [],
    setValidationClick = () => {},
    setValidateForm = () => {},
    isShow = false,
    toggle = () => {},
    data = {},
    setRefresh = () => {},
    dispatch,
}) => {
    const handleProcessCommission = () => {
        let isError = false;
        setRefresh(false);
        setValidationClick(isError);
        setValidateForm(false);
        let postData = {
            recipe_url: form[0].value,
            nominal: data?.wallet?.current_balance,
            account_number: data?.bank?.account_number,
            transfer_to: {
                id: data?.id,
            },
        };
        service.commissionTransaction({ dispatch, data: postData });
    };

    return (
        <Modal isOpen={isShow} toggle={toggle}>
            {data && (
                <ModalBody>
                    <CommissionStyle>
                        <h3 className="user-name">{data?.store?.name}</h3>
                        <div className="label-info">
                            <div className="label-info__text">Total Komisi</div>
                            <div>:</div>
                            <div className="label-info__value">
                                {formatMoney(data?.wallet?.current_balance)}
                            </div>
                        </div>
                        <div className="label-info">
                            <div className="label-info__text">
                                Rekening Tujuan
                            </div>
                            <div>:</div>
                            <div className="label-info__value">
                                {data?.bank?.account_number !== null
                                    ? data?.bank?.account_number
                                    : "-"}
                            </div>
                        </div>
                        <div className="label-info">
                            <div className="label-info__text">Nama Bank</div>
                            <div>:</div>
                            <div className="label-info__value">
                                {data?.bank?.bank_name !== null
                                    ? data?.bank?.bank_name
                                    : "-"}
                            </div>
                        </div>
                        <div className="label-info">
                            <div className="label-info__text">
                                Nama Pemilik Rekening
                            </div>
                            <div>:</div>
                            <div className="label-info__value">
                                {data?.bank?.account_name !== null
                                    ? data?.bank?.account_name
                                    : "-"}
                            </div>
                        </div>
                    </CommissionStyle>
                    <Form
                        setForm={setForm}
                        validateForm={validateForm}
                        validationClick={validationClick}
                        form={form}
                    />
                    <div className="text-right">
                        <Button type="button" onClick={toggle} className="mr-3">
                            Tutup
                        </Button>
                        <Button
                            type="button"
                            onClick={handleProcessCommission}
                            color="primary"
                        >
                            Proses
                        </Button>
                    </div>
                </ModalBody>
            )}
        </Modal>
    );
};
const FinanceSupplier = () => {
    const { state, dispatch } = useStore();
    const [isRefresh, setRefresh] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [status, setStatus] = React.useState(false);
    const [isModalStatusMessage, setModalStatusMessage] = React.useState(false);
    const [data, setData] = React.useState({});
    const [isShow, setShow] = React.useState(false);
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [form, setForm] = React.useState([
        {
            label: "Foto bukti transaksi",
            type: "image",
            action: "uploadImage",
            name: "image_1",
            placeholder: "Upload foto bukti transaksi",
            col: 12,
            valid: "",
            value: "",
            status: false,
            normalData: false,
            heightStyle: "250px",
            required: false,
        },
    ]);
    const handleProcessCommission = (item) => {
        setShow(!isShow);
        setData(item);
    };

    React.useEffect(() => {
        if (state?.commission?.transaction?.isSuccess) {
            service.commissionTransactionClear({ dispatch });
            setShow(false);
            setMessage(state.commission.transaction.message.text);
            setStatus(state.commission.transaction.isSuccess);
            setModalStatusMessage(true);
            setRefresh(true);
            form[0].value = "";
            form[0].status = false;
            setForm(form);
            setTimeout(() => {
                setModalStatusMessage(false);
            }, 3000);
        }
        if (state?.commission?.transaction?.isError) {
            service.commissionTransactionClear({ dispatch });
            setMessage(state.commission.transaction.message.text);
            setMessage(state.commission.transaction.data.message);
            setModalStatusMessage(true);
            setRefresh(true);
            setTimeout(() => {
                setModalStatusMessage(false);
            }, 3000);
        }
    }, [state, dispatch, form]);
    return (
        <Style>
            <ModalProcessCommission
                handleProcessCommission={handleProcessCommission}
                dispatch={dispatch}
                setRefresh={setRefresh}
                data={data}
                isShow={isShow}
                setForm={setForm}
                validateForm={validateForm}
                validationClick={validationClick}
                form={form}
                toggle={() => {
                    setShow(!isShow);
                    setData({});
                    form[0].value = "";
                    form[0].status = false;
                    setForm(form);
                }}
                setValidateForm={setValidateForm}
                setValidationClick={setValidationClick}
            />
            <Header titlePage={"Supplier"} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <Card>
                            <CardBody>
                                <Tab />

                                <CommonTable
                                    download={true}
                                    id="tableSupplier"
                                    isRefresh={isRefresh}
                                    searchBy="full_name"
                                    configuration={{
                                        searchInput: true,
                                        searchValue: [
                                            "store.name",
                                            "full_name",
                                            "email",
                                            "bank.bank_name",
                                            "bank.account_name",
                                            "phone_number",
                                        ],

                                        // searchValue: "store.name",
                                        header: false,
                                        nav: {
                                            hasSearch: true,
                                            filter: [
                                                {
                                                    icon: "fas fa-sort-amount-down",
                                                    type: "dropdown",
                                                    value: [
                                                        {
                                                            value: "email",
                                                            label: "Email",
                                                            key: "email",
                                                        },
                                                        {
                                                            value: "full_name",
                                                            label: "Name",
                                                            key: "full_name",
                                                        },
                                                        {
                                                            value: "store.name",
                                                            label: "Nama Toko",
                                                            key: "store.name",
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        service: {
                                            api: service.supplierList,
                                            group: "supplier",
                                            key: "finance",
                                            filters: "",
                                            filterData: `["wallet.current_balance","!=","0"]`,
                                        },
                                        optionTable: [
                                            {
                                                headName: "No",
                                                type: "number",
                                            },
                                            {
                                                headName: "Nama Toko",
                                                type: "isCustom",
                                                fieldName: "item.store.name",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <img
                                                                src={
                                                                    item.store
                                                                        .image_store !==
                                                                    undefined
                                                                        ? item
                                                                              .store
                                                                              .image_store
                                                                        : DefaultImage
                                                                }
                                                                alt=""
                                                                className="img-fluid mr-3 img-supplier"
                                                            />
                                                            <span>
                                                                {
                                                                    item.store
                                                                        .name
                                                                }
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Nama Pemilik",
                                                type: "text",
                                                fieldName: "full_name",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                            },
                                            {
                                                headName: "Telepon Toko",
                                                type: "isCustome",
                                                fieldName: "store.phone_cs",
                                                iconClass:
                                                    "fas fa-sort-numeric-down",
                                                iconClass2:
                                                    "fas fa-sort-numeric-up",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span>
                                                                {
                                                                    item.store
                                                                        .phone_cs
                                                                }
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName:
                                                    "Nama pemilik Rekening",
                                                type: "isCustome",
                                                fieldName: "bank.account_name",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span>
                                                                {item?.bank
                                                                    ?.account_name ||
                                                                    "-"}
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "Nama Bank",
                                                type: "isCustome",
                                                fieldName: "bank.bank_name",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span>
                                                                {item?.bank
                                                                    ?.bank_name ||
                                                                    "-"}
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },

                                            {
                                                headName: "Pendapatan",
                                                type: "isCostume",
                                                fieldName:
                                                    "wallet.current_balance",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <span>
                                                                {formatMoney(
                                                                    item.wallet
                                                                        .current_balance
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                headName: "",
                                                type: "isCostume",
                                                renderItem: (item) => {
                                                    return (
                                                        <React.Fragment>
                                                            <Button
                                                                color="primary"
                                                                onClick={() =>
                                                                    handleProcessCommission(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                Kirim Komisi
                                                            </Button>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                        ],
                                    }}
                                ></CommonTable>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
            <MessageState
                isShow={isModalStatusMessage}
                status={status}
                message={message}
            />
        </Style>
    );
};
export default FinanceSupplier;
