/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const ModalAddQA = ({
    isShowQA = false,
    toggle = () => {},
    setShowQA = () => {},
    setLoading = () => {},
    setRefresh = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [form, setForm] = useState([
        {
            type: "select",
            action: "select",
            label: "Kategori",
            name: "title",
            service: {
                api: service.categoryFaq,
                group: "faq",
                key: "faqList",
                filters: "",
            },
            haveSubTitle: true,
            required: true,
            placeholder: "Pilih kategori",
            col: 6,
            valid: "Kategori tidak boleh kosong",
            value: "",
            status: false,
        },

        {
            type: "text",
            action: "text",
            label: "Pertanyaan",
            required: true,
            name: "question",
            placeholder: "Pertanyaan",
            col: 6,
            valid: "Pertanyaan tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "html",
            action: "html",
            name: "answer",
            required: true,
            placeholder: "Jawaban",
            label: "Jawaban",
            col: 8,
            valid: "Jawaban tidak boleh kosong",
            value: "",
            rows: 3,
            status: false,
        },
    ]);
    const handlerSubmitData = () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let data = {
                question: form[1].value,
                answer: form[2].value,
                faq: {
                    id: form[0].value.value,
                    category: "",
                    title: form[0].value.label,
                },
            };
            service.AddQA({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (form[0].value.value === "Reseller") {
        }
    }, [form]);
    React.useEffect(() => {
        if (state?.qa?.addQA?.isSuccess) {
            form[0].value = "";
            form[1].value = "";
            form[2].value = "";
        }
    }, [state]);
    React.useEffect(() => {});
    return (
        <Modal size="lg" isOpen={isShowQA} toggle={toggle}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Tambah QA</h5>
                <Form
                    isShow={isShowQA}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
                <div className="text-right">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => handlerSubmitData()}
                    >
                        Tambah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default ModalAddQA;
