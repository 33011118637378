import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Button from "../../../../Components/Particle/Button";

const ModalDeletePayment = ({
    data = false,
    isShow = false,
    toggle = () => {},
    handleDeletePayment = () => {},
}) => {
    return (
        <Modal isOpen={isShow} toggle={toggle}>
            <ModalBody>
                <h6 className="my-4 text-center">
                    Hapus {data?.name} dari daftar pembayaran ?{" "}
                </h6>
                <Row>
                    <Col xl="6" md="6" lg="6" sm="6" xs="6">
                        <Button
                            type="button"
                            color="primary"
                            onClick={toggle}
                            block
                        >
                            Tidak
                        </Button>
                    </Col>
                    <Col xl="6" md="6" lg="6" sm="6" xs="6">
                        <Button
                            type="button"
                            color="outline-primary"
                            onClick={handleDeletePayment}
                            block
                        >
                            Ya
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};
export default ModalDeletePayment;
