import React from "react";
import Header from "./Header";
import CardNotVerification from "./CardList/CardNotVerification";
import styled from "styled-components";
import Aside from "../../../Components/Admin/Aside";
import { aside } from "../../../Utils/Variable";
import Filter from "../../../Components/Admin/Modal/Filter";
const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
        }
        transition: all 0.25s ease;
        width: ${(props) =>
            props.isAside ? `calc(100% - ${aside.width})` : "100%"};
    }
`;
const NotVerification = () => {
    const [isAside, setAside] = React.useState(false);
    const [isShowAddSupplier, setShowAddSupplier] = React.useState(true);
    const [isFilterTransaction, setFilterTransaction] = React.useState(false);
    const [titlePage, setTitlePage] = React.useState("List Reseller");
    const [filterReseller, setFilterReseller] = React.useState(false);
    const handleStateFilter = (isShow) => {
        setShowAddSupplier(isShow);
    };
    const handleFilterReseller = (isShow) => {
        setFilterReseller(isShow);
    };
    const handleShowAside = () => {
        setAside(true);
    };
    const handleShowFilterTransaction = () => {
        setFilterTransaction(!isFilterTransaction);
    };
    return (
        <Style id="reseller" isAside={isAside}>
            <Header titlePage={titlePage} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <CardNotVerification
                            handleShowFilterTransaction={
                                handleShowFilterTransaction
                            }
                            isShowAddSupplier={isShowAddSupplier}
                            filterReseller={filterReseller}
                            isAside={isAside}
                            handleFilterReseller={handleFilterReseller}
                            handleStateFilter={handleStateFilter}
                            setAside={setAside}
                            handleShowAside={handleShowAside}
                            setTitlePage={setTitlePage}
                        />
                    </div>
                </section>
                {isAside && <Aside isAside={isAside} setAside={setAside} />}
            </div>
            <Filter
                isShow={isFilterTransaction}
                toggle={handleShowFilterTransaction}
            />
        </Style>
    );
};
export default NotVerification;
