import React, { useRef, useEffect } from "react";
import debounce from "../../../Utils/Debounce";

import {
    FormGroup,
    Row,
    Col,
    InputGroupText,
    InputGroup,
    Input,
    InputGroupAddon,
} from "reactstrap";
import styled from "styled-components";
import Button from "../../Particle/Button";

const Style = styled.div`
    .checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }
    .checkbox:hover input ~ .checkmark {
        background-color: #ccc;
    }

    .checkbox input:checked ~ .checkmark {
        background-color: #2196f3;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .checkbox input:checked ~ .checkmark:after {
        display: block;
    }
    .checkbox .checkmark:after {
        left: 9px;
        top: 5px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
const TableTop = (
    {
        handleSetDownload = () => {},
        getData = () => {},
        configTable = {},
        handleCheckAll = () => {},
        checkbox = false,
        searchBy = "full_name",
        searchInput,
        onSearch = () => {},
        searchValue,
        dummyData = [],
        service,
        download = false,
        id = "default",
        setDataTable = () => {},
        dataNav,
        onGetValueFilter = () => {},
    },
    props
) => {
    const inputEl = useRef(null);

    const exportData = () => {
        handleSetDownload();
    };
    const eventOnChangeSearchInput = debounce((value) => {
        if (service) {
            onSearch(value);
        }
    }, 750);
    const eventOnChangeSearchInputDummy = (value) => {
        if (service) {
            onSearch(value);
        } else {
            let getData = dummyData;
            if (value.length > 0) {
                let data = dummyData;
                data = data.filter((person) => {
                    return person[searchBy].toLowerCase().search(value) !== -1;
                    // return lowerCase;
                });
                setDataTable(data);
            }
            if (value.length < 1) {
                setDataTable(getData);
            }
        }
    };
    useEffect(() => {
        if (searchInput) {
            inputEl.current.value = searchValue;
        }
    }, [searchValue, searchInput]);
    const handleCheckedType = (e) => {
        let checked = e.target.checked;
        handleCheckAll(checked);
    };
    return (
        <Row
            className={
                checkbox
                    ? `justify-content-between align-items-center`
                    : `justify-content-end`
            }
        >
            {checkbox && (
                <Col md={5} lg={5} sm={12} xs={12}>
                    <Style>
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                onChange={handleCheckedType}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </Style>
                </Col>
            )}
            {searchInput && (
                <Col md={5} lg={5} sm={12} xs={12}>
                    <FormGroup className="search-box">
                        <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-search" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                ref={inputEl}
                                onChange={
                                    service
                                        ? ({ target: { value } }) =>
                                              eventOnChangeSearchInput(value)
                                        : (e) =>
                                              eventOnChangeSearchInputDummy(
                                                  e.target.value
                                              )
                                }
                                defaultValue={searchValue}
                                placeholder="Cari"
                                type="text"
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
            )}
            {download && (
                <Col md={2} lg={2} sm={12} xs={12}>
                    <Button block color="primary" onClick={() => exportData()}>
                        <i className="fas fa-download"></i> Download
                    </Button>
                </Col>
            )}
        </Row>
    );
};

export default TableTop;
