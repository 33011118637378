/* eslint-disable */
import React, { useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Button from "../../../Components/Particle/Button";
import Form from "../../../Components/Molekul/FormValidation";
import Logo from "../../../Components/Assets/Image/Lakugan_Logo.png";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LocalStorage from "../../../Utils/LocalStorage";
import { useEffect } from "react";
import { useStore } from "../../../Reducers";
import service from "./service";
import JSON_to_URLEncoded from "../../../Utils/JSON-URLEncode";

const Style = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Login = () => {
  const [validationClick, setValidationClick] = useState(true);
  const [validateForm, setValidateForm] = useState(true);
  const history = useHistory();
  const [message, setMessage] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { dispatch, state } = useStore();
  const [form, setForm] = useState([
    {
      type: "text",
      action: "text",
      name: "username",
      label: "Username",
      col: 12,
      valid: "Username tidak boleh kosong",
      value: "",
      status: false,
    },
    {
      type: "password",
      action: "password",
      name: "password",
      label: "Password",
      col: 12,
      valid: "Password tidak boleh kosong",
      value: "",
      status: false,
    },
  ]);

  const handlerSubmitData = (e) => {
    setAlert(false);
    let isError = false;
    e.preventDefault();
    for (let key in form) {
      if (!form[key].status) {
        isError = true;
      }
    }
    setValidationClick(isError);
    setValidateForm(false);
    if (!isError) {
      let formData = {
        username: form[0].value,
        password: form[1].value,
        grant_type: "password",
        client_id: "my-client-id",
        client_secret: "password",
      };
      setLoading(true);
      let data = JSON_to_URLEncoded(formData);
      service.token({ dispatch, data });
    }
    setTimeout(() => {
      setLoading(false);
      setMessage({
        mode: {
          type: "danger",
        },
        text: "Connection timeout",
      });
      setAlert(true);
    }, 60000);
  };
  useEffect(() => {
    if (state?.user?.token?.isSuccess) {
      service.tokenClear({ dispatch });
      setLoading(false);
      const ex_date = new Date();
      LocalStorage().save(
        "EX",
        `${new Date(ex_date.getTime() + 5 * 60 * 60 * 1000)}`
      );
      LocalStorage().save("authAdminLakugan", state.user.token.data);
      history.push("/admin/supplier/verification");
    }
    if (state?.user?.token?.isError) {
      setMessage(state.user.token.message);
      setLoading(false);
      setAlert(true);
    }
  }, [state]);
  return (
    <Style id="login">
      <Container>
        <Row className="justify-content-center">
          <Col xl="6" md="6" sm="8" xs="12">
            <Row className="justify-content-center mb-3">
              <Col xl="8" md="8" sm="8" xs="12">
                <img src={Logo} alt="logo" className="img-fluid" />
              </Col>
            </Row>
            <Card>
              <CardBody>
                {alert && (
                  <Alert className="text-center" color={message?.mode?.type}>
                    {message?.text}
                  </Alert>
                )}
                <form onSubmit={handlerSubmitData}>
                  <Form
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                  />
                  <Button
                    block
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? <Spinner color="light" /> : "Login"}
                  </Button>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Style>
  );
};
export default Login;
