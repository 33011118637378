import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Button from "../../../../../../Components/Particle/Button";

const Style = styled(Modal)`
  .tab-top-title {
    width: 100%;
    text-align: center;
    margin: auto;
    display: block;
  }
  .title {
    text-align: center;
    margin: auto;
    display: block;
    font-size: 2.25rem;
  }
  .btn-right {
    float: right;
  }
  .description {
    font-size: 14px;
    p {
      margin-bottom: 0;
    }
    @media (max-width: 450px) {
      font-size: 10px;
    }
  }
`;

const Preview = ({
  isShow = false,
  toggle = () => {},
  dataArticle = false,
  handleEditPreview = () => {},
}) => {
  return (
    <Style size="lg" isOpen={isShow} toggle={toggle}>
      <ModalHeader className="border-0 pb-0" toggle={toggle} />
      <ModalBody>
        <div>
          {dataArticle && (
            <img
              className="img-fluid"
              src={dataArticle?.img_url}
              alt="Not found or not added"
            />
          )}
          <div
            className="title"
            dangerouslySetInnerHTML={{ __html: dataArticle.title }}
          ></div>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: dataArticle.content }}
          ></div>
        </div>
        <Button
          color="secondary"
          className="btn-right"
          onClick={() => handleEditPreview(dataArticle)}
        >
          Edit
        </Button>
      </ModalBody>
    </Style>
  );
};

export default Preview;
