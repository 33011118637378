import React from "react";
import styled from "styled-components";
import { aside } from "../../../Utils/Variable";
import Rating from "../../../Components/Particle/Rating";
import Icon from "../../../Components/Assets/Image/store_default.png";

import { formatMoney } from "../../../Utils/PriceConverter";
import Button from "../../../Components/Particle/Button";
import { Col, FormGroup, Row } from "reactstrap";
import EditProfile from "../Modal/EditProf";
import ListProduct from "../Modal/ListProduct";
import CreateProduct from "../Modal/CreateProduct";

const Style = styled.aside`
    width: ${(props) => (props.isAside ? `${aside.width}` : "0px")};
    background: #fff;
    transition: all 0.25s ease;
    padding: ${(props) => (props.isAside ? "20px" : "0")};
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0 2px 4px #ddd;
    @media (max-width: 900px) {
        width: ${(props) => (props.isAside ? `100%` : "0px")};
    }
    @media (max-width: 767px) {
        width: ${(props) => (props.isAside ? `100%` : "0px")};
    }
    .img-profile {
        width: 200px;
        border: 1px solid #aaa;
        padding: 10px;
        border-radius: 50%;
        margin-bottom: 20px;
    }
    .desc {
        p {
            font-weight: bold;
            font-size: 14px;
        }
        .balance {
            font-size: 20px;
        }
        .btn {
            font-size: 12px;
        }
    }
    .btn-close {
        border: none;
        background: transparent;
    }
`;

const Aside = ({
    isAside = false,
    setAside = () => {},
    supplierDetail = {},
}) => {
    const [isShowProfile, setShowProfile] = React.useState(false);
    const [isDataProduct, setDataProduct] = React.useState(false);
    const [isCreateProduct, setCreateProduct] = React.useState(false);
    const months = [
        "Januari",
        "Febuari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "Oktober",
        "November",
        "Desember",
    ];
    const handleCloseAside = () => {
        setAside(false);
    };
    const handleEditProfile = () => {
        setShowProfile(!isShowProfile);
    };
    const handleListProduct = () => {
        setDataProduct(!isDataProduct);
    };
    const handleCreateProduct = () => {
        setCreateProduct(!isCreateProduct);
        setDataProduct(false);
    };
    const createDate = () => {
        let fullDate = new Date(supplierDetail.created);
        let getDate = fullDate.getDate();
        let getMonth = fullDate.getMonth();
        let getYear = fullDate.getFullYear();
        return (
            <div>
                {getDate} {months[getMonth - 1]} {getYear}
            </div>
        );
    };
    return (
        <Style id="aside" isAside={isAside}>
            <div className="text-right">
                <button
                    type="button"
                    onClick={handleCloseAside}
                    className="btn-close"
                >
                    <i className="fas fa-times"></i>
                </button>
            </div>
            <div className="text-center mb-5">
                <img src={Icon} alt="" className="img-fluid img-profile" />
                <h6>{supplierDetail.store.name}</h6>
                <Rating rating={5} totalStars={5} />
            </div>
            <div className="desc">
                <div className="mb-3">
                    <label>Alamat Toko</label>
                    <p>
                        {supplierDetail.address === null
                            ? "-"
                            : supplierDetail.address}
                    </p>
                </div>
                <div className="mb-3">
                    <label>Nomor Telepon Toko</label>
                    <p>
                        {supplierDetail.phone_number === null
                            ? "-"
                            : supplierDetail.phone_number}
                    </p>
                </div>
                <div className="mb-3">
                    <label>Tanggal Bergabung</label>
                    {supplierDetail.created === null ? "-" : createDate()}
                </div>
                <div className="mb-3">
                    <label>Saldo Utama</label>
                    <p className="balance">{formatMoney(26850000)}</p>
                </div>
                <FormGroup>
                    <Button type="button" color="#5d8cf1">
                        Beri Akses
                    </Button>
                </FormGroup>
                <Row>
                    <Col xl="6" md="6" sm="6" xs="12">
                        <Button
                            onClick={handleListProduct}
                            type="button"
                            color="#5d8cf1"
                        >
                            List Product
                        </Button>
                    </Col>
                    <Col xl="6" md="6" sm="6" xs="12">
                        <Button
                            onClick={handleEditProfile}
                            type="button"
                            color="#5d8cf1"
                        >
                            Edit Profile
                        </Button>
                    </Col>
                </Row>
            </div>
            <CreateProduct
                toggle={handleCreateProduct}
                isShow={isCreateProduct}
            />
            <ListProduct
                handleCreateProduct={handleCreateProduct}
                isShow={isDataProduct}
                toggle={handleListProduct}
            />
            <EditProfile isShow={isShowProfile} toggle={handleEditProfile} />
        </Style>
    );
};
export default Aside;
