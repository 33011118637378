import React from "react";
import { Modal, ModalBody, ModalHeader, Col, Row } from "reactstrap";
import Button from "../../../../Components/Particle/Button";
import styled from "styled-components";
import service from "../index.service";
import { useStore } from "../../../../Reducers";

const Style = styled(Modal)`
    .tab-top-title {
        width: 100%;
        text-align: center;
        margin: auto;
        display: block;
    }
    .title {
        text-align: center;
        margin: auto;
        display: block;
        font-size: 2.5rem;
    }
`;

const ModalDeleteBlog = ({
    isDisplay = false,
    toggle = () => {},
    dataBlog = false,
    setLoading = () => {},
    setRefresh = () => {},
    setDisplay = () => {},
}) => {
    const { dispatch } = useStore();

    const handleDeleteItem = () => {
        setRefresh(false);
        setLoading(true);
        let id = dataBlog.id;
        let data = {
            title: dataBlog.title,
        };
        service.deleteBlog({ dispatch, id, data });
        setLoading(true);
    };
    return (
        <Style isOpen={isDisplay} toggle={toggle}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h6 className="my-4 text-center">
                    Apakah anda yakin akan menghapus blog
                    <br></br>"{dataBlog?.title}" ?
                </h6>
                <Row>
                    <Col xl="6" md="6" lg="6" sm="6" xs="6">
                        <Button
                            type="button"
                            color="primary"
                            onClick={toggle}
                            block
                        >
                            Tidak
                        </Button>
                    </Col>
                    <Col xl="6" md="6" lg="6" sm="6" xs="6">
                        <Button
                            type="button"
                            color="outline-primary"
                            onClick={handleDeleteItem}
                            block
                        >
                            Ya
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Style>
    );
};

export default ModalDeleteBlog;
