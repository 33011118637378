import React from "react";
import { Card, CardBody } from "reactstrap";

import Header from "../Header";
import styled from "styled-components";
import Tab from "../Tab";

import CardList from "./CardList";
import ModalAddBanner from "./ModalAddBanner";

const Style = styled.div`
  section {
    .card-section {
      padding: 20px;
    }
    transition: all 0.25s ease;
    width: 100%;
  }
`;

const PostBanner = () => {
  const [isRefresh, setRefresh] = React.useState(true);
  const [isShow, setShow] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [titlePage, setTitlePage] = React.useState("Banner");
  const [isShowAddBanner, setShowAddBanner] = React.useState(true);

  const handleShowModal = () => {
    setShow(!isShow);
  };
  const handleStateFilter = (isShow) => {
    setShowAddBanner(isShow);
  };
  return (
    <Style>
      <Header titlePage={titlePage} />
      <div className="d-flex">
        <section>
          <div className="card-section">
            <Card>
              <CardBody>
                <Tab />
                <CardList
                  isRefresh={isRefresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                  isLoading={isLoading}
                  handleShowModal={handleShowModal}
                  isShowAddBanner={isShowAddBanner}
                  handleStateFilter={handleStateFilter}
                  setTitlePage={setTitlePage}
                />
              </CardBody>
            </Card>
          </div>
        </section>
      </div>
      <ModalAddBanner
        setRefresh={setRefresh}
        setLoading={setLoading}
        setShow={setShow}
        toggle={handleShowModal}
        isShow={isShow}
      />
    </Style>
  );
};
export default PostBanner;
