export const color = {
    primary: "#00278c",
    secondary: "#6488f3",
};
export const sidebar = {
    width: 250,
};
export const aside = {
    width: "300px",
};
