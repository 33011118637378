import React from "react";
import TabLink from "../../../Components/Molekul/TabLink";

const Tab = () => {
    const tabLink = [
        {
            name: "Blog",
            link: "/admin/setting/blog",
        },
        {
            name: "FAQ",
            link: "/admin/setting/faq",
        },
        {
            name: "Banner",
            link: "/admin/setting/banner",
        },
        {
            name: "Tentang Lakugan",
            link: "/admin/setting/tentang",
        },
        {
            name: "Metode Pembayaran",
            link: "/admin/setting/payment-method",
        },
    ];
    return <TabLink data={tabLink} />;
};
export default Tab;
