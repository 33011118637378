/* eslint-disable */
import React, { useState } from "react";

import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";

import { useStore } from "../../../../Reducers";
import service from "../index.service";

const ModalAddBanner = ({
    isShow = false,
    toggle = () => {},
    setShow = () => {},
    setLoading = () => {},
    setRefresh = () => {},
}) => {
    const today = new Date();
    const tommorrow = new Date(today);
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [isMessage, setMessage] = React.useState(false);
    const [form, setForm] = useState([
        {
            type: "text",
            action: "text",
            required: true,
            label: "Judul",
            labelClass: "position-relative w-100",
            name: "Judul",
            col: 6,
            character: {
                min: {
                    length: 4,
                    valid: "Minimal 4 karakter",
                },
                max: {
                    length: 25,
                    valid: "Maximal 25 karakter",
                },
            },
            valid: "Judul tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "textarea",
            action: "textarea",
            label: "Deskripsi",
            required: true,
            name: "content",
            col: 12,
            valid: "Deskripsi tidak boleh kosong",
            value: "",
            character: {
                max: {
                    length: 700,
                    valid: "Maximal 10 karakter",
                },
                min: {
                    length: 10,
                    valid: "Minimal 10 karakter",
                },
            },
            rows: 3,
            labelClass: "position-relative w-100",
            status: false,
        },
        {
            type: "datepicker",
            action: "datepicker",
            label: "Terbitkan sampai",
            name: "publish_until",
            col: 6,
            valid: "Tanggal penerbitan tidak boleh kosong",
            value: tommorrow.setDate(tommorrow.getDate() + 1),
            minDate: tommorrow.setDate(tommorrow.getDate()),
            status: true,
        },
        {
            type: "checkbox",
            action: "checkbox",
            label: "Diterbitkan?",
            name: "published",
            col: 6,
            valid: "Published tidak boleh kosong",
            value: false,
            status: true,
        },
        {
            type: "image",
            action: "uploadImage",
            label: "Gambar (Uk: 1440x500)",
            required: true,
            name: "image.img_url",
            placeholder: "Klik Untuk Masukkan Gambar",
            col: 12,
            setting: {
                width: 1440,
                height: 500,
                valid: "Tipe file yang anda pilih tidak sesuai",
            },
            valid: "Harap masukkan gambar",
            value: "",
            status: false,
            normalData: true,
            heightStyle: "200px",
        },
    ]);
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            if (file !== undefined) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // console.log("masuk sini");
                    return resolve(reader.result);
                };
                reader.onerror = (error) => reject(error);
            }
        });
    const handlerSubmitData = async () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        console.log("form", form);
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let data = {
                title: form[0].value,
                description: form[1].value,
                image: {
                    name: form[4].value.name,
                    img_url: await toBase64(form[4].value),
                },
                banner_url: "",
                published: `${form[3].value}`,
                publish_until: form[2].value,
            };
            service.newBanner({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (state?.banner?.newBanner?.isSuccess) {
            setShow(false);
            form[0].value = "";
            form[1].value = "";
            form[3].value = "";
            form[4].value = "";
            setForm(form);
        }
    }, [state]);
    React.useEffect(() => {
        const timer = setTimeout(() => setMessage(false), 2000);
        return () => clearTimeout(timer);
    });
    return (
        <Modal size="lg" isOpen={isShow} toggle={toggle}>
            {isMessage && <Alert color="danger">{isMessage}</Alert>}
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Tambah Banner</h5>
                <Form
                    isShow={isShow}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
                <div className="text-right">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => handlerSubmitData()}
                    >
                        Tambah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddBanner;
