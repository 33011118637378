import React from "react";
import { Col, Row } from "reactstrap";
import CardProduct from "../../../../Components/Admin/Card/CardProduct";

const ListProduct = ({ data = [] }) => {
    return (
        <React.Fragment>
            <Row>
                {data.length > 0
                    ? data.map((item, key) => {
                          return (
                              <Col xl="3" md="3" sm="4" xs="6" key={key}>
                                  <CardProduct data={item} />
                              </Col>
                          );
                      })
                    : false}
            </Row>
        </React.Fragment>
    );
};
export default ListProduct;
