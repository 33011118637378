import React from "react";
// import CommonTable from "../../../../Components/Molekul/CommonTable";
import styled from "styled-components";
import { color } from "../../../../Utils/Variable";

const Style = styled.div`
    .tg {
        padding: 3px;
        margin: 5px;
        margin-left: auto;
        margin-right: auto;
    }
    .table-data {
        font-size: 14px;
        padding: 10px 5px;
        word-break: normal;
        text-align: center;
        margin: auto;
    }
    .table-head {
        font-size: 14px;
        font-weight: bolder;
        padding: 10px 10px;
        word-break: normal;
        text-align: center;
        width: 40%;
        margin: auto;
        color: #fff;
        background: ${color.primary};
    }
    @media screen and (max-width: 767px) {
        .tg {
            width: auto !important;
        }
        .tg col {
            width: auto !important;
        }
        .tg-wrap {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
`;

const ListReseller = ({ item = [] }) => {
    // eslint-disable-next-line
    return item.length > 0 ? (
        <Style>
            <div className="tg-wrap">
                <table className="tg">
                    <thead>
                        <tr>
                            <th className="table-head">Nama</th>
                            <th className="table-head">Email</th>
                            <th className="table-head">Telepon</th>
                            <th className="table-head">Deskripsi</th>
                            <th className="table-head">Alamat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr> */}
                        {item.map((value, key) => {
                            return (
                                <tr key={key}>
                                    <td className="table-data">
                                        {value.full_name}
                                    </td>
                                    <td className="table-data">
                                        {value.email}
                                    </td>
                                    <td className="table-data">
                                        {value.phone_number}
                                    </td>
                                    <td className="table-data">
                                        {value.description}
                                    </td>
                                    <td className="table-data">
                                        {value.address}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Style>
    ) : (
        <h6>NO DATA</h6>
    );
};
export default ListReseller;
