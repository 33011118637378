/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const ModalAddBlog = ({
    isShow = false,
    toggle = () => {},
    setShow = () => {},
    setLoading = () => {},
    setRefresh = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [form, setForm] = useState([
        {
            type: "image",
            action: "uploadImage",
            name: "article_image.img_url",
            placeholder: "Klik Untuk Masukkan Gambar",
            col: 12,
            valid: "Harap masukan gambar",
            value: "",
            status: false,
            normalData: false,
            heightStyle: "200px",
        },
        {
            type: "text",
            action: "text",
            label: "Judul",
            required: true,
            name: "title",
            col: 6,
            valid: "Title tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            name: "category",
            type: "select",
            action: "select",
            required: true,
            label: "Pilih kategori",
            col: 6,
            service: {
                api: service.category,
                group: "category",
                key: "category",
            },
            isMulti: false,
            valid: "Kategori wajib diisi",
            value: "",
            status: false,
        },
        {
            type: "html",
            action: "html",
            name: "content",
            label: "Deskripsi",
            required: true,
            placeholder: "Deskripsi",
            col: 12,
            valid: "Deskripsi tidak boleh kosong",
            value: "",
            rows: 3,
            status: false,
        },
    ]);
    const handlerSubmitData = () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let data = {
                category: { id: form[2].value.value },
                author: "Anon",
                title: form[1].value,
                content: form[3].value,
                article_image: [
                    {
                        name: form[1].value,
                        img_url: form[0].value,
                    },
                ],
            };
            service.blogList({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (state?.blog?.blogList?.isSuccess) {
            form[0].value = "";
            form[1].value = "";
            form[2].value = "";
            form[3].value = "";
        }
    }, [state, dispatch]);
    return (
        <Modal size="lg" isOpen={isShow} toggle={toggle}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Tambah Blog</h5>
                <Form
                    isShow={isShow}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
                <div className="text-right">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => handlerSubmitData()}
                    >
                        Tambah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default ModalAddBlog;
