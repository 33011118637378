import React from "react";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import styled from "styled-components";
import Button from "../../../../Components/Particle/Button";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import TabArticle from "./TabArticle";
import Article from "./Article";
import MessageState from "../../../../Components/Modal/Message";

const Style = styled(Card)`
    .card-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        transition: all 0.25s ease;
    }
    .notice {
        position: fixed;
        z-index: 3;
        top: 50px;
        left: 0;
        right: 0;
    }
`;
const CardList = () => {
    const { dispatch, state } = useStore();
    const [isStatus, setStatus] = React.useState(true);
    const [isShowMessage, setShowMessage] = React.useState(false);
    const [messageText, setMessageText] = React.useState("");
    const [newCategory, setCategory] = React.useState("");
    const [isLoading, setLoading] = React.useState(false);
    // eslint-disable-next-line
    const [isRefresh, setRefresh] = React.useState(true);

    const handleInputCategory = (e) => {
        let value = e.target.value;
        if (value === " ") {
            setCategory("");
        } else {
            setCategory(value);
        }
    };
    const handleCreateCategory = () => {
        setRefresh(false);
        let data = {
            name: newCategory,
            type: 1,
            is_special_cut: false,
        };
        // console.log(newCategory);
        if (newCategory.length > 2) {
            setLoading(true);
            service.newCategory({ dispatch, data });
            // service.categoryArtPostClear({ dispatch });
        }
    };
    React.useEffect(() => {
        if (state?.category?.newCategory?.isSuccess) {
            setCategory("");
            setRefresh(true);
            setLoading(false);
            setStatus(state.category.newCategory.isSuccess);
            setMessageText(state.category.newCategory.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.newCategoryClear({ dispatch });
            // window.location.reload();
        }
        if (state?.category?.newCategory?.isError) {
            setCategory("");
            setLoading(false);
            setStatus(state.category.newCategory.isSuccess);
            setMessageText(state.category.newCategory.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.newCategoryClear({ dispatch });
            // window.location.reload();
        }
    }, [state, dispatch]);
    return (
        <Style>
            <GrowingLoading active={isLoading} />
            <CardBody>
                <Row>
                    <Col xl="6" md="12" sm="12">
                        <div className="mb-4">
                            <TabArticle />
                        </div>
                        <div className="mb-4">
                            <FormGroup>
                                <Row>
                                    <Col xl="8" md="8" sm="8" xs="12">
                                        <Input
                                            type="text"
                                            name=""
                                            value={newCategory}
                                            onChange={handleInputCategory}
                                        />
                                    </Col>
                                    <Col xl="4" md="4" sm="4" xs="12">
                                        <Button
                                            block
                                            color="#5d8cf1"
                                            type="button"
                                            onClick={handleCreateCategory}
                                        >
                                            Tambah
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </div>
                        <div>
                            <Article
                                isRefresh={isRefresh}
                                setRefresh={setRefresh}
                            />
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <MessageState
                isShow={isShowMessage}
                status={isStatus}
                message={messageText}
            />
        </Style>
    );
};
export default CardList;
