import React from "react";
import HeaderRight from "../../../Components/Admin/Header";
import styled from "styled-components";

const Style = styled.div`
    padding: 20px;
    box-shadow: 0 3px 6px #eee;
    background: #fff;
    h4 {
        font-weight: bold;
    }
    .header-left {
        @media (max-width: 767px) {
            margin-left: 30px;
        }
    }
`;
const Header = () => {
    return (
        <Style>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center header-left">
                    <h4 className="mb-0">Kategori</h4>
                </div>
                <HeaderRight />
            </div>
        </Style>
    );
};
export default Header;
