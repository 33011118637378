/*eslint-disable*/
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import MessageState from "../../../../Components/Modal/Message";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import { formatMoney } from "../../../../Utils/PriceConverter";
import { color } from "../../../../Utils/Variable";
import service from "../index.service";

const Style = styled.div`
  .order {
    margin-bottom: 20px;
    .title {
      font-weight: bold;
      position: relative;
      padding: 0 0 10px;
      margin: 0 0 10px;
      font-size: 18px;
      color: ${color.primary};
      &:before {
        content: "";
        height: 2px;
        left: 0;
        margin: auto;
        position: absolute;
        bottom: 0;
        width: 100px;
        background: ${color.primary};
      }
    }
  }
  .data-order {
    display: flex;
    &__label {
      width: 175px;
    }
    &__dot {
      margin-right: 5px;
    }
    &__value {
      color: ${color.primary};
      font-weight: 600;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .field-label {
    width: 25%;
  }
  .field-value {
    width: 75%;
  }
  p {
    padding: 0;
    margin: 0;
    font-size: 13px;
  }
`;
const ModalDetail = ({
  setRefresh = () => {},
  setShow = () => {},
  isShow = false,
  toggle = () => {},
  data,
  setData = () => {},
}) => {
  const { dispatch, state } = useStore();
  const [isLoading, setLoading] = React.useState(false);
  const [validationClick, setValidationClick] = React.useState(true);
  const [validateForm, setValidateForm] = React.useState(true);
  const [isStatus, setStatus] = React.useState(true);
  const [isShowMessage, setShowMessage] = React.useState(false);
  const [messageText, setMessageText] = React.useState("");
  const [form, setForm] = React.useState([
    {
      type: "text",
      action: "text",
      name: "bank_name",
      placeholder: "Nama Bank",
      label: "Nama Bank",
      valid: "Nama bank Rekening wajib di isi",
      status: false,
      col: 6,
      value: "",
      isDisabled: false,
    },
    {
      type: "number",
      action: "text",
      name: "account_number",
      placeholder: "Nomor Rekening",
      label: "Nomor Rekening",
      valid: "Nomor rekening wajib di isi",
      status: false,
      col: 6,
      value: "",
      min: {
        length: 10,
        valid: "Nomor rekening tidak terdaftar",
      },
      max: {
        price: 16,
        valid: "Nomor rekening tidak terdaftar",
      },
      isDisabled: false,
    },
    {
      type: "text",
      action: "text",
      name: "account_name",
      placeholder: "Nama Pemilik Rekening",
      label: "Nama Pemilik Rekening",
      valid: "Nama pemilik Rekening wajib di isi",
      status: false,
      col: 6,
      value: "",
      isDisabled: false,
    },
    {
      name: "nominal",
      type: "price",
      action: "price",
      currencyLogo: "Rp. ",
      label: "Nominal",
      placeholder: "Nominal",
      valid: "Masukan nominal yang ingin di transfer",
      col: 6,
      value: data?.total_cost,
      status: true,
      isDisabled: true,
    },
    {
      label: "Bukti transfer",
      type: "image",
      action: "uploadImage",
      name: "image_1",
      placeholder: "Klik Untuk Masukan Gambar",
      col: 12,
      valid: "Harap masukan gambar",
      value: "",
      status: false,
      normalData: false,
      heightStyle: "250px",
      isDisabled: false,
    },
  ]);
  const returnData = () => {
    setData(false);
    toggle();
  };
  const handlerSubmitData = (e) => {
    let isError = false;
    e.preventDefault();
    for (let key in form) {
      if (!form[key].status) {
        isError = true;
      }
    }
    setValidationClick(isError);
    setValidateForm(false);
    if (!isError) {
      setLoading(true);
      setRefresh(false);
      let id = data.id;
      let dataPost = {
        account_number: form[1].value,
        nominal: form[0].value,
        account_owner: form[3].value,
        bank_name: form[2].value,
        recipe_url: form[4].value,
      };
      service.refund({ dispatch, data: dataPost, id });
    }
  };
  React.useEffect(() => {
    if (state?.finance?.refund?.isError) {
      setLoading(false);
      setStatus(state.finance.refund.isSuccess);
      setMessageText(state.finance.refund.data.message);
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2500);
      service.refundClear({ dispatch });
      // window.location.reload();
    }
    if (state?.finance?.refund?.isSuccess) {
      setRefresh(true);
      setStatus(state.finance.refund.isSuccess);
      setMessageText(state.finance.refund.message.text);
      setShowMessage(true);
      setLoading(false);
      setTimeout(() => {
        setShow(false);
        setShowMessage(false);
        form[0].value = data?.product_fee * data?.total_amount;
        form[1].value = "";
        form[2].value = "";
        form[3].value = "";
        form[4].value = "";
        setData(false);
      }, 2500);
      service.refundClear({ dispatch });
      // window.location.reload();
    }
  }, [state, dispatch]);
  return (
    <Modal size="md" toggle={returnData} isOpen={isShow}>
      <ModalHeader toggle={returnData}>Detail Transaksi</ModalHeader>
      <GrowingLoading active={isLoading} />
      {data && (
        <ModalBody>
          <Style id="transaction_refund">
            <div style={{ marginBottom: 20 }}>
              <div class="content-wrapper">
                <div class="field-label">
                  <p>Nama Pembeli</p>
                </div>
                <div class="field-value">
                  <p>: {data?.customer_detail.full_name}</p>
                </div>
              </div>
              <div class="content-wrapper">
                <div class="field-label">
                  <p>Telepon</p>
                </div>
                <div class="field-value">
                  <p>: {data?.customer_detail.phone_number}</p>
                </div>
              </div>
              <div class="content-wrapper">
                <div class="field-label">
                  <p>Nomor Transaksi</p>
                </div>
                <div class="field-value">
                  <p>: {data?.transaction_code}</p>
                </div>
              </div>
              <br></br>
              <div class="content-wrapper">
                <div class="field-label">
                  <p>Nama Produk</p>
                </div>
                <div class="field-value">
                  <p>
                    : {data.product?.name}{" "}
                    <b>({formatMoney(data?.product_fee)})</b>
                  </p>
                </div>
              </div>
              <div class="content-wrapper">
                <div class="field-label">
                  <p>Jumlah Item</p>
                </div>
                <div class="field-value">
                  <p>: {data?.total_amount}</p>
                </div>
              </div>
              <div class="content-wrapper">
                <div class="field-label">
                  <p>Biaya Pengiriman</p>
                </div>
                <div class="field-value">
                  <p>: {formatMoney(data?.shipping_fee)}</p>
                </div>
              </div>
              <div class="content-wrapper">
                <div class="field-label">
                  <p>Biaya Administrasi</p>
                </div>
                <div class="field-value">
                  <p>: {formatMoney(data?.admin_fee)}</p>
                </div>
              </div>
              {data.insurance_fee !== 0 && (
                <div class="content-wrapper">
                  <div class="field-label">
                    <p>Biaya Asuransi</p>
                  </div>
                  <div class="field-value">
                    <p>: {formatMoney(data?.insurance_fee)}</p>
                  </div>
                </div>
              )}
              <div class="content-wrapper">
                <div class="field-label">
                  <p>Total</p>
                </div>
                <div class="field-value">
                  <p>: {formatMoney(data?.total_cost)}</p>
                </div>
              </div>
            </div>
            <Form
              form={form}
              setForm={setForm}
              validateForm={validateForm}
              validationClick={validationClick}
            />
            <div className="text-right">
              <Button color="primary" onClick={handlerSubmitData}>
                Proses
              </Button>
            </div>
          </Style>
        </ModalBody>
      )}
      <MessageState
        isShow={isShowMessage}
        status={isStatus}
        message={messageText}
      />
    </Modal>
  );
};
export default ModalDetail;
