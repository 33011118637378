/* eslint-disable */
import React from "react";
import { Col, Row } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";

import moment from "moment";
import Button from "../../../../Components/Particle/Button";
import { color } from "../../../../Utils/Variable";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const FixCommission = ({ data = false }) => {
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [form, setForm] = React.useState([
        {
            label: "Potongan Supplier",
            type: "price",
            action: "price",
            name: "Supplier",
            col: 7,
            precision: 2,
            valid: "Harap isi form harga",
            value: data[0].suppliers_cut,
            defaultValue: 0,
            suffix: " %",
            disable: true,
            min: {
                price: 0,
                valid: "Field Persen tidak boleh kurang dari 0%",
            },
            max: {
                price: 100,
                valid: "Field Persen tidak boleh lebih dari 100%",
            },
            status: data[0].suppliers_cut > 0 ? true : false,
        },
        {
            label: "Komisi Reseller",
            type: "price",
            action: "price",
            name: "Reseller",
            col: 7,
            valid: "Harap isi form harga",
            value: data[0].reseller_commission,
            precision: 2,
            suffix: " %",
            min: {
                price: 0,
                valid: "Field Persen tidak boleh kurang dari 0%",
            },
            max: {
                price: 100,
                valid: "Field Persen tidak boleh lebih dari 100%",
            },
            status: data[0].reseller_commission > 0 ? true : false,
        },
        {
            label: "Komisi Lakugan",
            type: "price",
            action: "price",
            name: "lakugan",
            col: 7,
            valid: "Harap isi form harga",
            value: data[0].lakugan_commission,
            precision: 2,
            suffix: " %",
            min: {
                price: 0,
                valid: "Field Persen tidak boleh kurang dari 0%",
            },
            max: {
                price: 100,
                valid: "Field Persen tidak boleh lebih dari 100%",
            },
            status: data[0].lakugan_commission > 0 ? true : false,
        },
        // {
        //     label: "Set Tanggal Perubahan",
        //     type: "text",
        //     action: "datepicker",
        //     name: "dateChange",
        //     col: 7,
        //     value: moment(new Date()).format("YYYY-MM-DD"),
        //     status: true,
        // },
    ]);
    const handlerSubmitData = (e) => {
        let isError = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            let name = data[0].name;
            let dataForm = {
                suppliers_cut: form[1].value + form[2].value,
                reseller_commission: form[1].value,
                lakugan_commission: form[2].value,
                partner_commission: 0,
            };
            service.updateCommission({ dispatch, dataForm, name });
        }
    };
    React.useEffect(() => {
        if (state?.commission?.updateCommission?.isSuccess) {
            service.commission({ dispatch });
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        }
    }, [state.commission.updateCommission]);
    return (
        <div id="fixed_commission">
            <Row>
                <Col xl="4" md="6" sm="12" xs="12">
                    <Form
                        setForm={setForm}
                        validateForm={validateForm}
                        validationClick={validationClick}
                        form={form}
                    />
                </Col>
            </Row>
            <div className="d-flex justify-content-end">
                <div>
                    <Button
                        onClick={handlerSubmitData}
                        type="button"
                        color={`${color.secondary}`}
                    >
                        Terapkan
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default FixCommission;
