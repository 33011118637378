import React from "react";
import styled from "styled-components";
import CommonTable from "../../../../../Components/Molekul/CommonTable";
import service from "../../index.service";
import GrowingLoading from "../../../../../Components/Loading/GrowingLoading";

const Style = styled.div`
    button {
        margin: 5px;
    }
`;
const CardBanner = ({
    handlerViewDetail = () => {},
    handlerEditDetail = () => {},
    handlerDeleteDetail = () => {},
    isLoading = false,
    isRefresh = true,
}) => {
    return (
        <Style>
            <div>
                <GrowingLoading active={isLoading} />
                <React.Fragment>
                    <CommonTable
                        searchBy={["title"]}
                        isRefresh={isRefresh}
                        configuration={{
                            searchInput: true,
                            searchValue: ["title"],
                            header: false,
                            nav: {
                                hasSearch: true,
                                filter: [
                                    {
                                        icon: "fas fa-sort-amount-down",
                                        type: "dropdown",
                                        value: [
                                            {
                                                value: "title",
                                                label: "Judul",
                                                key: "title",
                                            },
                                        ],
                                    },
                                ],
                            },
                            service: {
                                api: service.getBanner,
                                group: "banner",
                                key: "getBanner",
                                filters: "",
                            },
                            optionTable: [
                                {
                                    headName: "No.",
                                    type: "number",
                                },
                                {
                                    headName: "Judul",
                                    type: "text",
                                    fieldName: "title",
                                    iconClass: "fas fa-sort-alpha-down",
                                    iconClass2: "fas fa-sort-alpha-up-alt",
                                },
                                {
                                    headName: "Aksi",
                                    type: "actions",
                                    options: [
                                        {
                                            actionType: "isViewModal",
                                            function: (item) =>
                                                handlerViewDetail(item),
                                            renderItem: () => {},
                                            field: "id",
                                        },
                                        {
                                            actionType: "isEditModal",
                                            function: (item) =>
                                                handlerEditDetail(item),
                                            renderItem: () => {},
                                            field: "id",
                                        },
                                        {
                                            actionType: "isDeleteModal",
                                            function: (item) =>
                                                handlerDeleteDetail(item),
                                            renderItem: () => {},
                                            field: "id",
                                        },
                                    ],
                                },
                            ],
                        }}
                    ></CommonTable>
                </React.Fragment>
            </div>
        </Style>
    );
};
export default CardBanner;
