/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const ModalEditFAQ = ({
    isEditFAQ = false,
    dataFAQ = false,
    setDataFAQ = () => {},
    setLoading = () => {},
    setRefresh = () => {},
    setEditFAQ = () => {},
}) => {
    const { dispatch } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [form, setForm] = useState([
        {
            name: "user",
            type: "select",
            action: "select",
            label: "User",
            required: true,
            col: 6,
            option: [
                {
                    label: "Supplier",
                    value: "Supplier",
                },
                {
                    label: "Reseller",
                    value: "Reseller",
                },
            ],
            isMulti: false,
            valid: "Pilih user",
            value: {
                label: dataFAQ?.category,
                value: dataFAQ?.category,
            },
            status: true,
        },
        {
            type: "text",
            action: "text",
            label: "Kategori",
            required: true,
            name: "title",
            placeholder: "Kategori",
            col: 12,
            valid: "Kategori tidak boleh kosong",
            value: dataFAQ?.title,
            status: true,
        },
    ]);
    const updateValueFAQ = () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let id = dataFAQ.id;
            let data = {
                id: dataFAQ.id,
                category: form[0].value.value,
                title: form[1].value,
                question_answer_list: dataFAQ.question_answer_list,
            };
            service.updateFAQ({ dispatch, id, data });
        }
        setRefresh(false);
        setLoading(true);
    };
    const closePopUp = () => {
        setEditFAQ(!isEditFAQ);
        setDataFAQ(false);
    };
    return (
        <Modal size="lg" isOpen={isEditFAQ} toggle={closePopUp}>
            <ModalHeader className="border-0 pb-0" toggle={closePopUp} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Ubah FAQ</h5>
                <Form
                    isShow={isEditFAQ}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />

                <div className="text-right">
                    <Button
                        color="success"
                        onClick={updateValueFAQ}
                        size="sm"
                        type="button"
                    >
                        <i className="fas fa-edit mr-2"></i>Ubah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default ModalEditFAQ;
