import React from "react";
import { Card, CardBody } from "reactstrap";

import Header from "../Header";
import styled from "styled-components";
import Tab from "../Tab";
import ModalDeletePayment from "./ModalDeletePayment";
import ModalEditPayment from "./ModalEditPayment";
import ModalAddPaymentMethod from "./ModalAddPaymentMethod";
import Button from "../../../../Components/Particle/Button";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import Message from "../../../../Components/Modal/Message";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";

const Style = styled.div`
    section {
        .card-section {
            padding: 20px;
            table {
                button {
                    margin: 0 5px;
                }
            }
        }
        transition: all 0.25s ease;
        width: 100%;
    }
`;

const PostPayment = () => {
    const { dispatch, state } = useStore();
    const [isRefresh, setRefresh] = React.useState(true);
    const [isShow, setShow] = React.useState(false);
    const [isStatus, setStatus] = React.useState(true);
    const [isShowMessage, setShowMessage] = React.useState(false);
    const [isDelete, setDelete] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [messageText, setMessageText] = React.useState("");
    const [data, setData] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const handleShowModal = () => {
        setShow(!isShow);
    };
    const modalDelete = (item) => {
        setDelete(!isDelete);
        setData(item);
    };
    const handleShowEdit = (item) => {
        setEdit(!isEdit);
        setData(item);
    };
    const toggle = () => {
        setEdit(false);
        setData(false);
        setDelete(false);
    };
    const handleDeletePayment = () => {
        setLoading(true);
        setRefresh(false);
        service.deletePaymentMethod({ dispatch, id: data?.id });
    };
    React.useEffect(() => {
        if (state?.payment?.newPaymentMethod?.isSuccess) {
            setStatus(state.payment.newPaymentMethod.isSuccess);
            setMessageText(state.payment.newPaymentMethod.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
        }
        if (state?.payment?.newPaymentMethod?.isError) {
            setShowMessage(true);
            setStatus(state.payment.delete.isSuccess);
            setMessageText(state.payment.delete.data.message);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
        }
        if (state?.payment?.delete?.isSuccess) {
            setRefresh(true);
            setDelete(false);
            setLoading(false);
            setStatus(state.payment.delete.isSuccess);
            setMessageText(state.payment.delete.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deletePaymentClear({ dispatch });
        }
        if (state?.payment?.delete?.isError) {
            setDelete(false);
            setShowMessage(true);
            setStatus(state.payment.delete.isSuccess);
            setMessageText(state.payment.delete.data.message);
            setLoading(false);
            setTimeout(() => {
                setShowMessage(false);
            }, 2500);
            service.deletePaymentClear({ dispatch });
        }
    }, [state, dispatch]);
    return (
        <Style>
            <GrowingLoading active={isLoading} />
            <Header titlePage={"Metode Pembayaran"} />
            <div className="d-flex">
                <section>
                    <div className="card-section">
                        <Card>
                            <CardBody>
                                <Tab />
                                <div
                                    className="d-flex"
                                    style={{ paddingBottom: 20 }}
                                >
                                    <Button
                                        onClick={handleShowModal}
                                        type="button"
                                        color="#5d8cf1"
                                        className="pl-5 pr-5 mr-3"
                                    >
                                        Tambah
                                    </Button>
                                </div>
                                <CommonTable
                                    isRefresh={isRefresh}
                                    searchBy={["name", "vendor", "type"]}
                                    configuration={{
                                        searchInput: true,
                                        searchValue: ["name", "vendor", "type"],
                                        header: false,
                                        nav: {
                                            hasSearch: true,
                                            filter: [
                                                {
                                                    icon: "fas fa-sort-amount-down",
                                                    type: "dropdown",
                                                    value: [
                                                        {
                                                            value: "name",
                                                            label: "Name",
                                                            key: "name",
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        service: {
                                            api: service.getPaymentMethod,
                                            group: "payment",
                                            key: "payment",
                                        },
                                        optionTable: [
                                            {
                                                headName: "No.",
                                                type: "number",
                                            },
                                            {
                                                headName: "Bank",
                                                type: "text",
                                                fieldName: "name",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                            },
                                            {
                                                headName: "Type",
                                                type: "text",
                                                fieldName: "type",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                            },
                                            {
                                                headName: "Vendor",
                                                type: "text",
                                                fieldName: "vendor",
                                                iconClass2:
                                                    "fas fa-sort-alpha-up-alt",
                                                iconClass:
                                                    "fas fa-sort-alpha-down",
                                            },

                                            {
                                                headName: "Aksi",
                                                type: "actions",
                                                options: [
                                                    {
                                                        actionType:
                                                            "isEditModal",
                                                        function: (item) =>
                                                            handleShowEdit(
                                                                item
                                                            ),
                                                        renderItem: () => {},
                                                        field: "id",
                                                    },
                                                    {
                                                        actionType:
                                                            "isDeleteModal",
                                                        function: (item) =>
                                                            modalDelete(item),
                                                        renderItem: () => {},
                                                        field: "id",
                                                    },
                                                ],
                                            },
                                        ],
                                    }}
                                ></CommonTable>
                            </CardBody>
                        </Card>
                    </div>
                </section>
            </div>
            <ModalAddPaymentMethod
                setRefresh={setRefresh}
                setLoading={setLoading}
                setShow={setShow}
                toggle={handleShowModal}
                isShow={isShow}
            />
            {data && (
                <ModalEditPayment
                    setStatus={setStatus}
                    setData={setData}
                    setRefresh={setRefresh}
                    setLoading={setLoading}
                    item={data}
                    setShowMessage={setShowMessage}
                    setShow={setEdit}
                    toggle={toggle}
                    isShow={isEdit}
                    setMessageText={setMessageText}
                />
            )}
            <ModalDeletePayment
                setShowMessage={setShowMessage}
                handleDeletePayment={handleDeletePayment}
                data={data}
                toggle={toggle}
                isShow={isDelete}
            />
            <Message
                isShow={isShowMessage}
                status={isStatus}
                message={messageText}
            />
        </Style>
    );
};
export default PostPayment;
