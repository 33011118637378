import { servicesAction } from "../../../Reducers";

const service = {
    commission: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/commission",
            method: "GET",
            token: true,
            reducer: "service",
            group: "commission",
            key: "commission",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
            },
        });
    },
    commissionClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "commission",
            key: "commission",
        });
    },
    updateCommission: ({ dispatch, dataForm, name }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/v1/commission/update/${name}`,
            method: "PUT",
            token: true,
            data: dataForm,
            reducer: "service",
            group: "commission",
            key: "updateCommission",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
            },
        });
    },
};
export default service;
