import React from "react";
import styled from "styled-components";

const Style = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .number {
        text-align: center;
        font-size: 100px;
        font-weight: bold;
    }
`;
const ErrorPage = () => {
    return (
        <Style>
            <div>
                <h1 className="number">404</h1>
                <h1 className="font-weight-bold">Page Not Found</h1>
            </div>
        </Style>
    );
};
export default ErrorPage;
