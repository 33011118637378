import React from "react";
import TabLink from "../../../../Components/Molekul/TabLink";

const TabArticle = () => {
    const tabLink = [
        {
            name: "Kategori Produk",
            link: "/admin/category/product",
        },
        {
            name: "Kategori Artikel",
            link: "/admin/category/article",
        },
    ];
    return <TabLink data={tabLink} />;
};
export default TabArticle;
