const serviceState = {
    supplier: {
        detail: false,
        supplierList: false,
        createSupplier: false,
    },
    category: {
        category: false,
        categoryart: false,
        newCategory: false,
        delete: false,
        update: false,
    },
    user: {
        auth: false,
    },
    reseller: {
        reseller: false,
        resellerList: false,
        referralCode: false,
    },
    finance: {
        allTransaction: false,
        refund: false,
    },
    commission: {
        commission: false,
        updateCommission: false,
        transaction: false,
    },
    blog: {
        blog: false,
        blogList: false,
        getBlog: false,
    },
    faq: {
        faqList: false,
    },
    partner: {
        partner: false,
    },
    payment: {
        payment: false,
        delete: false,
    },
    transaction: {
        transactionList: false,
    },
    voucher: {
        voucherList: false,
        voucherPost: false,
    },
};

export default serviceState;
