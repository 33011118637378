import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Button from "../../../../Components/Particle/Button";

const Style = styled(Modal)`
    .tab-top-title {
        width: 100%;
        text-align: center;
        margin: auto;
        display: block;
    }
    .title {
        text-align: center;
        margin: auto;
        display: block;
        font-size: 2.5rem;
    }
    .btn-right {
        float: right;
        margin: 5px;
    }
    .kategori {
        text-align: right;
        margin: auto;
        font-weight: bold;
    }
    .btn-left {
        float: left;
        margin: 5px;
    }
`;

const ModalPreview = ({
    isView = false,
    toggle = () => {},
    dataFAQ = false,
    handleEditPreview = () => {},
    handleEditFAQ = () => {},
    handleRemove = () => {},
    handleRemoveQA = () => {},
}) => {
    return (
        <Style size="lg" isOpen={isView} toggle={toggle}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <div className="title">{dataFAQ?.title}</div>
                <div className="kategori">Kategori: {dataFAQ?.category}</div>
                <Button
                    color="danger"
                    className="btn-right"
                    size="sm"
                    type="button"
                    onClick={() => handleRemove(dataFAQ)}
                >
                    <i className="fas fa-trash mr-2"></i>Hapus
                </Button>
                <Button
                    color="success"
                    className="btn-right"
                    onClick={() => handleEditFAQ(dataFAQ)}
                >
                    <i className="fas fa-edit mr-2"></i>Ubah
                </Button>
                <div>
                    {dataFAQ?.question_answer_list?.map((data, key) => (
                        <div key={key}>
                            <br></br>
                            <br></br>
                            <div>- {data?.question}</div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data?.answer,
                                }}
                            ></div>
                            <br></br>
                            <Button
                                className="mr-2"
                                color="success"
                                onClick={() => handleEditPreview(dataFAQ, data)}
                                size="sm"
                                type="button"
                            >
                                <i className="fas fa-edit mr-2"></i>Ubah
                            </Button>
                            <Button
                                color="danger"
                                onClick={() => handleRemoveQA(dataFAQ, data)}
                                size="sm"
                                type="button"
                            >
                                <i className="fas fa-trash mr-2"></i>Hapus
                            </Button>
                        </div>
                    ))}
                </div>
            </ModalBody>
        </Style>
    );
};

export default ModalPreview;
