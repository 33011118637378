import React from "react";
import { Card, CardBody } from "reactstrap";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import Button from "../../../../Components/Particle/Button";
import service from "../index.service";
import Tab from "../Tab";
import { useStore } from "../../../../Reducers";
import { formatMoney } from "../../../../Utils/PriceConverter";

import Header from "../Header";
import styled from "styled-components";
import ModalDetail from "./ModalDetail";

const Style = styled.div`
  section {
    .card-section {
      padding: 20px;
      .img-supplier {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }
    transition: all 0.25s ease;
    width: 100%;
  }
`;
const CancelTransaction = () => {
  const { dispatch, state } = useStore();
  const [isFirstGet, setFirstGet] = React.useState(true);
  const [isShow, setShow] = React.useState(false);
  const [isRefresh, setRefresh] = React.useState(true);
  const [data, setData] = React.useState(false);
  const [commissionCustom, setCommissionCustom] = React.useState([]);
  const [commissionFixed, setCommissionFixed] = React.useState([]);

  React.useEffect(() => {
    if (isFirstGet) {
      service.commission({ dispatch });
      setFirstGet(false);
    }
  }, [isFirstGet, dispatch]);

  React.useEffect(() => {
    if (state?.commission?.commission?.isSuccess) {
      setCommissionCustom(
        state.commission.commission.data.content.filter((item) => item.id === 1)
      );
      setCommissionFixed(
        state.commission.commission.data.content.filter((item) => item.id === 2)
      );
    }
  }, [state]);
  const handleViewDetail = (data) => {
    setShow(!isShow);
    setData(data);
  };
  return (
    <Style>
      <Header titlePage={"Pembatalan Transaksi"} />
      <div className="d-flex">
        <section>
          <div className="card-section">
            <Card>
              <CardBody>
                <Tab />
                <CommonTable
                  isRefresh={isRefresh}
                  download={true}
                  id="tableAllTransaction"
                  searchBy="customer_detail.full_name"
                  configuration={{
                    searchInput: true,
                    searchValue: [
                      "transactionCode",
                      "customerDetail.fullName",
                      "productName",
                    ],

                    // searchValue: "store.name",
                    header: false,
                    nav: {
                      hasSearch: true,
                      filter: [
                        {
                          icon: "fas fa-sort-amount-down",
                          type: "dropdown",
                          value: [
                            {
                              value: "email",
                              label: "Email",
                              key: "email",
                            },
                            {
                              value: "full_name",
                              label: "Name",
                              key: "full_name",
                            },
                            {
                              value: "store.name",
                              label: "Nama Toko",
                              key: "store.name",
                            },
                          ],
                        },
                      ],
                    },
                    service: {
                      api: service.refundTransaction,
                      group: "finance",
                      key: "refundTransaction",
                      filters: ``,
                      filterData: `["status","=","13"]`,
                    },
                    optionTable: [
                      {
                        headName: "No",
                        type: "number",
                      },
                      {
                        headName: "Nama",
                        type: "isCostume",
                        fieldName: "full_name",
                        renderItem: (item) => {
                          return (
                            <React.Fragment>
                              <span>{item.customer_detail.full_name}</span>
                            </React.Fragment>
                          );
                        },
                        iconClass2: "fas fa-sort-alpha-up-alt",
                        iconClass: "fas fa-sort-alpha-down",
                      },
                      {
                        headName: "Telepon",
                        type: "isCostume",
                        fieldName: "phone_number",
                        renderItem: (item) => {
                          return (
                            <React.Fragment>
                              <span>{item.customer_detail.phone_number}</span>
                            </React.Fragment>
                          );
                        },
                        iconClass2: "fas fa-sort-alpha-up-alt",
                        iconClass: "fas fa-sort-alpha-down",
                      },
                      {
                        headName: "Tanggal Transaksi",
                        type: "isCostume",
                        fieldName: "transaction_created",
                        renderItem: (item) => {
                          return (
                            <React.Fragment>
                              <span>{item.created.substring(0, 10)}</span>
                            </React.Fragment>
                          );
                        },
                        iconClass2: "fas fa-sort-alpha-up-alt",
                        iconClass: "fas fa-sort-alpha-down",
                      },
                      {
                        headName: "Nomor Transaksi",
                        type: "isCostume",
                        fieldName: "transaction_code",
                        renderItem: (item) => {
                          return (
                            <React.Fragment>
                              <span>{item.transaction_code}</span>
                            </React.Fragment>
                          );
                        },
                        iconClass2: "fas fa-sort-alpha-up-alt",
                        iconClass: "fas fa-sort-alpha-down",
                      },
                      {
                        headName: "Pengurangan Biaya Admin",
                        type: "isCostume",
                        fieldName: "admin_fee",
                        renderItem: (item) => {
                          return (
                            <React.Fragment>
                              <span>{formatMoney(item.admin_fee)}</span>
                            </React.Fragment>
                          );
                        },
                        iconClass2: "fas fa-sort-alpha-up-alt",
                        iconClass: "fas fa-sort-alpha-down",
                      },
                      {
                        headName: "Pengembalian",
                        type: "isCostume",
                        fieldName: "refund",
                        renderItem: (item) => {
                          return (
                            <React.Fragment>
                              <span>
                                {formatMoney(item.total_cost - item.admin_fee)}
                              </span>
                            </React.Fragment>
                          );
                        },
                        iconClass2: "fas fa-sort-alpha-up-alt",
                        iconClass: "fas fa-sort-alpha-down",
                      },
                      {
                        headName: "",
                        type: "isCostume",
                        renderItem: (item) => {
                          return (
                            <React.Fragment>
                              <Button
                                color="primary"
                                onClick={() => handleViewDetail(item)}
                              >
                                Lihat
                              </Button>
                            </React.Fragment>
                          );
                        },
                      },
                    ],
                  }}
                ></CommonTable>
              </CardBody>
            </Card>
          </div>
        </section>
      </div>
      {data && (
        <ModalDetail
          setShow={setShow}
          setRefresh={setRefresh}
          commissionFixed={commissionFixed}
          commissionCustom={commissionCustom}
          setData={setData}
          toggle={() => setShow(!isShow)}
          isShow={isShow}
          data={data}
        />
      )}
    </Style>
  );
};
export default CancelTransaction;
