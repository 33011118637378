import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ModalFilter = ({ isShow = false, toggle = () => {} }) => {
    return (
        <Modal toggle={toggle} isOpen={isShow}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Filter</h5>
            </ModalBody>
        </Modal>
    );
};
export default ModalFilter;
