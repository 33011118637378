/* eslint-disable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const ModalAddFAQ = ({
    isShow = false,
    toggle = () => {},
    setShow = () => {},
    setLoading = () => {},
    setRefresh = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [form, setForm] = useState([
        {
            type: "text",
            action: "text",
            label: "Kategori",
            required: true,
            name: "title",
            placeholder: "Pilih kategori",
            col: 6,
            valid: "Kategori tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            name: "user",
            type: "select",
            action: "select",
            label: "User",
            col: 6,
            required: true,
            // service: {
            //   api: service.categoryFaq,
            //   group: "faq",
            //   key: "faqList",
            //   filters: "",
            // },
            option: [
                {
                    label: "Supplier",
                    value: "Supplier",
                },
                {
                    label: "Reseller",
                    value: "Reseller",
                },
            ],
            isMulti: false,
            valid: "Pilih user",
            value: "",
            status: false,
        },
    ]);
    const handlerSubmitData = () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            setRefresh(false);
            let data = {
                category: form[1].value.value,
                title: form[0].value,
            };
            service.AddFAQ({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (state?.faq?.addFAQ?.isSuccess) {
            form[0].value = "";
            form[1].value = "";
        }
    }, [state]);
    return (
        <Modal size="lg" isOpen={isShow} toggle={toggle}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <h5 className="font-weight-bold mb-3">Tambah Kategori FAQ</h5>
                <Form
                    isShow={isShow}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
                <div className="text-right">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => handlerSubmitData()}
                    >
                        Tambah
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default ModalAddFAQ;
